<template>
  <van-row class="comment" @click="!isreply && $router.push({name: 'CommentDetail', params:{type: type,id: comment_id}})">
    <van-col span="3" class="comment-left-icon">
      <font-awesome-icon v-if="headpic.length<=0" :icon="['fas', 'user-circle']" class="comment-icon" />
      <van-image
        v-if="headpic.length>0"
        round
        width="30px"
        height="30px"
        :src="imageUrl+headpic"
      />
    </van-col>
    <van-col span="21" class="comment-name">
      <div class="comment-name-text">{{nickname}}</div>
      <div class="content gray">{{comment_content}}</div>
      <div class="datetime gray">
        <van-row>
          <van-col span="12">
            {{comment_time}}
          </van-col>
          <van-col span="12" style="text-align:right;font-size:12px;" v-if="type!='user' && !isreply">
            <span class="right-content" @click="onUp(comment_id, $event)" :style="{'color':is_comment_up==1 ? '#D91A6E':'#868A93'}"><font-awesome-icon :icon="['fas', 'thumbs-up']" /> {{comment_up}}</span>
            <span class=""><font-awesome-icon :icon="['fas', 'comment-dots']" /> {{comment_reply_num}}</span>
          </van-col>
        </van-row>
      </div>
      <div class="background" style="margin-top:5px;" v-if="comment_reply && comment_reply.length>0">
        <template v-for="(item,index) in comment_reply" :key="item.comment_id">
          <div v-if="index<3" class="van-ellipsis">{{item.nickname}}: <span class="gray">{{item.comment_content}}</span></div>
        </template>
        <div class="all-reply">查看所有回复</div>
      </div>
    </van-col>
  </van-row>
</template>
<script>
import { imageUrl } from '@/config/index.js'
export default {
  name: 'CommentItem',
  props: {
    isreply: { type: Boolean, default: false },
    type: { type: String, default: 'video' },
    comment_id: { type: Number, default: 0 },
    headpic: { type: String, default: '' },
    nickname: { type: String, default: '' },
    vod_id: { type: Number, default: 0 },
    comment_content: { type: String, default: '' },
    comment_up: { type: Number, default: 0 },
    is_comment_up: { type: Number, default: 0 },
    comment_time: { type: String, default: '' },
    comment_reply_num: { type: Number, default: 0 },
    comment_reply: { type: Array },
    onUp: { type: Function }
  },
  components: {

  },
  data () {
    return {
      content: '',
      imageUrl
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.comment{
  padding: 10px;
  margin-bottom: 10px;
  background:#1F232B;
  border-radius: var(--van-card-border-radius);
  .comment-icon{
    width:30px;
    height:30px;
    border-radius: var(--van-card-border-radius);
    text-align: center;
  }
  .background{
    padding: 10px;
    background:#161A22;
    border-radius: var(--van-card-border-radius);
  }
  .comment-name-text{
    font-size: 14px;
    height:30px;
    line-height: 30px;
  }
  .content{
    padding:10px 0;
    font-size: 12px;
  }
  .datetime{
    font-size: 10px;
  }
  .right-content{
    margin-right:20px;
  }
  .gray{
    color: #868A93;
  }
  .all-reply{
    color: #FACF1A
  }
}
</style>
