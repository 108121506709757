<template>
  <div class="earn-task">
    <van-sticky>
      <van-nav-bar title="系统公告" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <van-cell-group inset>
          <van-cell v-for="item in list" :key="item.id" :title="item.title"  :label="item.create_time" is-link  @click="$router.push({ name: 'NoticeDetail', params: {id: item.id} })">
            <template #title>
              <van-tag type="danger">公告</van-tag>
              <span class="title">{{item.title}}</span>
            </template>
          </van-cell>
        </van-cell-group>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
export default {
  name: 'MessageSystem',
  props: {
  },
  components: {

  },
  data () {
    return {
      list: [],
      loading: false,
      refreshing: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.notice/list').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        this.list = res.data
      })
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.getList()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.earn-task {
  .wrapper {
    padding: 10px;
  }
  .title{
    margin-left:5px;
  }
  .van-cell__right-icon{
    align-self: center;
  }
}
</style>
