<template>
  <van-row class="payment" @click="chooseWay(type, name)">
    <van-col span="4" class="payment-left-icon">
      <van-image width="30" height="30" :src="payWayImageConfig[icon_name]" class="payment-icon"/>
    </van-col>
    <van-col span="14" class="payment-name">
      <div class="payment-name-text">{{payment_name}}</div>
      <div class="payment-subname-text" :style="{color:sub_color}">{{sub_name}}</div>
    </van-col>
    <van-col span="6" class="payment-right-btn">
      <font-awesome-icon
        :icon="['fas', (payWayType == type && payWayName == name)?'check-circle':'circle']"
        :style="{color:(payWayType == type && payWayName == name)?'#F0B90B':'#868A93'}"
        class="payment-icon"
      />
    </van-col>
  </van-row>
</template>
<script>
const payWayImageConfig = {
  juhepay: require('@/assets/icon/juhepay.png'),
  unionpay: require('@/assets/icon/unionpay.png'),
  wepay: require('@/assets/icon/wepay.png'),
  alipay: require('@/assets/icon/alipay.png'),
  quickpass: require('@/assets/icon/quickpass.png'),
  DOIT: require('@/assets/icon/icon_doit.png')
}
export default {
  created () {
  },
  name: 'PaymentItem',
  props: {
    payWayType: { type: [Number, String] },
    payWayName: { type: String },
    icon_name: { type: String },
    color: { type: String },
    sub_color: { type: String },
    payment_name: { type: String },
    sub_name: { type: String },
    type: { type: [Number, String] },
    name: { type: String },
    chooseWay: { type: Function }
  },
  components: {

  },
  data () {
    return {
      payWayImageConfig
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.payment{
  height:60px;
}
.payment-icon{
  width:20px;
  height:20px;
  padding:10px;
  border-radius: var(--van-card-border-radius);
}
.payment-left-icon{
  align-self: center;
}
.payment-name{
  align-self: center;
}
.payment-right-btn{
  align-self: center;
  text-align: right;
}
.payment-name-text{
  font-size:14px;
}
.payment-subname-text{
  font-size: 10px;
  margin-top: 5px;
}
</style>
