<template>
  <div class="flow-item">
    <div class="section">
      <div class="wrapper">
        <van-row>
          <van-col span="12" class="left-item">
            <div class="title">{{title}}</div>
            <div class="time">{{time}}</div>
          </van-col>
          <van-col span="12" class="right-item">
            <div class="amount">{{amount}}<span class="currency">{{currency}}</span></div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FlowItem',
  props: {
    title: { type: String, defalut: '支付成功' },
    time: { type: String },
    amount: { type: Number },
    currency: { type: String, defalut: '色币' }
  },
  components: {

  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.flow-item{
  width:100%;
  .section{
    width: 100%;
    margin-bottom: 10px;
  }
  .wrapper{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
    padding:10px;
  }
  .left-item div{
    line-height: 20px;
  }
  .right-item{
    text-align: right;
    line-height: 40px;
  }
  .title{
    font-size:16px;
  }
  .time{
    font-size:12px;
    color:#868A93;
  }
  .amount{
    color:#f8c369 ;
    font-size:16px;
  }
  .currency{
    margin-left:5px;
    font-size:16px;
    color:#868A93;
  }
}
</style>
