<template>
  <div class="forgot-password">
    <van-sticky>
      <van-nav-bar
        left-arrow
        :border=false
        @click-left="$router.back()"
      />
    </van-sticky>
    <div class="section">
      <p class="lbl_title">找回密码</p>
      <p class="lbl_subtitle">手机号码找回，如手机丢失请联系客服</p>
      <div>
        <van-field v-model="username" type="tel" class="input-item"  placeholder="手机号码">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'mobile-alt']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="code" center clearable type="digit"  placeholder="手机验证码" class="input-item" >
          <template #button>
            <van-button size="mini" type="primary" class="input-right-item" v-if="setCode" @click="getCode" :disabled="disabled" >{{setCode}}</van-button>
          </template>
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'sms']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password" type="password"  placeholder="新登录密码" class="input-item"  >
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password2" type="password"  placeholder="确认登录密码" class="input-item"  >
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-button type="primary" block round class="forgot-password-btn mt20" @click="forgotPassword()">确认找回</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request'
import { Toast } from 'vant'
import validator from 'validator'

export default {
  data () {
    return {
      username: '',
      password: '',
      password2: '',
      code: '',
      setCode: '发送验证码',
      disabled: false
    }
  },
  methods: {
    getCode () {
      if (!validator.isMobilePhone(this.username, ['zh-CN'])) {
        Toast.fail('手机号码错误')
        return
      }
      post('/common/v1.system/getPhoneCaptcha',
        {
          phone: this.username,
          area: '86'
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        // if (res.code != 0) {
        //   Toast.fail(res.msg)
        //   return
        // }
        this.validateBtn()
        return true
      })
    },
    validateBtn () {
      let time = 60
      const timer = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        if (time == 0) {
          clearInterval(timer)
          this.disabled = false
          this.setCode = '发送验证码'
        } else {
          this.setCode = time + '秒后重试'
          this.disabled = true
          // eslint-disable-next-line no-const-assign
          time--
        }
      }, 1000)
    },
    forgotPassword  () {
      if (!validator.isMobilePhone(this.username, ['zh-CN'])) {
        Toast.fail('手机号码错误')
        return
      }
      if (!validator.isInt(this.code, { min: 1000, max: 9999 })) {
        Toast.fail('验证码错误')
        return
      }
      if (this.password.length < 6) {
        Toast.fail('密码长度不能小于6个字符')
        return
      }
      if (this.password.length > 32) {
        Toast.fail('密码长度不能大于32个字符')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.password != this.password2) {
        Toast.fail('两次密码必须一致')
        return
      }
      if (!validator.isInt(this.code, { min: 1000, max: 9999 })) {
        Toast.fail('验证码错误')
        return
      }
      const loading = Toast.loading('提交中', 20)
      post('/common/v1.system/forgetPassword',
        {
          phone: this.username,
          password: this.password,
          repassword: this.password2,
          phonecode: this.code
        }
      ).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast({
          type: 'success',
          message: '找回成功',
          onClose: () => {
            this.$router.replace({ name: 'Login' })
          }
        })
      })
    }
  },
  created () {
  },
  name: 'ForgotPassword',
  components: {}
}
</script>

<style scoped lang="scss">
.forgot-password{
  .section{
    padding:20px;
  }
  .lbl_title{
    font-size: 20px;
    color:white;
    margin-bottom: 10px;
  }
  .lbl_subtitle{
    font-size: 12px;
    color:white;
    margin-bottom: 30px;
  }
  .input-item{
    margin:10px 0;
    border-radius: 20px;
  }
  .input-right{
    margin:0;
    padding:0;
  }
  .input-right-item{
    margin: 0;
    border-radius: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .icon-box{
    min-width: 40px;;
  }
  .input-icon{
    color:#D91A6E;
    width: 20px !important;
    height: 20px;
  }
  .van-field__control{
    color: #000 !important;
  }
  .mt20{
    margin-top:20px;
  }
}
</style>
