import platform from 'platform'

export function bin2hex (s) {
  let i
  let l
  let o = ''
  let n
  s += ''
  for (i = 0, l = s.length; i < l; i++) {
    n = s.charCodeAt(i).toString(16)
    o += n.length < 2 ? '0' + n : n
  }
  return o
}

export function isIOS () {
  return /iOS/.test(platform.os.family)
}

export function isAndroid () {
  return /Android/.test(platform.os.family)
}

export function parseJson (jsonStr) {
  try {
    return JSON.parse(jsonStr)
  } catch (error) {
    return {}
  }
}

export function getVersionInfo () {
  try {
    if (isAndroid() && window.AndroidInterface) {
      const versionInfo = window.AndroidInterface.getVersionInfo()
      const res = parseJson(versionInfo)
      if (res && res.data) {
        return res.data
      } else {
        return null
      }
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}

export function getAndroidUUID () {
  try {
    if (isAndroid() && window.AndroidInterface) {
      const uuid = window.AndroidInterface.generateDeviceUUID()
      const res = parseJson(uuid)
      if (res && res.data) {
        return res.data
      } else {
        return null
      }
    } else {
      return null
    }
  } catch (err) {
    return null
  }
}
