<template>
  <div class="video-index">
    <van-sticky>
      <van-row>
        <van-col span="21" @click="$router.push({name:'VideoSearch'})">
          <van-search
            shape="round"
            placeholder="点击搜索视频"
          >
          </van-search>
        </van-col>
        <van-col span="3" class="header-right">
          <div class="filter" @click="$router.push({name:'VideoFilter'})">
            <font-awesome-icon
              :icon="['fas', 'bars']"
              class="filter-icon"
            />
          </div>
        </van-col>
      </van-row>
      <van-tabs v-model:active="active" @click-tab="changeTab">
        <van-tab v-for="item in types" :title="item.type_name" :key="item.type_id" :name="item.type_id">
        </van-tab>
      </van-tabs>
      <div class="tags" v-if="sort!='vod_time' || days!=0">
        <div v-if="sort!='vod_time'" class="tag-type">
          <van-tag closeable size="medium" round type="primary" @close="closeType">
            {{filterType.name}}
          </van-tag>
        </div>
        <div v-if="days!=0">
          <van-tag closeable size="medium" round type="primary" @close="closeTime">
            {{filterTime.name}}
          </van-tag>
        </div>
      </div>
    </van-sticky>
    <div class="videos" v-if="isloading">
      <free-style-shimmer :is-loading="isloading" height="125px" width="100%" border-radius="6px" class="item-loading" v-for="item in 20" :key="item" @click="getList" />
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh"  v-if="!isloading">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
        class="padding"
      >
        <template v-if="list && list.length>0">
          <div class="videos">
            <VideoItem
              v-for="item in list" :key="item.vod_id"
              :vod_id="item.vod_id"
              :vod_pic="item.vod_pic"
              :vod_name="item.vod_name"
              :vod_watch="item.vod_watch"
              :vip="item.is_vip"
              :level="item.level"
              :price="item.price"
              :numColumns="2"
              class="item"
            />
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue'
import Bus from 'vue3-bus'
import { get } from '@/utils/request'
import VideoItem from '@/components/Video/Item.vue'

export default {
  name: 'VideoIndex',
  components: {
    VideoItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      type_id: 0,
      days: 0,
      keyword: '',
      sort: 'vod_time',
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      types: [{
        type_id: 0,
        type_name: '全部',
        type_en: 'quanbu',
        type_pid: 0
      }],
      filterType: {},
      filterTime: {},
      isloading: true,
      scroll: 0
    }
  },
  created () {
    this.getTypes()
    this.getList()
  },
  methods: {
    closeType () {
      this.sort = 'vod_time'
      this.onRefresh()
    },
    closeTime () {
      this.days = 0
      this.onRefresh()
    },
    jumpPage (id) {
      this.$router.push({ name: 'VideoDetail', params: { id } })
    },
    changeTab (data) {
      this.type_id = data.name
      this.onRefresh()
    },
    getTypes () {
      get('/common/v1.video/type').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        res.data.unshift({
          type_id: 0,
          type_name: '全部',
          type_en: 'quanbu',
          type_pid: 0
        })
        const arr = []
        // eslint-disable-next-line no-undef
        res.data.forEach(v => {
          arr.push(v)
        })
        this.types = arr
      })
    },
    getList () {
      get('/common/v1.video/list', {
        page: this.page,
        sort: this.sort,
        days: this.days,
        type: this.type_id,
        keyword: this.keyword
      }).then(res => {
        this.isloading = false
        this.loading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    handleScroll () {
      this.scroll = document.documentElement && document.documentElement.scrollTop
    }
  },
  mounted () {
    // let self = this
    Bus.on('filter', (data) => {
      this.filterType = data.type
      this.filterTime = data.time
      this.sort = data.type.value
      this.days = data.time.value
      this.onRefresh()
    })
    window.addEventListener('scroll', this.handleScroll, true)
  },
  setup () {
    const active = ref(0)
    return { active }
  },
  watch: {
  },
  beforeRouteLeave (to, from, next) {
    if (['VideoDetail'].includes(to.name)) {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    window.removeEventListener('scroll', this.handleScroll, true)
    next()
  },
  activated () {
    if (this.scroll > 0) {
      window.scrollTo(0, this.scroll)
      this.scroll = 0
      window.addEventListener('scroll', this.handleScroll, true)
    }
  }
}
</script>
<style scoped lang="scss">
.video-index{
  padding-bottom: 70px;
  .videos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    .item{
      height: 125px;
      flex: 0 0 49%;
      overflow: hidden;
    }
  }
  .item-loading{
    height: 125px;
    width: 47.2%;
    padding: 5px;
  }
  .padding{
    padding:5px;
  }
  .header-right{
    display: flex;
    background: #161A22;
  }
  .filter{
    align-self: center;
    margin:0 auto;
  }
  .tags{
    display: flex;
    padding: 10px;
  }
  .tag-type{
    margin-right: 10px;
  }
  .filter-icon{
    width:18px;
    height:18px;
  }
}
</style>
