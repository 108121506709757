<template>
  <div class="ofo-exp">
    <van-sticky>
      <van-nav-bar title="体验报告" :border=false>
        <template #right>
          <div @click="$router.push({name:'CityPicker'})">
            <van-icon name="location" size="18" />
            {{city}}
          </div>
        </template>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <template v-if="list && list.length>0">
            <div class="ofo-item">
              <OfoExpItem
                v-for="item in list"
                :key="item.id"
                :id="item.id"
                :girl_name="item.girl_name"
                :exp_time="item.exp_time"
                :location="item.location"
                :service_desc="item.service_desc"
                :image_around="item.image_around"
                :image_girl="item.image_girl"
                :image_inner="item.image_inner"
                :image_pay="item.image_pay"
                :look_ofo_exp="item.look_ofo_exp"
                :service_score="item.service_score"
                :status="item.status"
                />
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { get } from '@/utils/request'
import OfoExpItem from '@/components/Ofo/ExpItem.vue'
export default {
  name: 'OfoExp',
  props: {

  },
  components: {
    OfoExpItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      keyword: '',
      adcode: '110100',
      city: '北京',
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      selectedIndex: 0,
      types: [{
        key: 'updated_at',
        sort: 'desc',
        type: '1'
      }]
    }
  },
  created () {
    this.location()
    this.getList()
  },
  methods: {
    location () {
      const location = this.$store.getters.location || {}
      if (location && location.id) {
        this.adcode = location.id
        this.city = location.city
      }
    },
    getList () {
      get('/common/v1.ofo/expList', {
        page: this.page,
        sort: this.types[this.selectedIndex].key,
        order: this.types[this.selectedIndex].sort,
        type: this.types[this.selectedIndex].type,
        keyword: '',
        adcode: this.adcode,
        is_vip: 0
      }).then(res => {
        this.isloading2 = false
        this.loading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
      this.onRefresh()
    })
  }
}
</script>

<style lang="scss" scoped>
.ofo-exp {
  .wrapper{
    padding: 10px;
  }
}
</style>
