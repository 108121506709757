<template>
  <div class="tasks-award">
    <van-sticky>
      <van-nav-bar title="奖励记录" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.id">
            <FlowItem
              :title="item.task_remark"
              :time="item.created_at"
              :amount="item.amount"
              :currency="type == 'DOIT' ? '色币' : type"
            />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
import FlowItem from '@/components/Common/FlowItem.vue'
export default {
  name: 'TasksAward',
  props: {
    type: { type: String, default: 'DOIT' }
  },
  computed: {
  },
  components: {
    FlowItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      list: [],
      loading: false,
      refreshing: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.record/getAssetsTaskReward', {
        page: this.page
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        if (res.data == null || res.data.length === 0) {
          this.finished = true
          // eslint-disable-next-line eqeqeq
          if (this.page == 1) {
            this.list = []
          }
          return
        }
        this.total = res.data.total
        const data = res.data.data
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.tasks-award {
  .wrapper {
    padding: 10px;
  }
}
</style>
