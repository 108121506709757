<template>
  <div class="earn-task-detail">
    <van-sticky>
      <van-nav-bar title="赚钱任务详情" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <iframe
        :src="data.url"
        ref="mapFrame"
        width='100%'
        height='1000'
        frameborder='0'
        class='iframe'
      ></iframe>
  </div>
</template>

<script>
import { get } from '@/utils/request'
export default {
  name: 'EarnTaskDetail',
  props: {
    id: { type: Number }
  },
  components: {

  },
  data () {
    return {
      data: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.system/getEarnTask', { id: this.id }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.data = res.data
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.earn-task-detail {
  .iframe{
    width: 100%;
    height: 100vh;
  }
}
</style>
