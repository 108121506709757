<template>
  <div class="club-detail">
    <van-nav-bar title="会所详情" :border="false">
      <template #left>
        <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
      </template>
      <template #right>
        <div v-if='false'>投诉</div>
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <free-style-shimmer :is-loading="isloading" height="123px" width="100%" border-radius="6px" />
      <van-row v-if="!isloading">
        <van-col span="7" class="left-item">
          <div class="left-image">
            <van-image width="88px" height="120px" :src="imageUrl+data.img" lazy-load>
              <template v-slot:loading>
                <div>色影</div>
              </template>
            </van-image>
          </div>
        </van-col>
        <van-col span="17" class="right-item">
          <div class="title">{{data.name}}</div>
          <div class="subtitle">别名：{{data.abbreviation}}</div>
          <div><span class="label">装修环境：</span><van-rate v-model="data.ambient" readonly allow-half /></div>
          <div><span class="label">技师素质：</span><van-rate v-model="data.quality" readonly allow-half /></div>
        </van-col>
      </van-row>
      <van-divider />
      <free-style-shimmer :is-loading="isloading" height="30px" width="100%" border-radius="6px" />
      <div class="text-2line" v-if="!isloading"><span class="label">地址：</span>{{data.address}}</div>
      <van-divider />
      <CommonTitle title="简单介绍" subtitle="我要评论" subtitleStyle="color:#D91A6E;" :onSubtitle="onComment" />
      <free-style-shimmer :is-loading="isloading" height="60px" width="100%" border-radius="6px" />
      <div class="intro" v-if="!isloading">{{data.introduce}}</div>
      <CommonTitle title="部长联系方式" style="margin:10px 0" />
      <free-style-shimmer :is-loading="isloading" height="50px" width="100%" border-radius="6px" />
      <div class="basic-card lock-card" v-if="!isloading">
        <div v-if="!showContact" @click="getContact">
          <font-awesome-icon :icon="['fas', 'lock']" class="icon" />
          <span class="lock-text">点击解锁</span>
        </div>
        <div v-if="showContact && contacts.length>0">
          <div v-for="(item, index) in contacts" :key="index" class="contact-item">{{item.contact}}</div>
        </div>
      </div>
      <CommonTitle title="消费情况" :subtitle="isloading?'':'￥'+data.monetary" style="margin:10px 0"  />
    </div>
    <van-action-bar>
      <van-action-bar-icon :style="{'min-width':minWidth}" icon="chat-o" :text="data.club_up" @click="handleDo('club_up')">
        <template #icon>
          <font-awesome-icon :icon="['fas', 'thumbs-up']" class="footer-nav-icon" :style="{'color':data.is_club_up ? '#D91A6E':'#868A93'}" />
        </template>
      </van-action-bar-icon>
      <van-action-bar-icon :style="{'min-width':minWidth}" icon="cart-o" :text="data.club_store" @click="handleDo('club_store')" >
        <template #icon>
          <font-awesome-icon :icon="['fas', 'heart']" class="footer-nav-icon" :style="{'color':data.is_club_store ? '#D91A6E':'#868A93'}" />
        </template>
      </van-action-bar-icon>
      <van-action-bar-icon :style="{'min-width':minWidth}" icon="shop-o" :text="data.club_share" @click="handleDo('club_share')" >
        <template #icon>
          <font-awesome-icon :icon="['fas', 'share-alt']" class="footer-nav-icon" :style="{'color':data.is_club_share? '#D91A6E':'#868A93'}"/>
        </template>
      </van-action-bar-icon>
      <van-action-bar-button type="danger" v-if="btnShow" :text="vipBtn" @click="onOpenVip"/>
    </van-action-bar>
    <Dialog
      :show="dialog"
      :title="title"
      :yesBtnText="yesBtnText"
      :code="code"
      :onClose="closeDialog"
      :onOpenVip="onOpenVip"
    />
  </div>
</template>

<script>
import { post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import Dialog from '@/components/Common/Dialog.vue'
import { Toast } from 'vant'
import { imageUrl } from '@/config/index.js'

export default {
  name: 'ClubDetail',
  props: {
    id: { type: String, required: true }
  },
  components: {
    CommonTitle,
    Dialog
  },
  data () {
    return {
      isloading: true,
      data: {},
      showContact: false,
      contacts: null,
      dialog: false,
      title: '',
      yesBtnText: '',
      code: 0,
      userInfo: this.$store.getters.userInfo,
      imageUrl
    }
  },
  computed: {
    vipBtn: (data) => {
      let btnStr = ''
      switch (parseInt(data.userInfo.vip_level)) {
        case 1:
        case 2:
        case 3:
          btnStr = '续费会员'
          break
        default:
          btnStr = '开通会员'
          break
      }
      return btnStr
    },
    minWidth: (data) => {
      let width = '80px'
      if (parseInt(data.userInfo.vip_level) === 3 && parseInt(data.userInfo.vip_deadline_type) === 3) {
        width = '33.33%'
      }
      return width
    },
    btnShow: (data) => {
      if (parseInt(data.userInfo.vip_level) === 3 && parseInt(data.userInfo.vip_deadline_type) === 3) {
        return false
      }
      return true
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    onComment () {
      this.$router.push({ name: 'Comments', params: { type: 'club', id: this.id } })
    },
    getDetails () {
      post('/common/v1.club/details', {
        club_id: this.id
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.isloading = false
        this.data = res.data
      })
    },
    getContact () {
      const loading = Toast.loading('获取中', 20)
      post('/common/v1.club/contact', {
        club_id: this.id
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code == 0) {
          // eslint-disable-next-line eqeqeq
          if (res.data.surplus_look_times != '-1') {
            Toast.success(
              '今日已查看' +
                res.data.look_times +
                '次，剩余查看' +
                res.data.surplus_look_times +
                '次'
            )
          }
          this.showContact = true
          this.contacts = res.data.contacts
        // eslint-disable-next-line eqeqeq
        } else if (res.code == 5001) {
          // this.mymodal.init({title: res.msg, visible: true})
          this.code = 0
          this.dialog = true
          this.title = res.msg
          this.yesBtnText = '开通会员'
        } else {
          Toast.fail('系统繁忙，请稍后重试')
        }
      })
    },
    closeDialog () {
      this.dialog = false
    },
    onOpenVip () {
      this.$router.push({ name: 'OpenVip' })
    },
    handleDo (type) {
      const loading = Toast.loading('请求中', 20)
      post('/common/v1.club/do', {
        club_id: this.id,
        type
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.type == 'club_up') {
          this.data.is_club_up = res.data.status
          this.data.club_up = res.data.num
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.type == 'club_store') {
          this.data.is_club_store = res.data.status
          this.data.club_store = res.data.num
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.type == 'club_share') {
          this.data.is_club_share = res.data.status
          this.data.club_share = res.data.num
        }
      })
    }
  },
  created () {
    this.getDetails()
  }
}
</script>

<style lang="scss" scoped>
.club-detail {
  .wrapper{
    padding: 10px;
  }
  .left-item{
    display: flex;
  }
  .left-image{
    align-self: center;
  }
  .title{
    font-size:16px;
    line-height: 30px;
  }
  .subtitle, .label{
    font-size:12px;
    color: #868A93;
    line-height: 30px;
  }
  .intro{
    color: #868A93;
    line-height: 20px;
  }
  .basic-card{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .lock-card{
    padding:15px 10px;
  }
  .icon{
    width:18px;
    height:18px;
    color:#FACF1A;
  }
  .lock-text{
    margin-left:10px;
    font-size: 16px;
  }
  .monetary{
    font-size:16px;
  }
  .contact-item{
    line-height: 20px;
  }
}
</style>
