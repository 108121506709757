<template>
  <div class="invite">
    <van-sticky>
      <van-nav-bar title="邀请好友" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="warpper">
      <div class="qrcode">
        <qrcode-vue :value="webUrl+userInfo.inviteCode" :size="150" level="H" />
      </div>
      <div class="content">
        <div class="text1">*好友也可在注册时直接填写您得邀请码</div>
        <div class="text2">您的邀请码</div>
        <div class="text3">{{userInfo.inviteCode}}</div>
        <input ref="inputCode" :value="userInfo.inviteCode" style="opacity:0;position:absolute;" />
        <input ref="inputUrl" :value="webUrl + userInfo.inviteCode" style="opacity:0;position:absolute;" />
        <div class="btn1"><van-button round plain type="primary" @click="copyCode">复制邀请码</van-button></div>
        <div class="btn2 marginBottom"><van-button round  type="primary" @click="copyUrl">复制邀请链接</van-button></div>
      </div>
      <!-- <div class="footer">
        <div v-if="invitefriends_tips1 && invitefriends_tips1.length>0">
          <div v-for="(item, index) in invitefriends_tips1" :key="index">{{item}}</div>
        </div>
        <div v-if="invitefriends_tips2 && invitefriends_tips2.length>0">
          <div v-for="(item, index) in invitefriends_tips2" :key="index">{{item}}</div>
        </div>
      </div> -->
      <CommonTitle title="推广福利"  style="margin-top:20px;" />
      <Tips :data="lang.invitefriends_tips1" v-if="lang && lang.invitefriends_tips1.length>0" />
      <CommonTitle title="推广说明" />
      <Tips :data="lang.invitefriends_tips2" v-if="lang && lang.invitefriends_tips2.length>0" />
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
import QrcodeVue from 'qrcode.vue'
import { Toast } from 'vant'
import Tips from '@/components/Common/Tips.vue'
import CommonTitle from '@/components/Common/Title.vue'

export default {
  name: 'Invite',
  components: {
    QrcodeVue,
    Tips,
    CommonTitle
  },
  data () {
    return {
      lang: null,
      webUrl: null,
      share_text: '成人版美团！看色片，找楼凤，寻会所，操嫩模，尽在色影app（邀请一人送2元，10元起提，四代收益，看片零撸月入过万）',
      userInfo: this.$store.getters.userInfo
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    copyCode () {
      const text = this.userInfo.inviteCode
      const input = this.$refs.inputCode
      input.value = text
      input.select()
      document.execCommand('copy')
      Toast.success('复制成功')
    },
    copyUrl () {
      if (!this.webUrl) {
        Toast.fail('复制失败，请重新刷新页面')
        return
      }
      const text = this.share_text + ' ' + this.webUrl + this.userInfo.inviteCode
      const input = this.$refs.inputUrl
      input.value = text
      input.select()
      document.execCommand('copy')
      Toast.success('复制成功')
    },
    getLang () {
      get('/common/v1.system/getLang').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.lang = res.data
      })
    },
    getConfig () {
      get('/common/v1.system/getConfig?key=share_text&type=text').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.share_text = res.data
      })
    },
    getSiteUrl () {
      get('/common/v1.system/siteUrl').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.webUrl = res.data.web_url
      })
    }
  },
  created () {
    this.getLang()
    this.getConfig()
    this.getSiteUrl()
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.invite {
  .warpper {
    padding: 10px;
  }
  .qrcode{
    margin:30px auto;
    text-align: center;
    background: #fff;
    border-radius: var(--van-card-border-radius);
    padding: 10px;
    width:150px;
  }
  .content{
    text-align: center;
  }
  .btn1, .btn2{
    margin-top: 20px;
  }
  .marginBottom{
    margin-bottom: 20px;
  }
  .text2{
    margin-top:10px;
    font-size:14px;
  }
  .text3{
    margin-top:10px;
    font-size:16px;
  }
  .footer{
    position: absolute;
    bottom: 20px;
    padding: 10px;
  }
}
</style>
