<template>
  <div class="bank-add">
    <van-sticky>
      <van-nav-bar title="添加银行卡" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="submit" style="color:#D91A6E">提交</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="label"
            label="标签"
            name="标签"
            placeholder="标签"
            clearable
          />
          <van-field
            v-model="card_name"
            label="收款人姓名"
            name="收款人姓名"
            placeholder="收款人姓名"
            clearable
          />
          <van-field
            v-model="card_number"
            label="银行卡号"
            name="银行卡号"
            placeholder="银行卡号"
            clearable
          />
          <van-field
            v-model="bank_name"
            is-link
            readonly
            name="收款银行"
            label="收款银行"
            placeholder="收款银行"
            @click="showBank = true"
          />
          <van-popup v-model:show="showBank" position="bottom">
            <van-picker
              :columns="bankArr"
              :columns-field-names="{text:'bank_name',value:'id'}"
              @confirm="onBankConfirm"
              @cancel="showBank = false"
            />
          </van-popup>
          <van-field
            v-model="branch"
            label="支行名称"
            name="支行名称"
            placeholder="支行名称"
            clearable
          />
          <van-field
            v-model="city"
            is-link
            readonly
            name="开户城市"
            label="开户城市"
            placeholder="点击选择城市"
            @click="$router.push({name:'CityPicker'})"
          />
          <van-field
            v-model="idcard_number"
            label="身份证号码"
            name="身份证号码"
            placeholder="身份证号码"
            clearable
          />
        </van-cell-group>
        <div style="margin: 16px 0;">
          <van-button round block type="primary" @click="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { post, get } from '@/utils/request'
import { Toast } from 'vant'
export default {
  name: 'AddBank',
  props: {
  },
  components: {
  },
  data () {
    return {
      showBank: false,
      adcode: 0,
      city: '',
      bank_id: 0,
      bank_name: '',
      label: '',
      branch: '',
      card_name: '',
      card_number: '',
      idcard_number: '',
      bankArr: []
    }
  },
  created () {
    this.getBankList()
  },
  methods: {
    getBankList () {
      get('/asset/v1.assets/getBankList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data) {
          this.bankArr = res.data
        }
      })
    },
    onBankConfirm (value) {
      this.bank_id = value.id
      this.bank_name = value.bank_name
      this.showBank = false
    },
    submit () {
      // eslint-disable-next-line eqeqeq
      if (this.label == '') {
        Toast.fail('请输入标签')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.card_name == '') {
        Toast.fail('请输入收款人姓名')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.card_number == '') {
        Toast.fail('请输入银行卡号')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.branch == '') {
        Toast.fail('请输入支行名称')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.adcode == '') {
        Toast.fail('请选择开户城市')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.idcard_number == '') {
        Toast.fail('请输入身份证号码')
        return
      }
      const toastkey = Toast.loading('提交中', 20)
      post('/asset/v1.assets/bindBank', {
        adcode: this.adcode,
        bank_id: this.bank_id,
        label: this.label,
        branch: this.branch,
        card_name: this.card_name,
        card_number: this.card_number,
        idcard_number: this.idcard_number
      }).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success('提交成功')
        setTimeout(() => {
          this.$router.push({ name: 'BankManage' })
        }, 500)
      })
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
    })
  }
}
</script>

<style lang="scss">
.bank-add {
  .wrapper {
    padding: 10px;
  }
  .van-uploader{
    width: 130px;
    height:170px;
  }
  .van-uploader__upload{
    width: 130px;
    height:170px;
  }
  .van-uploader__preview-image{
    width: 125px;
    height:165px;
  }
  .van-uploader__preview-delete{
    width: 25px;
    height:25px;
  }
  .van-uploader__preview-delete-icon{
    font-size: 25px;
  }
}
</style>
