<template>
  <div class="pay">
    <van-nav-bar
      title="充值"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    />
    <div class="iframe-wrapper">
<!--      <div class="pay-tip" v-if="blank==0"><a class="sel_btn" ref="payBtn" :href="url" target="_blank">点击立即支付</a></div>-->
      <div class="pay-tip">
        <van-button type="primary" size="large" round class="submit-btn" @click="open_with_browser()">点击马上支付(Android)</van-button>
      </div>
      <div class="pay-tip">
        <van-button type="primary" size="large" round class="submit-btn" @click="go_index()">点击马上支付(Other)</van-button>
      </div>
      <div class="pay-tip">如无法打开窗口，请点击上面按钮进行支付</div>
    </div>
  </div>
</template>

<script>
import { isAndroid } from '@/utils/common'
export default {
  name: 'Pay2',
  created () {
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    open_with_browser () {
      if (isAndroid() && window.AndroidInterface) {
        window.AndroidInterface.openBrowser(this.url)
      } else {
        window.open(this.url)
      }
    },
    go_index () {
      window.location.href = this.url
    }
  },
  data () {
    return {
      url: decodeURIComponent(this.$route.query.url),
      blank: this.$route.query.blank
    }
  },
  setup () {
  },
  mounted () {
    // this.$refs.payBtn.click()
  }
}
</script>
<style lang="scss" scoped>
.pay{
  .pay-tip{
    font-size:14px;
    margin-top:30px;
    width: 100%;
    text-align: center;
  }
  .sel_btn{
    height: 21px;
    line-height: 21px;
    padding: 10px 15px;
    background: #02bafa;
    border: 1px #26bbdb solid;
    border-radius: var(--van-card-border-radius);
    color: #fff;
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    outline: none;
  }
  a:visited{
    color: #fff;
  }
}
</style>
