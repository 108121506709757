<template>
  <div class="paypassword">
    <van-sticky>
      <van-nav-bar
        left-arrow
        :border=false
        @click-left="onClickLeft"
      />
    </van-sticky>
    <div class="section">
      <p class="lbl_title">支付密码</p>
      <p class="lbl_subtitle">通过手机验证码设置或修改，如手机遗失请联系客服</p>
      <div>
        <van-field v-model="password" required type="password"  placeholder="请输入支付密码" class="input-item"  left-icon="lock">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password2" required type="password" placeholder="请再次输入支付密码" class="input-item" left-icon="lock">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="code" required center clearable type="digit" placeholder="请输入手机验证码" class="input-item"  left-icon="lock">
          <template #button>
            <van-button size="mini" type="primary" class="input-right-item" v-if="setCode" @click="getCode" :disabled="disabled" >{{setCode}}</van-button>
          </template>
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'sms']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-button type="primary" block round class="btn-register" @click="submit()">确定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request'
import { Toast } from 'vant'
import validator from 'validator'

export default {
  name: 'Register',
  components: {},
  data () {
    return {
      password: '',
      password2: '',
      code: '',
      setCode: '获取验证码',
      disabled: false
    }
  },
  setup () {
    const onClickLeft = () => history.back()
    return {
      onClickLeft
    }
  },
  methods: {
    getCode () {
      post('/common/v1.user/getPhoneCaptcha').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.validateBtn()
        return true
      })
    },
    validateBtn () {
      let time = 60
      const timer = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        if (time == 0) {
          clearInterval(timer)
          this.disabled = false
          this.setCode = '发送验证码'
        } else {
          this.setCode = time + '秒后重试'
          this.disabled = true
          // eslint-disable-next-line no-const-assign
          time--
        }
      }, 1000)
    },
    submit  () {
      if (this.password.length < 6) {
        Toast.fail('密码长度不能小于6个字符')
        return
      }
      if (this.password.length > 32) {
        Toast.fail('密码长度不能大于32个字符')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.password != this.password2) {
        Toast.fail('两次密码必须一致')
        return
      }
      if (!validator.isInt(this.code, { min: 1000, max: 9999 })) {
        Toast.fail('验证码错误')
        return
      }
      const loading = Toast.loading('设置中', 20)
      post('/common/v1.user/updatePayPassword',
        {
          password: this.password,
          repassword: this.password2,
          phonecode: this.code
        }
      ).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        post('/common/v1.user/getUserInfo').then(res => {
        // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            Toast.fail(res.msg)
            return
          }
          Toast({
            type: 'success',
            message: '设置成功',
            onClose: () => {
              this.$router.back()
            }
          })
        })
      })
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.paypassword{
  .section{
    padding:20px;
  }
  .lbl_title{
    font-size: 20px;
    color:white;
    margin-bottom: 10px;
  }
  .lbl_subtitle{
    font-size: 12px;
    color:white;
    margin-bottom: 30px;
  }
  .input-item{
    margin:10px 0;
    border-radius: 20px;
  }
  .input-right{
    margin:0;
    padding:0;
  }
  .input-right-item{
    margin: 0;
    border-radius: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .btn-register{
    margin-top: 30px;
  }
  .icon-box{
    min-width: 40px;;
  }
  .input-icon{
    color:#D91A6E;
    width: 20px;
    height: 20px;
  }
}
</style>
