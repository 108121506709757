<template>
  <div class="video-search">
    <div class='wrapper'>
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <template v-if="list && list.length>0">
          <div class="videos">
            <VideoItem
              v-for="item in list" :key="item.vod_id"
              :vod_id="item.vod_id"
              :vod_pic="item.vod_pic"
              :vod_name="item.vod_name"
              :vod_watch="item.vod_watch"
              :vip="item.is_vip"
              :level="item.level"
              :price="item.price"
              :numColumns="2"
              class="item"
            />
          </div>
        </template>
      </van-list>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request'
import VideoItem from '@/components/Video/Item.vue'

export default {
  name: 'TabTopic',
  props: {
    tabid: { type: String, required: true },
    name: { type: String, required: true }
  },
  components: {
    VideoItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      loading: false,
      finished: false,
      list: []
    }
  },
  created () {
    this.getTopicsDetail()
  },
  methods: {
    getTopicsDetail () {
      post('/common/v1.topics/detail', {
        page: this.page,
        id: this.tabid
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        if (res.data == null || res.data.length === 0) {
          this.finished = true
          return
        }
        this.total = res.data.videos.total
        const data = res.data.videos.data
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.videos.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onLoad () {
      this.page++
      this.getTopicsDetail()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.video-search{
  .wrapper{
    padding: 10px;
  }
  .icon{
    width:22px;
    height:22px;
  }
  .label-item{
    margin:0 15px 5px 0;
  }
  .videos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    .item{
      height: 125px;
      flex: 0 0 49%;
      overflow: hidden;
    }
  }
  .icon2{
    width: 18px;
    height: 18px;
    color: #fff;
  }
  .btn{
    width: 40px;
    height: 40px;
    line-height: 45px;
    border-radius: 20px;
    background: var(--van-button-primary-background-color);
    text-align: center;
  }
  .van-field__control{
    color: #fff !important;
  }
}
</style>
