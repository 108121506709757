<template>
  <div class="recommand-wrap" v-for="(items, k) of topics" :key="items.id">
    <CommonTitle :title="items.name" :subtitle="items.desc" />
    <div ref="wrapper">
      <div class="videos">
        <VideoItem
          v-for=" ( item, index ) in items.videos"
          :key="item.video_info.vod_id"
          :vod_id="item.video_info.vod_id"
          :vod_pic="item.video_info.vod_pic"
          :vod_name="item.video_info.vod_name"
          :vip="item.video_info.is_vip"
          :level="item.video_info.level"
          :price="item.video_info.price"
          :vod_watch="item.video_info.vod_watch"
          :height="((items.banner==1 && index==0)?160:85)"
          :numColumns="(items.banner == 1 ? (index == 0 ? 1 : items.cells) : items.cells)"
          :display="(items.banner == 1 ? (index>(Number(items.rows*items.cells))?false:true) : (index>(Number(items.rows*items.cells)-1)?false:true))"
          class="item"
        />
      </div>
    </div>
    <van-row  justify="space-around">
      <van-col span="12">
        <van-button color="#1F232B" size="mini" block class="topic-bottom-btn" @click="moreVideo(items.id, items.name)">
          <font-awesome-icon :icon="['fas', 'play-circle']" class="icon2" />
          &nbsp;更多精彩影片
        </van-button>
      </van-col>
      <van-col span="12">
        <van-button color="#1F232B" size="mini" block class="topic-bottom-btn" @click="changeVideo(items.id, k)"  style="float:right;">
          <font-awesome-icon :icon="['fas', 'sync']" class="icon2" />
          &nbsp;换一批
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import CommonTitle from '../Common/Title.vue'
import VideoItem from '@/components/Video/Item.vue'

export default {
  name: 'HomeTopic',
  props: {
    topics: {
      type: Array,
      required: true
    },
    changeVideo: {
      type: Function
    }
  },
  components: {
    CommonTitle,
    VideoItem
  },
  data () {
    return {

    }
  },
  methods: {
    moreVideo (id, name) {
      this.$router.push({ name: 'VideoMore', params: { id, name } })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.recommand-wrap {
  // background: #fff;
  width: 100%;
  // border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
}

.videos{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
}
.item{
  width: 100%;
  border-radius: var(--van-card-border-radius);
  overflow: hidden;
}
.topic-bottom-btn{
  font-size:12px;
  width:98%;
  height: 32px;
  border-radius: var(--van-card-border-radius);
}
.icon2{
  width:13px;
  height:15px;
  color:#D91A6E;
  vertical-align: -30%;
}
</style>
