<template>
  <div class='ofo-search'>
    <van-sticky>
      <van-nav-bar title="楼凤搜索" :border=false>
        <template #left>
            <font-awesome-icon :icon="['fas', 'times']" class="icon" @click="$router.back()" />
        </template>
      </van-nav-bar>
      <van-search
        show-action
        shape="round"
        v-model="keyword"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="search" v-if="!searched">
            <div class="btn">
              <font-awesome-icon :icon="['fas', 'search']" class="icon2" />
            </div>
          </div>
          <div @click="reset" v-if="searched">
            <div class="btn">
              <font-awesome-icon :icon="['fas', 'times']" class="icon2" />
            </div>
          </div>
        </template>
      </van-search>
    </van-sticky>
    <div class='wrapper'>
      <div v-if="!searched">
        <CommonTitle title="热门搜索" />
        <div class="label-wrapper">
          <van-tag
            type="primary"
            round
            size="large"
            v-for="item in ofoLabel"
            :key="item.keyword"
            class="label-item"
            @click="clickLabel(item.keyword)"
          >
            {{item.keyword}}
          </van-tag>
        </div>
        <CommonTitle title="搜索历史" />
        <div class="label-wrapper" v-if="searchHistory && searchHistory.length>0">
          <van-tag
            type="primary"
            round
            size="large"
            v-for="(item, index) in searchHistory"
            :key="index"
            class="label-item"
            @click="clickLabel(item)"
          >
            {{item}}
          </van-tag>
        </div>
      </div>
      <div v-if="searched">
        <CommonTitle :title="'在'+city+'搜索 “'+rskey+'” 的结果'" />
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-if="result && result.length>0">
            <div class="ofos">
              <OfoItem
                v-for="item in result"
                :key="item.id"
                :ofo_id="item.id"
                :avatar="item.avatar"
                :name="item.name"
                :type="item.type"
                :city="item.city"
                :services="item.services"
                :quality="item.quality"
                :monetary="item.monetary"
                :created_at_str="item.created_at_str"
                :look_ofo="item.look_ofo"
                :ofo_up="item.ofo_up"
                :ofo_store="item.ofo_store"
                :status="item.status"
                :margin="item.margin"
                />
            </div>
          </template>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { get, post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import OfoItem from '@/components/Ofo/Item.vue'

export default {
  name: 'OfoSearch',
  props: {
  },
  components: {
    CommonTitle,
    OfoItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      searched: false,
      ofoLabel: [],
      searchHistory: [],
      keyword: '',
      loading: false,
      finished: false,
      rskey: '',
      result: [],
      adcode: '110100',
      city: '北京',
      empty: false
    }
  },
  created () {
    this.getOfoLabels()
    this.getSearchHistory()
  },
  methods: {
    getOfoLabels () {
      get('/common/v1.ofo/keyword').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.ofoLabel = res.data.data
      })
    },
    getSearchHistory () {
      this.searchHistory = this.$store.getters.ofoSearchHistory || []
    },
    reset () {
      this.keyword = ''
      this.searched = !this.searched
    },
    clickLabel (name) {
      const keyword = name.trim()
      // eslint-disable-next-line eqeqeq
      if (keyword == '') {
        Toast.fail('标签有误')
        return
      }
      this.keyword = keyword
      this.search()
    },
    search () {
      let loading
      const keyword = this.keyword.trim()
      // eslint-disable-next-line eqeqeq
      if (keyword == '') {
        Toast.fail('关键字不能为空')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.page == 1) {
        loading = Toast.loading('加载中', 20)
      }
      const location = this.$store.getters.location || {}
      if (location && location.id) {
        this.adcode = location.id
        this.city = location.city
      }
      this.empty = false
      post('/common/v1.ofo/index',
        {
          page: this.page,
          sort: 'follow',
          order: 'desc',
          keyword,
          adcode: this.adcode
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (this.page == 1) {
          loading.clear()
        }
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.loading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        this.searched = true
        this.rskey = keyword
        if (data == null || data.length === 0) {
          this.finished = true
          this.empty = true
          this.result = []
          return
        }
        if (res.data.current_page > 1) {
          this.result = this.result.concat(data)
        } else {
          this.result = data
        }
        if (this.result.length >= this.total) {
          this.finished = true
        }
        const searchHistory = this.$store.getters.ofoSearchHistory || []
        if (searchHistory.indexOf(keyword) === -1) {
          searchHistory.unshift(this.keyword)
          this.searchHistory = searchHistory
          this.$store.dispatch('setOfoSearchHistory', searchHistory)
        }
      })
    },
    onLoad () {
      this.page++
      this.search()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.ofo-search{
  .wrapper{
    padding: 10px;
  }
  .icon{
    width:22px;
    height:22px;
  }
  .label-item{
    margin:0 15px 5px 0;
  }
  .ofos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    .item{
      height: 125px;
      flex: 0 0 49%;
      overflow: hidden;
    }
  }
  .icon2{
    width: 18px;
    height: 18px;
    color: #fff;
  }
  .btn{
    width: 40px;
    height: 40px;
    line-height: 45px;
    border-radius: 20px;
    background: var(--van-button-primary-background-color);
    text-align: center;
  }
  .van-field__control{
    color: #fff !important;
  }
}
</style>
