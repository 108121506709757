<template>
  <div class="ofo-my">
    <van-sticky>
      <van-nav-bar title="我的楼凤" :border=false>
        <template #right>
          <div v-if="userInfo.vip_level==3 && userInfo.vip_deadline_type==3" @click="$router.push({name:'AddOfo'})" style="color:#D91A6E">
            新增
          </div>
        </template>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-sticky>
        <van-tabs v-model:active="selectedIndex" @click-tab="changeTab">
          <van-tab v-for="(item, index) in types" :title="item.name" :key="item.id" :name="index">
          </van-tab>
        </van-tabs>
      </van-sticky>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-top:10px;">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <template v-if="list && list.length>0">
            <div class="ofo-item">
              <OfoItem
                v-for="item in list"
                :key="item.id"
                :ofo_id="item.id"
                :avatar="item.avatar"
                :name="item.name"
                :type="item.type"
                :city="item.city"
                :services="item.services"
                :quality="item.quality"
                :monetary="item.monetary"
                :created_at_str="item.created_at"
                :look_ofo="item.look_ofo"
                :ofo_up="item.ofo_up"
                :ofo_store="item.ofo_store"
                :reason="item.reason"
                :editable="true"
                :status="item.status"
                :margin="item.margin"
                :onEdit="onEdit"
                :onMargin="onMargin"
                :onPullOff="onPullOff"
                />
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :code="code"
    :onClose="closeDialog"
    :onPay="onPay"
    :onDeposit="onDeposit"
    :onOpenVip="onOpenVip"
  />
</template>

<script>
import { get, post } from '@/utils/request'
import OfoItem from '@/components/Ofo/Item.vue'
import { Toast } from 'vant'
import Dialog from '@/components/Common/Dialog.vue'
export default {
  name: 'MyOfo',
  props: {

  },
  components: {
    OfoItem,
    Dialog
  },
  data () {
    return {
      page: 1,
      total: 0,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      code: 0,
      ofo_id: 0,
      userInfo: this.$store.getters.userInfo,
      types: [
        { name: '已上架', type: '1' },
        { name: '待审核', type: '2' },
        { name: '已下架', type: '3' },
        { name: '不通过', type: '4' },
        { name: '全部', type: '5' }
      ],
      selectedIndex: 0
    }
  },
  created () {
    this.getList()
  },
  methods: {
    changeTab (data) {
      this.selectedIndex = data.name
      this.onRefresh()
    },
    getList () {
      get('/common/v1.ofo/mine', {
        page: this.page,
        type: this.types[this.selectedIndex].type
      }).then(res => {
        this.loading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          // eslint-disable-next-line eqeqeq
          if (this.page == 1) {
            this.list = data
          }
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    onEdit (id) {
      // console.log(id)
      this.$router.push({ name: 'EditOfo', params: { id } })
    },
    onMargin (id) {
      this.ofo_id = id
      this.code = 5004
      this.dialog = true
      this.title = '缴纳保证金（2000色币）'
      this.subtitle = '享受跟多特权'
      this.yesBtnText = '立即缴纳'
    },
    closeDialog () {
      this.dialog = false
    },
    onPay () {
      this.dialog = false
      const toast = Toast.loading('提交中')
      post('/common/v1.ofo/addMargin', {
        ofo_id: this.ofo_id
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          // eslint-disable-next-line eqeqeq
          if (res.code == 10056) {
            this.code = 5005
            this.dialog = true
            this.title = res.msg
            this.subtitle = '缴纳保证金享受更多特权'
            this.yesBtnText = '立即充值'
          } else {
            Toast.fail(res.msg)
          }
        } else {
          this.onRefresh()
        }
      })
    },
    onDeposit () {
      this.dialog = false
      setTimeout(() => {
        this.$router.push({ name: 'Deposit' })
      }, 300)
    },
    onPullOff (id) {
      this.ofo_id = id
      this.code = 5003
      this.dialog = true
      this.title = '您确定下架该楼凤吗？'
      this.subtitle = '如有缴纳保证金，将会在15个工作日退还到账户'
      this.yesBtnText = '立即下架'
    },
    onOpenVip () { // 下架楼凤
      this.dialog = false
      const toast = Toast.loading('提交中')
      post('/common/v1.ofo/deleteOfo', {
        ofo_id: this.ofo_id
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
        } else {
          this.onRefresh()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ofo-my {
  .wrapper{
    padding: 10px;
  }
}
</style>
