<template>
  <div class="comment-detail">
    <van-sticky>
      <van-nav-bar title="评论详细" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <div class="background">
        <van-row>
          <van-col span="4">
            <font-awesome-icon v-if="data && data.headpic.length<=0" :icon="['fas', 'user-circle']" class="comment-icon" />
            <van-image
              v-if="data && data.headpic.length>0"
              round
              width="40px"
              height="40px"
              :src="imageUrl+data.headpic"
            />
          </van-col>
          <van-col span="20">
            <div class="nickname">{{data && data.nickname}}</div>
            <div class="datetime">{{data && data.comment_time}}</div>
          </van-col>
        </van-row>
        <div class="content">{{data && data.comment_content}}</div>
      </div>
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.comment_id">
            <CommentItem
              :isreply="true"
              :type="type"
              :comment_id="item.comment_id"
              :headpic="item.headpic"
              :nickname="item.nickname"
              :vod_id="item.vod_id"
              :comment_content="item.comment_content"
              :comment_up="item.comment_up"
              :comment_time="item.comment_time"
            />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
  <van-action-bar style="background:#1F232B;padding:5px;">
    <van-cell-group inset style="flex:4">
      <van-field
        v-model="content"
        placeholder="发表评论..."
        maxlength="100"
      />
    </van-cell-group>
    <van-action-bar-button type="primary" text="立即评论" @click="onComment()" />
  </van-action-bar>
</template>

<script>
import { get, post } from '@/utils/request'
import { Toast } from 'vant'
import CommentItem from '@/components/Common/CommentItem'
import { imageUrl } from '@/config/index.js'
export default {
  name: 'CommentDetail',
  props: {
    type: { type: String, default: 'video' },
    id: { type: Number }
  },
  components: {
    CommentItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      list: [],
      loading: false,
      refreshing: false,
      data: null,
      content: '',
      imageUrl
    }
  },
  created () {
    this.getDetail()
    this.getList()
  },
  methods: {
    getDetail () {
      let url = ''
      if (this.type === 'video') {
        url = '/common/v1.video/comment_detail'
      } else if (this.type === 'club') {
        url = '/common/v1.club/comment_detail'
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/comment_detail'
      }
      post(url, {
        comment_id: this.id
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.data = res.data
      })
    },
    getList () {
      let url = ''
      let data = {}
      if (this.type === 'video') {
        url = '/common/v1.video/comment_reply'
        data = { page: this.page, comment_id: this.id }
      } else if (this.type === 'club') {
        url = '/common/v1.club/get_comment_reply'
        data = { page: this.page, comment_id: this.id }
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/get_comment_reply'
        data = { page: this.page, comment_id: this.id }
      }
      get(url, data).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        if (res.data == null || res.data.length === 0) {
          this.finished = true
          // eslint-disable-next-line eqeqeq
          if (this.page == 1) {
            this.list = []
          }
          return
        }
        this.total = res.data.total
        const data = res.data.data
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    onComment () {
      if (this.content === '') {
        Toast.fail('请输入评论内容')
        return
      }
      const toastKey = Toast.loading('提交中')
      let url = ''
      let data = {}
      if (this.type === 'video') {
        url = '/common/v1.videohandle/comment_reply'
        data = {
          comment_id: this.id,
          reply_content: this.content
        }
      } else if (this.type === 'club') {
        url = '/common/v1.club/comment_reply'
        data = {
          comment_id: this.id,
          reply_content: this.content
        }
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/comment_reply'
        data = {
          comment_id: this.id,
          reply_content: this.content
        }
      }
      post(url, data).then(res => {
        toastKey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success(res.msg)
        this.content = ''
        setTimeout(() => {
          this.onRefresh()
        }, 200)
      })
    },
    onUp (commentid) {
      const toastKey = Toast.loading('提交中')
      let url = ''
      let data = {}
      if (this.type === 'video') {
        url = '/common/v1.videohandle/comment_up'
        data = {
          comment_id: commentid
        }
      } else if (this.type === 'club') {
        url = '/common/v1.club/comment_up'
        data = {
          comment_id: commentid
        }
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/comment_up'
        data = {
          comment_id: commentid
        }
      }
      post(url, data).then(res => {
        toastKey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        setTimeout(() => {
          this.onRefresh()
        }, 200)
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.comment-detail {
  .wrapper {
    padding: 10px;
  }
  .background{
    padding: 10px;
    background:#161A22;
    border-radius: var(--van-card-border-radius);
  }
  .comment-icon{
    width: 40px;
    height: 40px;
  }
  .nickname{
    font-size: 16px;
    height:25px;
    line-height: 25px;
  }
  .datetime{
    font-size:12px;
    color: #868A93;
    height:15px;
    line-height: 15px;
  }
  .content{
    padding: 20px 0;
    font-size:14px;
  }
}
</style>
