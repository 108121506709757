<template>
  <div class="photos">
    <van-sticky>
      <van-nav-bar title="我的相册" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <div class="body" v-if="!right">
        <div style="line-height:40px;">您没有权限上传图片，请及时充值或开通VIP</div>
        <van-button type="primary" size="small" round  @click="$router.push({name:'OpenVip'})">立即开通VIP</van-button>
      </div>
      <van-uploader
        v-if="right"
        v-model="photos"
        :max-count="6"
        :after-read="addPhoto"
        :before-delete="delPhoto"
      />
      <div class="footer">最多可上传6张图片，每次只能上传1张图片</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { post } from '@/utils/request'
import { imageUrl } from '@/config/index.js'
export default {
  name: 'Photos',
  components: {
  },
  data () {
    return {
      photos: [],
      userInfo: this.$store.getters.userInfo,
      imageUrl
    }
  },
  computed: {
    right: (data) => {
      // eslint-disable-next-line eqeqeq
      if (data.userInfo.isdeposit == 1) {
        return true
      }
      return false
    }
  },
  methods: {
    getPhotos () {
      const toastkey = Toast.loading('加载中')
      post('/common/v1.user/getPhotoAlbum').then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          this.photos.push({ url: imageUrl + element.images_url, name: element.id })
        }
      })
    },
    addPhoto (file) {
      this.uploadImg(file)
    },
    delPhoto (file, detail) {
      this.deletePhoto(file.name, detail.index)
    },
    uploadImg (file) {
      file.status = 'uploading'
      file.message = '上传中...'
      post('/common/v1.user/uploadImge', {
        base64_image: file.content
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          file.status = 'failed'
          file.message = res.msg
          return
        }
        setTimeout(() => {
          this.setPhoto(res.data.imgUrl)
        }, 200)
      }).catch(err => {
        console.log(err)
        Toast.fail('上传失败')
        this.headpicArr = []
      })
    },
    setPhoto (imgUrl) {
      const toastkey = Toast.loading('提交中')
      post('/common/v1.user/photoAlbum',
        {
          images_url: imgUrl,
          images_remarks: ''
        }
      ).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.photos.splice((this.photos.length - 1), 1)
        this.photos.push({ url: imageUrl + imgUrl, name: res.data.id })
        Toast.success('提交成功')
      })
    },
    deletePhoto (photoId, index) {
      const toastkey = Toast.loading('删除中')
      post('/common/v1.user/delPhoto', {
        images_id: photoId
      }).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.photos.splice(index, 1)
      })
    }
  },
  created () {
    this.getPhotos()
  }
}
</script>

<style lang="scss">
.photos{
  .wrapper{
    padding:10px;
  }
  .van-uploader__upload, .van-uploader__preview-image, .van-uploader__mask{
    width: 118px;
    height: 170px;
    border-radius: 15px;
    overflow: 'hidden';
  }
  .van-uploader__preview-delete{
    width: 25px;
    height:25px;
    border-top-right-radius: 15px;
  }
  .van-uploader__preview-delete-icon{
    font-size: 25px;
  }
  .body{
    color: #868A93;
    font-size:12px;
    text-align: center;
    width: 100%;
    margin-top:20px;
  }
  .footer{
    color: #868A93;
    font-size:12px;
    text-align: center;
    margin-top: 50px;
  }
}
</style>
