<template>
  <div class="login">
    <van-sticky>
      <van-nav-bar
        left-arrow
        :border=false
        @click-left="onClickLeft"
      />
    </van-sticky>
    <div class="section">
      <p class="lbl_title">欢迎回来</p>
      <p class="lbl_subtitle">登录您的色影游客账户</p>
      <div>
        <van-field v-model="token" class="input-item" placeholder="游客凭证">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-button type="primary" block round class="btn-login mt20" @click="tokenLogin()">凭证登录</van-button>
        <van-divider v-if="false" :style="{borderColor:'#fff'}">新用户</van-divider>
        <van-button v-if="false" type="warning" block round class="btn-login" @click="guestLogin()">新游客登录</van-button>
        <div class="forgot" @click="jumpPage('Login')">手机号码登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../utils/request'
import { Toast } from 'vant'

export default {
  data () {
    return {
      token: '',
      invite_code: ''
    }
  },
  setup () {
    const onClickLeft = () => history.back()
    return {
      onClickLeft
    }
  },
  methods: {
    jumpPage (name) {
      this.$router.push({ name })
      return null
    },
    tokenLogin  () {
      // eslint-disable-next-line eqeqeq
      // if (this.token.length < 40) {
      //   Toast.fail('游客凭证格式不正确')
      //   return
      // }
      this.$store.dispatch('removeTokenData')
      const toast = Toast.loading('登录中')
      post('/common/v1.login/guestlogin',
        {
          token: this.token,
          client_secret: 'jNv8YgpOeFRas1Pa4Fn6FmWkIefNGlylwkTPTVQr',
          client_id: 1
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.$store.dispatch('setTokenData', res.data)
        post('/common/v1.user/getUserInfo').then(res => {
        // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            Toast.fail(res.msg)
            return
          }
          toast.clear()
          this.$store.dispatch('setUserInfo', res.data)
          this.$router.replace({ name: 'Home' })
        })
      })
    },
    guestLogin () {
      const toast = Toast.loading('加载中')
      post('/common/v1.register/user2',
        {
          type: 'guest',
          agreed: '1',
          client_secret: 'jNv8YgpOeFRas1Pa4Fn6FmWkIefNGlylwkTPTVQr',
          client_id: '1',
          inviteCode: this.invite_code
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.$store.dispatch('setTokenData', res.data)
        post('/common/v1.user/getUserInfo').then(res => {
        // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            Toast.fail(res.msg)
            return
          }
          toast.clear()
          this.$store.dispatch('setUserInfo', res.data)
          this.$router.replace({ name: 'Home' })
        })
      })
    }
  },
  created () {
  },
  name: 'GuestLogin',
  components: {}
}
</script>

<style lang="scss">
.login{
  .section{
    padding:20px;
  }
  .lbl_title{
    font-size:20px;
    color:white;
    margin-bottom: 10px;
  }
  .lbl_subtitle{
    font-size: 12px;
    color:white;
    margin-bottom: 30px;
  }
  .input-item{
    margin:10px 0;
    border-radius: 20px;
  }
  .btn-login{
    margin-top: 10px;
  }
  .forgot{
    margin: 20px auto;
    text-align: center;
    color:white;
    font-size: 12px;
  }
  .icon-box{
    min-width: 40px;;
  }
  .input-icon{
    color:#D91A6E;
    width: 20px !important;
    height: 20px;
  }
  .mt20{
    margin-top:20px;
  }
}
</style>
