<template>
  <div class="deposit">
    <van-sticky>
      <van-nav-bar title="充值" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="$router.push({ name: 'DepositRecord', params:{type:'DOIT'} })">充值记录</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="warpper">
      <div v-if="payAmountList && payAmountList.length>0">
        <div>
          <van-row justify="space-between">
            <van-col
              span="5"
              v-for="(item, index) in payAmountList"
              :key="index"
              class="money-item"
              @click="changeMoney(item)"
              :style="{background: '#1F232B'}"
            >
              <van-image v-if="Number(amount) == Number(item)" width="35px" height="35px" :src="selectImage" class="selectImage" />
              <div class="money-amount" :style="{color:'#F0B90B'}">{{item}}</div>
              <div class="currency">色币</div>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="actual-pay">
        {{amount == -1 ? '不能操作' : '实际支付：'}}
        {{this.amount == -1? '': this.amount + '(色币) / ' + this.payMoney}}
      </div>
      <div class="payments">
        <CommonTitle title="支付方式" subtitle="" />
        <div class="background" v-if="paymentList.length>0">
            <PaymentItem
              v-for="item in paymentList"
              :key="item.id"
              :payWayType="this.payWayType"
              :payWayName="this.payWayName"
              :icon_name="item.icon_name"
              :color="item.color"
              :sub_color="item.sub_color"
              :payment_name="item.payment_name"
              :sub_name="item.sub_name"
              :type="item.type"
              :name="item.name"
              :chooseWay="this.chooseWay"
            />
        </div>
        <div class="background" v-if="paymentList.length<=0">
            <div class="nopayment">暂无通道，请选择其他金额</div>
        </div>
      </div>
      <van-button type="primary" size="large" block round class="submit-btn" @click="deposit()">立即充值</van-button>
      <Tips :data="lang.recharge_tips" v-if="lang && lang.recharge_tips.length>0" style="margin-top:20px;" />
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { get, post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import Tips from '@/components/Common/Tips.vue'
import PaymentItem from '@/components/Common/PaymentItem.vue'
import { isNumeric } from 'vant/lib/utils'
const payAmountList = [30, 50, 100, 200, 500, 800, 1000, 2000]

const selectImage = require('@/assets/icon/icon_recharge_select.png')
export default {
  name: 'Deposit',
  components: {
    CommonTitle,
    PaymentItem,
    Tips
  },
  data () {
    return {
      exchangerRate: 1,
      amount: 0,
      paymentList: [],
      allPayment: [],
      selectImage,
      payAmountList,
      payWayType: 0,
      payWayName: '',
      userInfo: this.$store.getters.userInfo,
      assetsData: this.$store.getters.assetsData,
      lang: null
    }
  },
  created () {
    this.getExchangerRate()
    this.getPaymentList()
    this.getLang()
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    changeMoney (amount) {
      this.amount = amount
      const newPayment = []
      if (this.allPayment.length > 0) {
        this.allPayment.forEach((item, index) => {
          // eslint-disable-next-line eqeqeq
          if (item.range == -1) {
            newPayment.push(item)
          // eslint-disable-next-line eqeqeq
          } else if (item.range.indexOf('-') != -1) {
            const rangeArr = item.range.split('-')
            if (
              Number(amount) >= Number(rangeArr[0]) &&
              Number(amount) <= Number(rangeArr[1])
            ) {
              newPayment.push(item)
            }
          // eslint-disable-next-line eqeqeq
          } else if (item.range.indexOf(',') != -1) {
            const rangeArr = item.range.split(',')
            // eslint-disable-next-line eqeqeq
            rangeArr.forEach((v, i) => {
            // eslint-disable-next-line eqeqeq
              if (Number(v) == Number(amount)) {
                newPayment.push(item)
                return null
              }
            })
          }
        })
        this.paymentList = newPayment
        this.payWayType = 0
        this.payWayName = ''
      }
    },
    chooseWay (type, name) {
      this.payWayType = type
      this.payWayName = name
    },
    getPaymentList () {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const device = (isiOS) ? 'ios' : 'android'
      get('/asset/v1.assets/paymentList?device=' + device).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.paymentList = res.data
        this.allPayment = res.data
      })
    },
    getExchangerRate () {
      get('/common/v1.system/getExchangerRate').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data && res.data.doit_cny) {
          this.canSubmit = true
          this.exchangerRate = parseFloat(res.data.doit_cny.rate)
        }
      })
    },
    deposit () {
      if (this.amount <= 0) {
        Toast.fail('请选择充值金额')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.payWayName.length == 0) {
        Toast.fail('请选择充值方式')
        return
      }
      const toast = Toast.loading('加载中', 20)
      post('/asset/v1.assets/deposit', {
        name: this.payWayName,
        type: this.payWayType,
        amount: this.amount
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        let query = ''
        let blank = 0
        for (const i in res.data) {
          // eslint-disable-next-line eqeqeq
          if (i != 'web_view_source' && i != 'url') {
            query += i + '=' + encodeURIComponent(res.data[i]) + '&'
          }
          // eslint-disable-next-line eqeqeq
          if (i == 'xapp-target') {
            blank = 1
          }
        }
        // eslint-disable-next-line eqeqeq
        if (query != '') {
          query = query.substring(0, query.length - 1)
        }
        let resurl = res.data.url
        // eslint-disable-next-line eqeqeq
        if (resurl.indexOf('?') == -1) {
          // eslint-disable-next-line eqeqeq
          if (query != '') {
            resurl = res.data.url + '?' + query
          }
        } else {
          const last = resurl.substr(resurl.length - 1, 1)
          // eslint-disable-next-line eqeqeq
          if (last == '&') {
            resurl = res.data.url + query
          } else {
            resurl = res.data.url + '&' + query
          }
        }
        this.$router.push({ name: 'Pay2', query: { url: encodeURIComponent(resurl), blank } })
      })
    },
    getLang () {
      get('/common/v1.system/getLang').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data) {
          this.lang = res.data
        }
      })
    }
  },
  computed: {
    payMoney: (item) => {
      const amount = Number(item.amount)
      if (!isNumeric(amount) || amount <= 0) {
        return 0
      }
      return (
        parseFloat(item.amount) * item.exchangerRate
      ).toFixed(2)
    }
  },
  setup () {
  }
}
</script>

<style lang="scss" scoped>
.deposit{
  .warpper {
    padding:10px;
  }
  .money-item {
    padding:30px 10px;
    border-radius: var(--van-card-border-radius);
    text-align: center;
    position: relative;
    margin-bottom: 10px;
  }
  .money-amount{
    font-size:20px;
  }
  .currency{
    font-size:10px;
    color:#ddd;
  }
  .actual-pay{
    text-align: right;
    margin: 10px 0;
    font-size: 14px;
  }
  .background{
    background: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .nopayment{
    padding: 10px;
    color:#D91A6E;
  }
  .icon-group{
    margin:10px 0;
    padding:10px;
    background-color: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .icon-item{
    margin:5px 0 5px 0;
    width: 25%;
  }
  .col-item{
    text-align: center;
  }
  .icon-name{
    font-size:10px;
    color:#fff;
    margin-top:4px;
  }
  .icon{
    width:20px;
    height:20px;
  }
  .icon-box {
    width: 45px;
    height: 45px;
    border-radius: var(--van-card-border-radius);
  }
  .submit-btn{
    margin-top:20px;
  }
  .selectImage{
    position: absolute;
    top:0;
    right:0;
  }
}
</style>
