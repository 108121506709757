<template>
  <div class="my-team">
    <van-sticky>
      <van-nav-bar title="我的团队" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="push()">直推数据</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class='wrapper'>
      <div class="my-team-item">
        <van-row justify="space-between">
          <van-col span="12" class="my-team-item-left">今日推广人数</van-col>
          <van-col span="12" class="my-team-item-right">{{teamData && teamData.now_team_num}}<span class="my-team-item-right-unit"> 人数</span></van-col>
        </van-row>
      </div>
      <div class="my-team-item">
        <van-row justify="space-between">
          <van-col span="12" class="my-team-item-left">今日消费总额</van-col>
          <van-col span="12" class="my-team-item-right">{{teamData && teamData.now_team_consume_amount}}<span class="my-team-item-right-unit"> 色币</span></van-col>
        </van-row>
      </div>
      <div class="my-team-item">
        <van-row justify="space-between">
          <van-col span="12" class="my-team-item-left">今日存币生币总额</van-col>
          <van-col span="12" class="my-team-item-right">{{teamData && teamData.now_team_financial_amount}}<span class="my-team-item-right-unit"> 色币</span></van-col>
        </van-row>
      </div>
      <div class="my-team-item">
        <van-row justify="space-between">
          <van-col span="12" class="my-team-item-left">总推广人数</van-col>
          <van-col span="12" class="my-team-item-right">{{teamData && teamData.team_num}}<span class="my-team-item-right-unit"> 人数</span></van-col>
        </van-row>
      </div>
      <div class="my-team-item">
        <van-row justify="space-between">
          <van-col span="12" class="my-team-item-left">总消费总额</van-col>
          <van-col span="12" class="my-team-item-right">{{teamData && teamData.team_consume_amount}}<span class="my-team-item-right-unit"> 色币</span></van-col>
        </van-row>
      </div>
      <div class="my-team-item">
        <van-row justify="space-between">
          <van-col span="12" class="my-team-item-left">总存币生币总额</van-col>
          <van-col span="12" class="my-team-item-right">{{teamData && teamData.team_financial_amount}}<span class="my-team-item-right-unit"> 色币</span></van-col>
        </van-row>
      </div>
      <CommonTitle title="层级信息（单位；人数）" subtitle="" />
      <div v-if="teamData.team_user_num">
        <div>
          <van-row justify="space-between">
            <van-col
              span="5"
              v-for="(index) in 4"
              :key="index"
              class="level"
              :style="{background: '#1F232B'}"
            >
              <div class="level-text">{{levelArr[index-1]}}</div>
              <div class="level-amount" :style="{color:'#F0B90B'}">{{teamData.team_user_num[index] || 0}}</div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'

export default {
  name: 'MyTeam',
  props: {
  },
  components: {
    CommonTitle
  },
  data () {
    return {
      teamData: {},
      levelArr: [
        '一级',
        '二级',
        '三级',
        '四级'
      ]
    }
  },
  created () {
    this.getTeamData()
  },
  methods: {
    getTeamData () {
      post('/common/v1.user/getUserTeam').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.teamData = res.data
      })
    },
    push () {
      this.$router.push({ name: 'MyTeamDetail' })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.my-team{
  .wrapper{
    padding: 10px;
  }
  &-item{
    line-height: 50px;
    font-size: 14px;
  }
  &-item-left{
    text-align: left;
    color: #868A93;
  }
  &-item-right{
    text-align: right;
  }
  &-item-right-unit{
    color: #868A93;
  }
  .level{
    border-radius: var(--van-card-border-radius);
    text-align: center;
    position: relative;
  }
  .level-amount {
    margin-bottom: 30px;
    font-size:18px;
  }
  .level-text{
    padding:30px 10px 10px 10px;
    font-size:14px;
    text-align: center;
  }
}
</style>
