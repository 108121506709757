<template>
  <div class="pay">
    <van-nav-bar
      title="充值"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="iframe-wrapper">
      <iframe
        :src="'/common'+arg.web_view_source"
        ref="mapFrame"
        width='100%'
        height='1000'
        frameborder='0'
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  created () {
  },
  mounted () {
    const data = {
      arg: this.arg,
      url: this.url
    }
    const mapFrame = this.$refs.mapFrame
    if (mapFrame.attachEvent) {
      mapFrame.attachEvent('onload', function () {
        const iframeWin = mapFrame.contentWindow
        iframeWin.postMessage(JSON.stringify(data), '*')
      })
    } else {
      mapFrame.onload = () => {
        const iframeWin = mapFrame.contentWindow
        iframeWin.postMessage(JSON.stringify(data), '*')
      }
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-2)
    }
  },
  data () {
    return {
      iframeWin: null,
      url: this.$route.params.url,
      arg: JSON.parse(this.$route.params.arg)
    }
  },
  setup () {
  }
}
</script>
<style lang="scss" scoped>
.pay{
  body {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    overflow: hidden !important;
  }
  .iframe-wrapper{
    width: 100% !important;
    height: 100% !important;
  }
}

</style>
