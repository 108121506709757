<template>
  <div class="ofo">
    <div class="section">
      <div class="ofo-wrapper">
        <van-image v-if="margin>0" width="40" height="40" :src="require('@/assets/icon/margin.png')" class="margin"/>
        <van-row @click="push">
          <van-col span="14" class="left-item">
            <div class="ofo-name">{{name}}</div>
            <div class="ofo-type" v-if="!editable"><div class="ofo_lbl">类型：</div>{{type}}</div>
            <div><div class="ofo_lbl">服务地区：</div>{{city}}</div>
            <div class="text-line" v-if="!editable"><div class="ofo_lbl">服务项目：</div>{{services}}</div>
            <div><div class="ofo_lbl">妹子颜值：</div><van-rate v-model="qualityData" readonly allow-half /></div>
            <div v-if="!editable"><div class="ofo_lbl">消费情况：</div>{{monetary}}</div>
            <div v-if="editable">
              <div class="ofo_lbl">状态：</div>
              <span v-if="status==1">已通过</span>
              <span v-if="status==0">审核中</span>
              <span v-if="status==2">已下架</span>
              <span v-if="status==3">审核不通过</span>
            </div>
            <div v-if="editable && status==3">
              <div class="ofo_lbl">原因：</div>
              <span>{{reason}}</span>
            </div>
          </van-col>
          <van-col span="10" class="right-item">
            <div class="right-image" v-if="avatar && avatar.length>0">
              <van-image width="100px" height="100px" :src="imageUrl+avatar" class="avatar" lazy-load  v-if="!editable">
                <template v-slot:loading>
                  <div>色影</div>
                </template>
              </van-image>
              <van-image width="60px" height="60px" :src="imageUrl+avatar" class="avatar" lazy-load  v-if="editable">
                <template v-slot:loading>
                  <div>色影</div>
                </template>
              </van-image>
            </div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="footer-left-item" @click="push">{{created_at_str}}发布</van-col>
          <van-col span="12" class="footer-right-item">
            <van-row v-if="!editable" @click="push" style="line-height:20px;">
              <van-col span="8"><van-icon name="eye-o" /> {{look_ofo}}</van-col>
              <van-col span="8"><van-icon name="good-job-o" /> {{ofo_up}}</van-col>
              <van-col span="8"><van-icon name="like-o" /> {{ofo_store}}</van-col>
            </van-row>
            <van-row v-if="editable && status!=2">
              <van-col span="8"><van-button type="primary" :disabled="status!=1" size="mini" @click="onEdit(ofo_id)">编辑</van-button></van-col>
              <van-col span="8"><van-button type="primary" :disabled="status!=1 || margin>0" color="#F0B90B" size="mini" @click="onMargin(ofo_id)">缴纳</van-button></van-col>
              <van-col span="8"><van-button type="default" :disabled="status!=1" size="mini" plain @click="onPullOff(ofo_id)">下架</van-button></van-col>
            </van-row>
          </van-col>
        </van-row>
        <font-awesome-icon :icon="['fas', 'times-circle']" class="close-icon" @click="onDelete(ofo_id, $event)" v-if="isDelete" />
      </div>
    </div>
  </div>
</template>
<script>
import { imageUrl } from '@/config/index.js'
export default {
  name: 'OfoItem',
  props: {
    ofo_id: { type: Number, required: true, default: null },
    avatar: { type: String, required: true, default: null },
    name: { type: String, required: true, default: null },
    type: { type: String, required: true, default: null },
    city: { type: String, required: true, default: null },
    services: { type: String, required: true, default: null },
    quality: { type: Number, required: true, default: null },
    monetary: { type: String, required: true, default: null },
    created_at_str: { type: String, required: true, default: null },
    look_ofo: { type: Number, required: true, default: null },
    ofo_up: { type: Number, required: true, default: null },
    ofo_store: { type: Number, required: true, default: null },
    editable: { type: Boolean, default: false },
    reason: { type: String },
    status: { type: Number },
    margin: { type: String },
    onEdit: { type: Function },
    onMargin: { type: Function },
    onPullOff: { type: Function },
    isDelete: { type: Boolean, default: false },
    onDelete: { type: Function }
  },
  components: {

  },
  computed: {
  },
  data () {
    return {
      qualityData: parseFloat(this.quality),
      imageUrl
    }
  },
  methods: {
    push () {
      if (this.status !== 1) return
      this.$router.push({ name: 'OfoDetail', params: { id: this.ofo_id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.ofo{
  position: relative;
  width: 100%;
  .section{
    width: 100%;
    margin-bottom: 10px;
    padding:0;
  }
  .close-icon{
    position:absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
     color: rgba(0,0,0,0.5);
  }
  .ofo-wrapper{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .ofo-name{
    font-size: 16px;
    margin-bottom: 10px;
  }
  .ofo_lbl{
    color:#868A93 ;
    display: inline-block;
  }
  .left-item{
    text-align: left;
    padding:10px;
  }
  .right-item{
    display: flex;
  }
  .right-image{
    align-self: center;
    margin: 0 auto;
  }
  .avatar{
    border-radius: 50px;
    overflow: hidden;
  }
  .text-line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .footer-left-item{
    line-height: 25px;
  }
  .footer-left-item, .footer-right-item{
    padding: 0 10px 10px 10px;
    color: #dfe0e1 ;
  }
  .footer-right-item{
    margin-top: 2px;
    text-align: right;
  }
  .margin{
    position: absolute;
    top: 5px;
    right: 26px;
    z-index: 100;
  }
}
</style>
