<template>
  <div class="earn-task">
    <van-sticky>
      <van-nav-bar title="赚钱任务" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <div v-for="item in list" :key="item.id" class="img" @click="$router.push({ name: 'EarnTaskDetail', params: { id: item.id } })">
          <van-image width="100%" height="120px" :src="imageUrl+item.img" />
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
import { imageUrl } from '@/config/index.js'
export default {
  name: 'EarnTask',
  props: {
  },
  components: {

  },
  data () {
    return {
      list: [],
      loading: false,
      refreshing: false,
      imageUrl
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.system/earnTaskList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        this.list = res.data
      })
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.getList()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.earn-task {
  .wrapper {
    padding: 10px;
  }
  .img {
    height: 120px;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: var(--van-card-border-radius);
  }
}
</style>
