<template>
  <div class="message-detail">
    <van-sticky>
      <van-nav-bar title="消息详情" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <free-style-shimmer :is-loading="loading2" height="120px" width="100%" border-radius="6px" />
      <van-pull-refresh v-model="loading" @refresh="onRefresh" v-if="!loading2">
        <div class="background card">
          <div class="title">{{type=="mine"?"我的消息":data.title}}</div>
          <div class="time">发布时间：{{data.created_at}}</div>
          <div class="content" v-html="data.content"></div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
export default {
  name: 'NoticeDetail',
  props: {
    type: { type: String },
    id: { type: Number }
  },
  components: {

  },
  data () {
    return {
      data: [],
      loading: false,
      refreshing: false,
      loading2: true
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      const url =
      // eslint-disable-next-line eqeqeq
      this.type == 'mine'
        ? '/common/v1.letter/detail'
        : '/common/v1.letter/activityDetail'
      get(url, { letter_id: this.id }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading2 = false
        this.loading = false
        this.refreshing = false
        this.data = res.data
      })
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.getList()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.message-detail {
  .wrapper {
    padding: 10px;
  }
  .card{
    padding:10px;
  }
  .background{
    background: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .title{
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
  .time{
    margin-top:10px;
    font-size: 12px;
    color:#868A93;
    line-height: 20px;
  }
  .content{
    margin-top: 20px;
    font-size: 14px;
    line-height: 25px;
  }
}
</style>
