<template>
  <div class="user-labels">
    <van-sticky>
      <van-nav-bar title="选择标签" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="submit" style="color:#D91A6E">保存</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class='wrapper'>
      <van-button
        round
        type="primary"
        size="small"
        :plain="selected.indexOf(item.label_id)>-1?false:true"
        class="label"
        v-for="item in labels"
        :key="item.label_id"
        @click="onSelect(item.label_id)"
      >
        {{item.label_name}}
      </van-button>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request'
import { Toast } from 'vant'
export default {
  name: 'UserLabels',
  props: {
    keyword: { type: Number }
  },
  components: {
  },
  data () {
    return {
      labels: {},
      userLabels: {},
      selected: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    onSelect (id) {
      if (this.selected.indexOf(id) > -1) {
        this.selected.splice(this.selected.indexOf(id), 1)
      } else {
        this.selected.push(id)
      }
    },
    getData () {
      post('/common/v1.user/label', { sex: this.keyword }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        const labels = res.data
        post('/common/v1.user/userLabel').then(res => {
          // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            return
          }
          this.labels = labels
          this.userLabels = res.data
          for (let index = 0; index < this.userLabels.length; index++) {
            const element = this.userLabels[index]
            this.selected.push(element.label_id)
          }
        })
      })
    },
    submit () {
      let labelStr = ''
      const labels = this.selected
      if (labels) {
        for (let i = 0; i < labels.length; i++) {
          const element = labels[i]
          if (i === labels.length - 1) {
            labelStr = labelStr + element
          } else {
            labelStr = labelStr + element + ','
          }
        }
      }
      if (labelStr === '') {
        Toast.fail('请至少选择一个')
        return
      }
      const toastkey = Toast.loading('提交中')
      const data = {
        label: labelStr
      }
      post('/common/v1.user/updateUserLabel',
        data
      ).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success({
          message: res.msg,
          onClose: () => {
            this.$router.back()
          }
        })
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.user-labels{
  .wrapper{
    padding: 10px;
  }
  .label{
    margin:5px;
  }
}
</style>
