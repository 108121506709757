<template>
  <div class="message-center">
    <van-sticky>
      <van-nav-bar title="消息中心" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <div v-for="item in list" :key="item.id" class="card" @click="jumpPage(item)">
          <van-row class="card-row">
            <van-col span="4" class="left-icon">
              <div class="icon" :style="{background:item.icon_bg_color}" >
                <font-awesome-icon :icon="['fas', item.fa_icon]" color="#fff" style="width:20px;height:20px;" />
              </div>
            </van-col>
            <van-col span="16" class="message-name">
              <div class="title">{{item.name}}</div>
              <div class="subtitle van-ellipsis">{{item.sub_title}}</div>
            </van-col>
            <van-col span="4" class="right">
              {{item.datetime}}
            </van-col>
          </van-row>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
export default {
  name: 'EarnTask',
  props: {
  },
  components: {

  },
  data () {
    return {
      list: [],
      loading: false,
      refreshing: false
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.letter/type').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        this.list = res.data
      })
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    jumpPage (obj) {
      if (obj.scene_key === 'MESSAGEOFSYSTEM') {
        this.$router.push({ name: 'MessageSystem' })
      } else if (obj.scene_key === 'MESSAGEOFMINE') {
        this.$router.push({ name: 'MessageMine' })
      } else if (obj.scene_key === 'MESSAGEOFACTIVITY') {
        this.$router.push({ name: 'MessageActivity' })
      } else if (obj.scene_key === 'FAQ') {
        this.$router.push({ name: 'Faq' })
      } else if (obj.scene_key === 'CONTACTUS') {
        this.$router.push({ name: 'CustomerService' })
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.message-center {
  .wrapper {
    padding: 10px;
  }
  .card{
    width: 100%;
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
    margin-bottom: 10px;
  }
  .card-row{
    padding: 0 10px;
    height: 80px;
  }
  .icon{
    width:20px;
    height:20px;
    padding:10px;
    border-radius: var(--van-card-border-radius);
  }
  .left-icon{
    align-self: center;
  }
  .message-name{
    align-self: center;
  }
  .right{
    align-self: center;
    text-align: right;
    color:#868A93;
  }
  .title{
    font-size: 14px;
  }
  .subtitle{
    margin-top: 5px;
    font-size:12px;
    color:#868A93;
  }
}
</style>
