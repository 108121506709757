<template>
  <van-config-provider :theme-vars="themeVars">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path"></component>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.path"></component>
    </router-view>
    <van-tabbar :border=false active-color="#D91A6E" route  v-if="(this.$route.name=='Home' || this.$route.name=='VideoIndex' || this.$route.name=='Ofo' || this.$route.name=='Club' || this.$route.name=='My')">
      <van-tabbar-item to='/'>
        <template #icon>
          <font-awesome-icon :icon="['fas', 'home']" class="footer-nav-icon" />
        </template>
        <div class="navbar-text">首页</div>
      </van-tabbar-item>
      <van-tabbar-item to='/video'>
        <template #icon>
          <font-awesome-icon :icon="['fas', 'video']" class="footer-nav-icon" />
        </template>
        <div class="navbar-text">视频</div>
        </van-tabbar-item>
      <van-tabbar-item to='/ofo'>
        <template #icon>
          <font-awesome-icon :icon="['fas', 'user-friends']" class="footer-nav-icon" />
        </template>
        <div class="navbar-text">楼凤</div>
      </van-tabbar-item>
      <van-tabbar-item to='/club'>
        <template #icon>
          <font-awesome-icon :icon="['fas', 'hotel']" class="footer-nav-icon" />
        </template>
        <div class="navbar-text">会所</div>
      </van-tabbar-item>
      <van-tabbar-item to='/my'>
        <template #icon>
          <font-awesome-icon :icon="['fas', 'user-alt']" class="footer-nav-icon" />
        </template>
        <div class="navbar-text">我的</div>
      </van-tabbar-item>
    </van-tabbar>
  </van-config-provider>
</template>
<script>
import { black } from '@/config/theme.js'
import VConsole from 'vconsole'
export default {
  created () {
    if (process.env.NODE_ENV === 'development') this.vConsole = new VConsole()
  },
  unmounted () {
    if (process.env.NODE_ENV === 'development') this.vConsole.destroy()
  },
  name: 'App',
  components: {
  },
  data () {
    return {
      includeList: [
        'Home',
        'VideoIndex',
        'VideoDetail',
        'OfoIndex',
        'ClubIndex'
      ],
      isRouterAlive: true,
      themeVars: black
    }
  },
  methods: {
  },
  watch: {
    $route (to) {
      const that = this
      if (to.meta.keepAlive && that.includeList.indexOf(to.name) === -1) {
        that.includeList.push(to.name)
      }
    }
  }

}
</script>
<style lang="scss">
body{
  margin:0;
  padding:0;
  background-color: #161A22;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.van-safe-area-bottom {
    padding-bottom: 14px !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-tab, .van-nav-bar__title{
  font-size:14px !important;
}
.van-action-bar{
  max-width:414px;
  width:100%;
  left: unset !important;
  right: unset !important;
}
.van-tabbar--fixed{
  transform: translateZ(0) !important;
  -webkit-transform: translateZ(0) !important;
  max-width:414px;
  width:100%;
  left: unset !important;
}
.footer-nav-icon{
  width:16px;
  height:16px;
}
.navbar-text{
  margin-top: 2px;
  margin-bottom: 5px;
}
.van-tabbar{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  overflow: hidden;
  height: 60px !important;
}
</style>
