<template>
  <div class="setting">
    <van-sticky>
      <van-nav-bar
        left-arrow
        :border=false
        @click-left="onClickLeft"
      />
    </van-sticky>
    <div class="section">
      <p class="lbl_title">设置</p>
      <div>
        <van-cell-group inset>
          <van-cell title="支付密码" is-link to='/paypassword' />
          <van-cell title="手机号码" :value="phone" v-if="phone!=''" />
          <van-cell title="绑定手机"  is-link to='/bindphone' v-if="phone==''" />
          <van-cell title="获取游客凭证"  is-link to='/guesttoken' v-if="phone==''" />
          <van-cell title="语言" value="简体中文" />
          <van-cell title="版本号" value="1.0.0" />
        </van-cell-group>
        <van-button type="warning" v-if="true" block round @click="logout()" style="margin-top:40px">切换账号</van-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Setting',
  components: {},
  data () {
    return {
      phone: null
    }
  },
  setup () {
    const onClickLeft = () => history.back()
    return {
      onClickLeft
    }
  },
  methods: {
    jumpPage (name) {
      this.$router.push({ name })
      return null
    },
    logout () {
      this.$store.dispatch('logout')
      setTimeout(() => {
        this.$router.push({ name: 'Welcome' })
      }, 200)
    },
    getUserInfo () {
      const userInfo = this.$store.getters.userInfo || {}
      this.phone = userInfo && userInfo.phone
    }
  },
  created () {
    this.getUserInfo()
  }
}
</script>

<style lang="scss">
.setting{
  .section{
    padding:20px;
  }
  .lbl_title{
    font-size: 20px;
    color:white;
    margin-bottom: 10px;
  }
  .btn-logout{
    margin-top: 40px;
  }
}
</style>
