import { createApp } from 'vue'
import Vant, { Lazyload } from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Shimmer from 'vue3-shimmer'
import 'lib-flexible'

import '../public/css/reset.css'
import 'vant/lib/index.css'

library.add(fas)

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(Vant)
  .use(Lazyload)
  .use(Shimmer)
  .use(store)
  .use(router)
  .mount('#app')
