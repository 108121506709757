import CryptoJS from 'crypto-js'
import md5 from 'js-md5'

export function createSign () {
  const time = Date.parse(new Date())
  let passwordData = 'time=' + time + '&apptype=web&bid=' + Math.random()
  const vkey = 'Yx99HFvK2g#gn8SN'
  const key = CryptoJS.enc.Utf8.parse(vkey)
  const iv = md5(vkey).substring(8, 24)
  const ivv = CryptoJS.enc.Utf8.parse(iv)
  const encrypted = CryptoJS.AES.encrypt(passwordData, key, {
    iv: ivv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  })
  passwordData = encrypted.toString()
  return passwordData
}
