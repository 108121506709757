<template>
  <div class="start">
    <div class='bg'>
      <div class="section">
        <van-loading size="50px" color="#ff375f" v-if="!isUpgrate" vertical>检查更新中...</van-loading>
        <div v-if="isUpgrate" class="version-info">
          <van-image class="logo" width="60px" height="60px" :src="require('@/assets/image/logo.png')" lazy-load>
            <template v-slot:loading>
              <div>色影</div>
            </template>
          </van-image>
          <div class="update-log">{{ versionData.updateLog }}</div>
          <van-button type="primary" @click="upgrate()">前往升级</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../utils/request'
import { Toast } from 'vant'
import { isAndroid, getVersionInfo } from '@/utils/common'

export default {
  name: 'Start',
  components: {
  },
  data () {
    return {
      isUpgrate: false,
      versionData: null,
      downloadUrl: null
    }
  },
  setup () {

  },
  methods: {
    upgrate () {
      if (isAndroid() && window.AndroidInterface) {
        this.versionData.downloadUrl && window.AndroidInterface.openBrowser(this.versionData.downloadUrl)
      } else {
        window.open(this.versionData.downloadUrl)
      }
    },
    checkUpdate () {
      post('/common/v1.system/checkUpdate').then(res => {
        if (res.code !== 0) {
          Toast.fail(res.msg)
          return
        }
        const version = getVersionInfo()
        console.log('android verison==', version)
        console.log('api verison==', res.data.version)
        if (version !== 0 && res.data.version > version) {
          this.isUpgrate = true
          this.versionData = res.data
        } else {
          this.$router.replace({ name: 'Welcome' })
        }
      })
    }
  },
  created () {
    // 检查Android版本是否更新
    if (isAndroid()) {
      this.checkUpdate()
      return
    }
    this.$router.replace({ name: 'Welcome' })
  }
}
</script>

<style scoped lang="scss">
.start{
  width: 100%;
  height: 100%;
  position: relative;
}
.bg{
  background: #161A22;
  height: 100%;
  width: 100%;
  position:fixed;
}
.section{
  margin:50% auto;
}
.login-btn{
  margin-top:70px;
}
.reg-btn{
  margin-top:10px;
}
.version-info{
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  width: 100%;
}
.logo{
  align-self: center;
  margin-bottom: 50px;
}
.version-text{
  font-size: 14px;
  align-self: center;
  margin-bottom: 50px;
}
.update-log{
  margin-bottom: 50px;
  font-size: 14px;
  text-align: center;
  align-self: center;
  white-space: pre-line;
}
</style>
