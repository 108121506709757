<template>
  <div class="home">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div class="wrapper">
        <div class="swipe">
          <free-style-shimmer :is-loading="loading" height="3.2rem" width="100%" border-radius="6px" />
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="!loading">
            <van-swipe-item v-for="item in banner" :key="item.id" @click="showPage(item)">
              <van-image width="100%" height="3.2rem" :src="imageUrl+item.img" lazy-load >
                <template v-slot:loading>
                  <div>色影</div>
                </template>
              </van-image>
            </van-swipe-item>
          </van-swipe>
        </div>
        <free-style-shimmer :is-loading="loading" height="30px" width="100%" border-radius="6px" />
        <van-notice-bar left-icon="volume-o"  v-if="!loading" :scrollable="false" class="notice" background="#1F232B" color="#fff">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <div v-for="(item) in notice" :key="item.id" @click="$router.push({ name: 'NoticeDetail', params: {id: item.id} })">
              <van-swipe-item class="notice-swipe-title">{{item.title}}</van-swipe-item>
            </div>
          </van-swipe>
        </van-notice-bar>
        <free-style-shimmer :is-loading="loading" height="85px" width="100%" border-radius="6px" class="icon-group-loading"/>
        <div class="icon-group" v-if="!loading">
          <van-row justify="space-between">
            <div v-for="(item) in feature" :key="item.id" @click="jumpPage(item)">
              <van-col class="col-item">
                <van-button class="icon-box" :color="item.bg_start">
                  <font-awesome-icon :icon="['fas', item.icon_name]" class="icon" />
                </van-button>
                <div class="icon-name">{{item.title}}</div>
              </van-col>
            </div>
          </van-row>
        </div>
        <free-style-shimmer :is-loading="loading" height="156px" width="100%" border-radius="6px"/>
        <HomeOfo :ofoList="ofo" v-if="!loading && ofo.length>0" :loading="loading" />
        <free-style-shimmer :is-loading="loading" height="500px" width="100%" border-radius="6px" class="topics-loading"/>
        <HomeTopic :topics="topics" v-if="!loading && topics.length>0" :changeVideo="changeVideo" :loading="loading" />
      </div>
    </van-pull-refresh>
  </div>
  <a ref="externalLink" :href="outUrl"  target="_blank" ></a>
</template>

<script>
import { post } from '@/utils/request'
import HomeOfo from '@/components/Home/Ofo.vue'
import HomeTopic from '@/components/Home/Topic.vue'
import { imageUrl } from '@/config/index.js'

export default {
  name: 'Recommend',
  components: {
    HomeOfo,
    HomeTopic
  },
  data () {
    return {
      isLoading: false,
      loading: true,
      code: 0,
      banner: [],
      activity: [],
      feature: [],
      notice: [],
      ofo: [],
      topics: [],
      imageUrl,
      externalLink: null,
      outUrl: ''
    }
  },
  methods: {
    index () {
      post('/common/v1.index/index2', { id: 100 }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.isLoading = false
        this.loading = false
        this.banner = res.data.banner
        this.activity = res.data.activity
        this.feature = res.data.feature
        this.notice = res.data.notice
        this.ofo = res.data.ofo
        this.topics = res.data.topics
      })
    },
    changeVideo (id, key) {
      post('/common/v1.index/changeVideo',
        { id }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        const topics = this.topics
        const videos = res.data[0].videos
        // eslint-disable-next-line eqeqeq
        if (res.data[0].banner == 1) {
          videos.unshift(topics[key].videos[0])
        }
        topics[key].videos = videos
        this.topics = topics
      })
    },
    onRefresh () {
      this.isLoading = true
      this.index()
    },
    jumpPage (obj) {
      if (obj.type === 'PAGE') {
        if (obj.value === 'INVITE_FRIENDS') {
          this.$router.push({ name: 'Invite' })
        } else if (obj.value === 'TOPICSHOT') {
          this.$router.push({ name: 'HotTopics' })
        } else if (obj.value === 'TOPICSDETAILS') {
          const params = JSON.parse(obj.params)
          this.$router.push({ name: 'VideoMore', params: params.topicsData })
        } else if (obj.value === 'TOPICSHOT') {
          this.$router.push({ name: 'HotTopics' })
        } else if (obj.value === 'SIGNIN') {
          this.$router.push({ name: 'SignIn' })
        }
      } else if (obj.type === 'TAB') {
        if (obj.value === '2') {
          this.$router.push({ name: 'Ofo' })
        }
      }
    },
    showPage (obj) {
      if (obj.show_type === 'doc') {
        const params = obj.url.split('=')
        this.$router.push({ name: 'NoticeDetail', params: { id: params[1] } })
      } else if (obj.show_type === 'component') {
        if (obj.content === 'OFO') {
          this.$router.push({ name: 'Ofo' })
        } else if (obj.content === 'TOPICSDETAILS') {
          const params = JSON.parse(obj.url)
          this.$router.push({ name: 'VideoMore', params: params.topicsData })
        } else if (obj.content === 'PAY') {
          const params = JSON.parse(obj.url)
          this.moniClick(params.url)
        }
      }
    },
    moniClick (url) {
      this.outUrl = url
      setTimeout(() => { this.$refs.externalLink.click() }, 200)
    }
  },
  created () {
    this.index()
    // this.getPopup()
  },
  mounted () {
  },
  setup () {
  },
  watch: {
    $route (to, from) {
      // eslint-disable-next-line eqeqeq
      if (to.path != from.path && (from.name == 'Login' || from.name == 'Register') && to.name == 'Home') {
        this.index()
      }
      return null
    }
  }
}
</script>
<style lang="scss">
.home{
  .wrapper{
    padding:10px;
    margin-bottom:50px;
  }
  .notice{
    border-radius: var(--van-card-border-radius);
    height: 30px;
  }
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
  .notice-swipe-title {
    font-size: 12px;
  }
  .icon-group-loading{
    margin:10px 0;
  }
  .icon-group{
    margin:10px 0;
    padding:10px;
    background-color: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .col-item{
    flex: 1;
    text-align: center;
  }
  .icon-name{
    font-size:12px;
    color:#fff;
    margin-top:4px;
  }
  .icon{
    width:18px;
    height:18px;
  }
  .icon2{
    width:13px;
    height:15px;
  }
  .icon-box {
    width: 45px;
    height: 45px;
    border-radius: var(--van-card-border-radius);
  }
  .banner-image{
    border-radius: var(--van-card-border-radius);
  }
  .swipe{
    padding:0 0 10px 0;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 120px;
    text-align: center;
    background-color: #39a9ed;
    overflow: hidden;
    border-radius: var(--van-card-border-radius);
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    color:#fff;
  }
  .topics-loading{
    margin-top:10px;
  }
}
</style>
