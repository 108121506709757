<template>
  <div class="title">
    <img class="title-img" :src="require('@/assets/icon/hot.png')">
    <span class="title-hotrec">{{title}}</span>
    <span class="title-allrec" @click="onSubtitle" :style="subtitleStyle">{{subtitle}}</span>
  </div>
</template>

<script>
export default {
  name: 'CommonTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    subtitleStyle: {
      type: String
    },
    onSubtitle: {
      type: Function
    }
  },
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  display:flex;
  .title-img {
    width: 15px;
    height: 18px;
    align-self:center;
  }
  .title-hotrec {
    font-size: 16px;
    margin-left: 4px;
    color:#fff;
    line-height: 40px;
  }
  .title-allrec {
    position: absolute;
    font-size: 12px;
    right: 0;
    color: #fff;
    line-height: 40px;
  }
}
</style>
