<template>
  <div class="ofo-add">
    <van-sticky>
      <van-nav-bar title="上传体验报告" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="submit" style="color:#D91A6E">提交</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-form @submit="onSubmit">
        <CommonTitle title="基本信息" />
        <van-cell-group inset>
          <van-field
            v-model="girlName"
            label="妹子花名"
            name="妹子花名"
            placeholder="妹子花名"
            clearable
          />
          <van-field
            v-model="expTime"
            is-link
            readonly
            name="体验时间"
            label="体验时间"
            placeholder="点击选择日期"
            @click="showCalendar = true"
          />
          <van-calendar v-model:show="showCalendar" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />
          <van-field
            v-model="city"
            is-link
            readonly
            name="city"
            label="所在城市"
            placeholder="点击选择城市"
            @click="$router.push({name:'CityPicker'})"
          />
        </van-cell-group>
        <CommonTitle title="妹子信息" />
        <van-cell-group inset>
          <van-field
            v-model="height"
            label="身高身材"
            name="身高身材"
            placeholder="170 高挑长腿"
            clearable
          />
          <van-field
            v-model="quality"
            label="颜值水平"
            name="颜值水平"
            placeholder="80分，风骚会玩"
            clearable
          />
          <van-field
            v-model="bar"
            label="胸器罩杯"
            name="胸器罩杯"
            placeholder="36D，巨乳"
            clearable
          />
        </van-cell-group>
        <CommonTitle title="相关描述" />
        <van-cell-group inset>
          <van-field
            v-model="serviceDesc"
            rows="5"
            autosize
            label="详细描述"
            type="textarea"
            maxlength="120"
            placeholder="请阐述真实过程，字数不得少于30字。字数越多，图片质量越好，管理员审核后所得到的奖励越丰厚。"
            show-word-limit
            clearable
          />
        </van-cell-group>
        <CommonTitle title="联系方式" />
        <van-cell-group inset>
          <van-field name="contact_type" label="联系类型">
            <template #input>
              <van-radio-group v-model="contact_type" direction="horizontal" @change="changeContactType">
                <van-radio name="0">{{contactArr[0]}}</van-radio>
                <van-radio name="1">{{contactArr[1]}}</van-radio>
                <van-radio name="2">{{contactArr[2]}}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="contact"
            label="联系方式"
            name="联系方式"
            clearable
            :placeholder="contact_placeholder"
          />
          <van-field
            v-model="location"
            label="详细地址"
            name="详细地址"
            clearable
            placeholder="什么区，什么地铁附近，几号房等等"
          />
        </van-cell-group>
        <CommonTitle title="体验评分" />
        <van-cell-group inset>
          <van-field name="qualityScore" label="妹子颜值">
            <template #input>
              <van-rate v-model="qualityScore" allow-half />
            </template>
          </van-field>
          <van-field name="serviceScore" label="服务质量">
            <template #input>
              <van-rate v-model="serviceScore" allow-half />
            </template>
          </van-field>
          <van-field name="ambientScore" label="环境设备">
            <template #input>
              <van-rate v-model="ambientScore" allow-half />
            </template>
          </van-field>
        </van-cell-group>
        <CommonTitle title="真实照片信息" />
        <div><span style="color:#F0B90B">务必按要求上传图片（也可以不上传）</span>，审核通过后图片信息将公开，请自行打码，如果上传图片一直加载中，请在网络顺畅的时候操作。</div>
        <div style="margin:10px 0;">
          <van-row>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="aroundImage" :after-read="addAround" :before-delete="delAround" />
              <div>周围环境</div>
              <div>(场所附近建筑或门面照)</div>
            </van-col>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="innerImage" :after-read="addInner" :before-delete="delInner"  />
              <div>室内环境</div>
              <div>(隐私自行打码)</div>
            </van-col>
          </van-row>
        </div>
        <div style="margin:10px 0;">
          <van-row>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="girlImage" :after-read="addGirl" :before-delete="delGirl" />
              <div>妹子图片</div>
              <div>(可以遮挡脸部)</div>
            </van-col>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="selfImage" :after-read="addSelf" :before-delete="delSelf" />
              <div>支付凭证截图</div>
              <div>(自行涂抹隐私信息)</div>
            </van-col>
          </van-row>
        </div>
        <div style="margin: 16px 0;">
          <van-button round block type="primary" @click="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { post } from '@/utils/request'
import { Toast } from 'vant'
import CommonTitle from '@/components/Common/Title.vue'
import { imageUrl } from '@/config/index.js'
export default {
  name: 'AddExp',
  props: {
    ofo_id: { type: Number, default: 0 }
  },
  components: {
    CommonTitle
  },
  data () {
    return {
      girlName: '',
      expTime: '',
      showCalendar: false,
      city: '',
      adcode: '',
      height: '',
      quality: '',
      bar: '',
      serviceDesc: '',
      contactArr: ['微信', '手机', 'QQ'],
      contact_type: '0',
      contact: '',
      location: '',
      qualityScore: 0,
      serviceScore: 0,
      ambientScore: 0,
      around: '',
      aroundImage: [],
      inner: '',
      innerImage: [],
      girl: '',
      girlImage: [],
      self: '',
      selfImage: [],
      imageUrl
    }
  },
  computed: {
    contact_placeholder: (data) => {
      return '请输入您的' + data.contactArr[data.contact_type]
    },
    minDate: () => {
      const date = new Date()
      const min = date.getTime() - 365 * 24 * 3600 * 1000
      return new Date(min)
    },
    maxDate: () => {
      const date = new Date()
      const max = date.getTime()
      return new Date(max)
    }
  },
  methods: {
    changeContactType () {
      this.contact = ''
    },
    onConfirm (date) {
      this.expTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.showCalendar = false
    },
    addAround (file) {
      this.uploadImg(file, 'around')
    },
    delAround () {
      this.aroundImage = []
    },
    addInner (file) {
      this.uploadImg(file, 'inner')
    },
    delInner () {
      this.innerImage = []
    },
    addGirl (file) {
      this.uploadImg(file, 'girl')
    },
    delGirl () {
      this.girlImage = []
    },
    addSelf (file) {
      this.uploadImg(file, 'self')
    },
    delSelf () {
      this.selfImage = []
    },
    uploadImg (file, type) {
      file.status = 'uploading'
      file.message = '上传中...'
      post('/common/v1.user/uploadImge', {
        imageType: type,
        base64_image: file.content
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          file.status = 'failed'
          file.message = res.msg
          return
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'around') {
          this.aroundImage = []
          this.aroundImage.push({ url: imageUrl + res.data.imgUrl })
          this.around = res.data.imgUrl
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'inner') {
          this.innerImage = []
          this.innerImage.push({ url: imageUrl + res.data.imgUrl })
          this.inner = res.data.imgUrl
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'girl') {
          this.girlImage = []
          this.girlImage.push({ url: imageUrl + res.data.imgUrl })
          this.girl = res.data.imgUrl
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'self') {
          this.selfImage = []
          this.selfImage.push({ url: imageUrl + res.data.imgUrl })
          this.self = res.data.imgUrl
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('上传失败')
        // eslint-disable-next-line eqeqeq
        if (type == 'around') {
          this.aroundImage = []
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'inner') {
          this.innerImage = []
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'girl') {
          this.girlImage = []
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'self') {
          this.selfImage = []
        }
      })
    },
    submit () {
      // eslint-disable-next-line eqeqeq
      if (this.girlName == '') {
        Toast.fail('请输入妹子花名')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.expTime == '') {
        Toast.fail('请选择体验时间')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.adcode == '') {
        Toast.fail('请选择所在城市')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.location == '') {
        Toast.fail('请输入所在位置')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.height == '') {
        Toast.fail('请输入身高身材')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.quality == '') {
        Toast.fail('请输入颜值水平')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.bar == '') {
        Toast.fail('请输入胸器罩杯')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.serviceDesc == '') {
        Toast.fail('请输入服务详细')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.contact == '') {
        Toast.fail('请输入联系方式')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.qualityScore == '') {
        Toast.fail('请给妹子颜值打分')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.serviceScore == '') {
        Toast.fail('请给服务质量打分')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.ambientScore == '') {
        Toast.fail('请给环境设备打分')
        return
      }
      const toastkey = Toast.loading('提交中', 20)
      post('/common/v1.ofo/uploadExpReport', {
        ofo_id: this.ofo_id,
        girl_name: this.girlName,
        exp_time: this.expTime,
        adcode: this.adcode,
        city: this.city,
        location: this.location,
        height: this.height,
        quality: this.quality,
        bar: this.bar,
        service_desc: this.serviceDesc,
        contact_type: this.contact_type,
        contact: this.contact,
        quality_score: this.qualityScore,
        service_score: this.serviceScore,
        ambient_score: this.ambientScore,
        image_around: this.around,
        image_inner: this.inner,
        image_girl: this.girl,
        image_pay: this.self
      }).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success('提交成功')
        setTimeout(() => {
          this.$router.push({ name: 'MyExp' })
        }, 500)
      })
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
    })
  }
}
</script>

<style lang="scss">
.ofo-add {
  .wrapper {
    padding: 10px;
  }
  .van-uploader{
    width: 130px;
    height:170px;
  }
  .van-uploader__upload{
    width: 130px;
    height:170px;
    border-radius: 15px;
    overflow: hidden;
  }
  .van-uploader__preview-image{
    width: 125px;
    height:165px;
    border-radius: 15px;
    overflow: hidden;
  }
  .van-uploader__preview-delete{
    width: 25px;
    height:25px;
    border-top-right-radius: 15px;
  }
  .van-uploader__preview-delete-icon{
    font-size: 25px;
  }
  .van-calendar__month, .van-calendar__header{
    color: #000;
  }
}
</style>
