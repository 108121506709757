<template>
  <div class="my-index">
    <van-nav-bar :border=false>
      <template #left>
        <font-awesome-icon :icon="['fas', 'edit']" class="header-icon" @click="$router.push({name:'Profile'})" />
      </template>
      <template #right>
        <font-awesome-icon :icon="['fas', 'bell']" class="header-icon bell-icon" :style="{color:(userData.messageCount>0)?'#D91A6E':'#FFF'}" @click="$router.push({name:'MessageCenter'})" />
        <font-awesome-icon :icon="['fas', 'cog']" class="header-icon" @click="setting" />
      </template>
    </van-nav-bar>
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div class="wrapper">
        <van-row>
            <van-col span="8" style="text-align:center;">
              <free-style-shimmer
                :is-loading="loading"
                height="96px"
                width="96px"
                border-radius="48px"
              />
              <van-image
                v-if="!loading && userData.headpic"
                round
                width="96"
                height="96"
                :src="imageUrl+userData.headpic"
              />
            </van-col>
            <van-col span="16">
              <free-style-shimmer
                :is-loading="loading"
                height="96px"
                width="100%"
                border-radius="6px"
              />
              <div v-if="!loading">
                <div class="white line nickname">{{userData.nickname}}</div>
                <div class="gray line"><font-awesome-icon :icon="['fas', 'map-marker-alt']" /> {{userData.areaName?userData.areaName:'未知'}}</div>
                <div class="gray text-2line">{{userData.introduction?userData.introduction:'这家伙很懒，什么都没留下'}}</div>
              </div>
            </van-col>
        </van-row>
        <free-style-shimmer
          :is-loading="loading"
          height="38px"
          width="100%"
          border-radius="6px"
          class="margin"
        />
        <van-row justify="center" class="center white margin" v-if="!loading">
          <van-col span="8"><span class="label">粉丝</span><div class="text1 margin-top-4">{{userData.follow ? userData.follow : 0}}</div></van-col>
          <van-col span="8"><span class="label">关注</span><div class="text1 margin-top-4">{{userData.guanzhu ? userData.guanzhu : 0}}</div></van-col>
          <van-col span="8"><span class="label">被赞</span><div class="text1 margin-top-4">{{userData.thumbs_up ? userData.thumbs_up : 0}}</div></van-col>
        </van-row>
        <div v-if="!loading">
          <div
            class="guest-tips background margin"
            v-if="(!userData.phone)"
            @click="$router.push({name: 'Setting'})"
          >注：请尽快绑定手机或保存游客凭证，以防账号丢失</div>
        </div>
        <free-style-shimmer
          :is-loading="loading"
          height="70px"
          width="100%"
          border-radius="6px"
          class="margin"
        />
        <van-row
          v-if="!loading"
          justify="center"
          class="vip-card center background margin"
          :style="{background:(userData.vip_level==0)?'#1F232B':'#D91A6E'}"
        >
          <van-col span="18">
            <van-row>
              <van-col span="4" class="vip-left">
                <font-awesome-icon :icon="['fas', 'portrait']" class="vip-icon" v-if="(userData.vip_level && userData.vip_level==0)" />
                <font-awesome-icon :icon="['fas', 'crown']" class="vip-icon" v-if="(userData.vip_level && userData.vip_level!=0)" />
              </van-col>
              <van-col span="18" class="vip-text">
                  <div class="line text1" v-if="(userData.vip_level && userData.vip_level==0)">普通会员</div>
                  <div class="line text1" v-if="(userData.vip_level && userData.vip_level!=0)">VIP会员</div>
                  <div class="line" v-if="(userData.vip_level && userData.vip_level==0)">点击开通，加入VIP大家庭</div>
                  <div class="line" v-if="(userData.vip_level && userData.vip_level!=0)">到期时间：{{(userData.vip_expire_time && userData.vip_deadline_type == 3? '终身VIP': userData.vip_expire_time)}}</div>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="6" class="vip-right">
            <van-button
              v-if="!(userData.vip_level && userData.vip_level==3 && userData.vip_deadline_type == 3)"
              @click="$router.push({name: 'OpenVip'})"
              round
              color="#fff"
              size="small"
              class="vip-btn">
              立即开通
            </van-button>
          </van-col>
        </van-row>
        <free-style-shimmer
          :is-loading="loading"
          height="107px"
          width="100%"
          border-radius="6px"
          class="margin"
          @click="getAssets"
        />
        <van-row
          v-if="!loading"
          justify="center"
          class="assets center background margin"
        >
          <van-col span="18" style="align-self:center;">
            <van-row>
              <van-col span="4" class="vip-left">
                <van-image :src="require('@/assets/icon/icon_doit.png')" class="payment-icon"/>
              </van-col>
              <van-col span="18" class="vip-text">
                <div class="line">钱包余额</div>
                <div class="line text1" >{{assetsData.DOIT? parseFloat(assetsData.DOIT.totalAssets).toFixed(2) : 0}}</div>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="6">
            <van-button
              round
              type="primary"
              size="small"
              @click="$router.push({name: 'Deposit'})"
              block
              >
              &nbsp;&nbsp;充 值&nbsp;&nbsp;
            </van-button>
            <van-button
              round
              type="primary"
              block
              plain
              size="small"
              @click="withdraw"
              class="margin-top-4"
              >
              &nbsp;&nbsp;提 现&nbsp;&nbsp;
            </van-button>
          </van-col>
        </van-row>
        <van-row v-if="false" justify="center" class="assets center background margin">
          <van-col span="12">
            <div class="line label">色币钱包</div>
            <div class="line assets-amount">{{assetsData.DOIT? parseFloat(assetsData.DOIT.totalAssets).toFixed(2) : 0}}</div>
            <div class="line">
              <van-row justify="space-around">
                <van-col span="10">
                  <van-button
                    round
                    type="primary"
                    size="small"
                    @click="$router.push({name: 'Deposit'})"
                    >
                    &nbsp;&nbsp;充 值&nbsp;&nbsp;
                  </van-button>
                </van-col>
                <van-col span="10"><van-button round type="primary" plain size="small" @click="withdraw">&nbsp;&nbsp;提 现&nbsp;&nbsp;</van-button></van-col>
              </van-row>
            </div>
          </van-col>
          <van-col span="12" class="">
            <div class="line label">USDT钱包</div>
            <div class="line assets-amount">{{assetsData.USDT? parseFloat(assetsData.USDT.totalAssets).toFixed(2) : 0}}</div>
            <div class="line">
              <van-row justify="space-around">
                <van-col span="10">
                  <van-button
                    round
                    type="primary"
                    size="small"
                    style="background:#01dc92"
                    @click="$router.push({name: 'DepositUSDT'})"
                  >
                    &nbsp;&nbsp;充 币&nbsp;&nbsp;
                  </van-button>
                </van-col>
                <van-col span="10"><van-button round type="primary" plain size="small" @click="withdrawUSDT">&nbsp;&nbsp;提 币&nbsp;&nbsp;</van-button></van-col>
              </van-row>
            </div>
          </van-col>
        </van-row>
        <free-style-shimmer
          :is-loading="loading2"
          height="275px"
          width="100%"
          border-radius="6px"
          class="margin"
          @click="getFeature"
        />
        <div class="icon-group" v-if="!loading2">
          <van-row>
            <div v-for="(item) in featureData" :key="item.id" class="icon-item"  @click="jumpPage(item)">
              <van-col class="col-item">
                <van-button class="icon-box" :color="item.bg_start">
                  <font-awesome-icon :icon="['fas', item.icon_name]" class="icon" />
                </van-button>
                <div class="icon-name">{{item.title}}</div>
              </van-col>
            </div>
          </van-row>
        </div>
        <free-style-shimmer
          :is-loading="loading3"
          height="350px"
          width="100%"
          border-radius="6px"
          class="margin"
          @click="getDailyTaskList"
        />
        <div v-if="!loading3">
          <CommonTitle title="任务列表" subtitle="全部" :onSubtitle="onSubtitle" />
          <van-row class="margin padding background">
            <CommonTitle title="每日任务" subtitle="" />
            <div class="task-item padding" v-if="daysTaskData && daysTaskData.length>0">
              <div v-for="item,index in daysTaskData" :key="item.id">
                <TaskItem
                  v-if="index<5"
                  :index="index"
                  :key="item.id"
                  :icon="item.icon"
                  :icon_bg_color="item.icon_bg_color"
                  :remark="item.remark"
                  :amount="item.amount"
                  :vip_days_str="(item.vip_days_str)?item.vip_days_str:''"
                  :finish_status="item.finish_status"
                  :scene_type="item.scene_type"
                  :scene_key="item.scene_key"
                  :scene_params="item.scene_params"
                  :name="item.name"
                  />
              </div>
            </div>
          </van-row>
        </div>
        <free-style-shimmer
          :is-loading="loading4"
          height="300px"
          width="100%"
          border-radius="6px"
          class="margin"
          @click="getFirstTaskList"
        />
        <van-row class="margin padding background firstTask" v-if="!loading4">
          <CommonTitle title="破处任务" subtitle="" />
          <div class="task-item padding" v-if="firstTaskData && firstTaskData.length>0">
            <div v-for="item,index in firstTaskData" :key="item.id">
              <TaskItem
                v-if="index<5"
                :index="index"
                :icon="item.icon"
                :icon_bg_color="item.icon_bg_color"
                :remark="item.remark"
                :amount="item.amount"
                :vip_days_str="(item.vip_days_str)?item.vip_days_str:''"
                :finish_status="item.finish_status"
                :scene_type="item.scene_type"
                :scene_key="item.scene_key"
                :scene_params="item.scene_params"
                :name="item.name"
                />
            </div>
          </div>
        </van-row>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { post, get } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import TaskItem from '@/components/Common/TaskItem.vue'
import { Toast } from 'vant'
import Bus from 'vue3-bus'
import { imageUrl } from '@/config/index.js'

export default {
  name: 'Home',
  components: {
    CommonTitle,
    TaskItem
  },
  data () {
    return {
      userData: {},
      assetsData: {},
      featureData: {},
      daysTaskData: {},
      firstTaskData: {},
      isLoading: false,
      loading: true,
      loading2: true,
      loading3: true,
      loading4: true,
      imageUrl
    }
  },
  computed: {

  },
  methods: {
    onSubtitle () {
      this.$router.push({ name: 'Tasks' })
    },
    withdraw () {
      if (Object.keys(this.userData).length === 0) {
        Toast.fail('刷新重试')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.userData && this.userData.payPasswordState != 1) {
        this.$router.push({ name: 'PayPassword' })
      } else {
        this.$router.push({ name: 'Withdraw' })
      }
    },
    withdrawUSDT () {
      if (Object.keys(this.userData).length === 0) {
        Toast.fail('刷新重试')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.userData && this.userData.payPasswordState != 1) {
        this.$router.push({ name: 'PayPassword' })
      } else {
        this.$router.push({ name: 'WithdrawUSDT' })
      }
    },
    setting () {
      this.$router.push({ name: 'Setting' })
    },
    getUserInfo () {
      post('/common/v1.user/getUserInfo').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.isLoading = false
        this.userData = res.data
        this.$store.dispatch('setUserInfo', res.data)
      })
    },
    getAssets () {
      get('/asset/v1.assets/getAssets').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.isLoading = false
        this.assetsData = res.data
        this.$store.dispatch('setAssetsData', res.data)
      })
    },
    getFeature () {
      get('/common/v1.system/getFeature').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading2 = false
        this.featureData = res.data
      })
    },
    getDailyTaskList () {
      get('/common/v1.user/getDailyTaskList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading3 = false
        this.daysTaskData = res.data
      })
    },
    getFirstTaskList () {
      get('/common/v1.user/getFirstTaskList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading4 = false
        this.firstTaskData = res.data
      })
    },
    onRefresh () {
      this.isLoading = true
      this.getAssets()
      this.getUserInfo()
    },
    jumpPage (obj) {
      if (obj.type === 'PAGE') {
        if (obj.value === 'INVITE_FRIENDS') {
          this.$router.push({ name: 'Invite' })
        }
        if (obj.value === 'CONTACTUS') {
          this.$router.push({ name: 'CustomerService' })
        }
        if (obj.value === 'MYTEAM') {
          this.$router.push({ name: 'MyTeam' })
        }
        if (obj.value === 'EARNTASK') {
          this.$router.push({ name: 'EarnTask' })
        }
        if (obj.value === 'OFOOFMINE') {
          this.$router.push({ name: 'MyOfo' })
        }
        if (obj.value === 'MYFAVORITE') {
          this.$router.push({ name: 'MyFavorite' })
        }
        if (obj.value === 'FAQ') {
          this.$router.push({ name: 'Faq' })
        }
        if (obj.value === 'SIGNIN') {
          this.$router.push({ name: 'SignIn' })
        }
        if (obj.value === 'MYPHOTOS') {
          this.$router.push({ name: 'Photos' })
        }
        if (obj.value === 'CURRENCYEXCHANGE') {
          this.$router.push({ name: 'Exchange' })
        }
        if (obj.value === 'FRIENDS') {
          Toast.fail('开发中')
        }
        if (obj.value === 'MANAGEMONEY') {
          Toast.fail('该服务已暂停')
        }
      }
    }
  },
  created () {
    this.getUserInfo()
    this.getAssets()
    this.getFeature()
    this.getDailyTaskList()
    this.getFirstTaskList()
  },
  setup () {

  },
  mounted () {
    // let self = this
    Bus.on('updateAssets', () => {
      this.getAssets()
    })
  },
  watch: {
    $route (to, from) {
      if (to.path !== from.path && to.name === 'My') {
        this.getUserInfo()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.my-index{
  .wrapper{
    padding:10px;
    margin-bottom:80px;
  }
  .header-icon{
    width:18px;
    height:18px;
  }
  .bell-icon{
    margin-right:20px;
  }
  .nickname{
    font-size: 16px;
  }
  .margin-top-4{
    margin-top:4px;
  }
  .text-2line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .vip-card{
    padding: 5px;
  }
  .vip-left{
    display: flex;
    margin: 0 auto;
  }
  .vip-text{
    text-align: left;
  }
  .vip-right{
    display: flex;
    justify-content: center;
  }
  .vip-icon{
    width: 40px;
    height: 40px;
    align-self: center;
  }
  .vip-btn{
    color:#000 !important;
    align-self: center;
  }
  .white{
    color: #fff;
  }
  .gray{
    color: #868A93;
  }
  .line{
    line-height: 30px;
  }
  .text1{
    font-size:16px;
  }
  .center{
    text-align: center;
  }
  .background{
    background: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .height100{
    height: 104px;
  }
  .line100{
    line-height: 104px;
  }
  .margin{
    margin-top:10px;
  }
  .padding{
    padding:5px;
  }
  .icon-group{
    margin:10px 0;
    padding:10px;
    background-color: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .icon-item{
    flex: 0 0 20%;
    margin:10px 0 10px 0;
  }
  .col-item{
    text-align: center;
  }
  .icon-name{
    font-size:12px;
    color:#fff;
    margin-top:4px;
  }
  .icon{
    width:18px;
    height:18px;
  }
  .icon-box {
    width: 40px;
    height: 40px;
    border-radius: var(--van-card-border-radius);
  }
  .assets{
    padding: 5px;
  }
  .task-item{
    width: 100%;
  }
  .firstTask{
    margin-bottom: 0px;
  }
  .label{
    color:#868A93;
    font-size: 12px;
  }
  .assets-amount{
    font-size:18px;
  }
  .guest-tips{
    color: #D91A6E;
    padding: 10px;
  }
  .payment-icon{
    width:30px;
    height:30px;
    border-radius: var(--van-card-border-radius);
    align-self:center;
  }
}
</style>
