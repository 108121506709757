<template>
  <div>
    <van-sticky>
      <van-nav-bar title="在线客服" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div>
      <iframe ref="iframe" :src="services_url" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
  },
  methods: {
  },
  computed: {
    services_url: () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const uid = userInfo.user_id
      const kefuUrl = process.env.VUE_APP_KEFU_URL
      const url = `${kefuUrl}?uname=${uid}&uid=${uid}`
      console.log(url)
      return url
    }
  }
}
</script>

<style>
iframe {
  width: 100%;
  border: none;
  height: calc(100vh - 50px);
}
</style>
