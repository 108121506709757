<template>
  <div class="open-vip">
    <van-sticky>
      <van-nav-bar title="会员开通" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="warpper">
      <div v-if="vipConfigList[3] && vipConfigList[3].length>0">
        <div>
          <van-row justify="space-between">
            <van-col
              span="6"
              v-for="vip in vipConfigList[3]"
              :key="vip.id"
              @click="vip.enable && changeDeadLine(vip.deadline_type)"
              style="padding:2px;"
            >
              <div class="vip-item" :style="{background: vip.enable?'#1F232B':'#868A93'}">
                <van-image v-if="(vip.enable && vip.deadline_type == deadlineIndex)" width="35px" height="35px" :src="selectImage" class="selectImage" />
                <div class="vip-amount" :style="{color:vip.enable?'#F0B90B':'#ddd'}">{{vip.amount}}</div>
                <div class="currency" v-if="vip.origin<=0">色币</div>
                <div class="currency" v-if="vip.origin>0" style="text-decoration:line-through;">原价: {{vip.origin}}</div>
                <div class="dead-line">
                  {{vipDeadlineTypeList[vip.deadline_type] && vipDeadlineTypeList[vip.deadline_type].name}}
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class="warpper">
      <div class="actual-pay">
        {{amount == -1 ? '不能操作' : '实际支付：'}}
        {{this.amount == -1? '': this.amount + '(色币) / ' + this.payMoney}}
      </div>
      <div class="vip-right">
        <CommonTitle title="VIP权益" subtitle="" />
        <div class="background">
          <div class="icon-group">
            <van-row>
              <div v-for="(item, index) in vipRights[vipIndex-1]" :key="index" class="icon-item">
                <div class="col-item" v-if="item.text=='look_ofo_text' && vipIndex==3 && deadlineIndex==3">
                  <van-col>
                    <van-button class="icon-box" >
                      <van-image width="30" height="30" :src="item.img" />
                    </van-button>
                    <div class="icon-name">{{vipInterest[item.text]}}</div>
                  </van-col>
                </div>
                <div  class="col-item" v-if="item.text!='look_ofo_text'">
                  <van-col class="col-item" v-if="item.text!='look_ofo_text'">
                    <van-button class="icon-box" >
                      <van-image width="30" height="30" :src="item.img" />
                    </van-button>
                    <div class="icon-name">{{vipInterest[item.text]}}</div>
                  </van-col>
                </div>
              </div>
            </van-row>
          </div>
        </div>
      </div>
      <div class="payments">
        <CommonTitle title="支付方式" subtitle="" />
        <div class="background">
            <PaymentItem
              key=1
              :payWayType="this.payWayType"
              :payWayName="this.payWayName"
              icon_name="DOIT"
              color='#fff'
              sub_color='#868A93'
              payment_name="色币"
              :sub_name="'余额：' +
                  parseFloat(
                    assetsData && assetsData.DOIT ? assetsData.DOIT.totalAssets : 0,
                  ).toFixed(2)"
              type="1"
              name="DOIT"
              :chooseWay="this.chooseWay"
            />
            <div v-if="paymentList.length>0">
              <PaymentItem
                v-for="item in paymentList"
                :key="item.id"
                :payWayType="this.payWayType"
                :payWayName="this.payWayName"
                :icon_name="item.icon_name"
                :color="item.color"
                :sub_color="item.sub_color"
                :payment_name="item.payment_name"
                :sub_name="item.sub_name"
                :type="item.type"
                :name="item.name"
                :chooseWay="this.chooseWay"
              />
            </div>
            <div class="background" v-if="paymentList.length<=0">
                <div class="nopayment">暂无通道，请选择其他金额或先充值色币在进行支付</div>
            </div>
        </div>
      </div>
      <van-button type="primary" size="large" block round class="submit-btn" @click="this.openingVIP">确定开通</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { get, post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import { vipRights } from '@/config/vipRight.js'
import PaymentItem from '@/components/Common/PaymentItem.vue'

const selectImage = require('@/assets/icon/icon_recharge_select.png')
export default {
  name: 'OpenVip',
  components: {
    CommonTitle,
    PaymentItem
  },
  props: {
    deadline: { type: Number }
    // vip_level: { type: Number },
    // vip_deadline_type: { type: Number },
    // assetsDOIT: { type: Number }
  },
  data () {
    return {
      vipIndex: 3,
      deadlineIndex: 0,
      vipInterests: {},
      vipInterest: {},
      canSubmit: false,
      exchangerRate: 1,
      paymentList: [],
      allPayment: [],
      vipLevelList: [
        { id: 3, name: '钻石' }
      ],
      vipLevelNames: [],
      vipDeadlineTypeList: [
        { id: 0, name: '月度', days: 30 },
        { id: 1, name: '季度', days: 90 },
        { id: 2, name: '年度', days: 365 },
        { id: 3, name: '终身', days: 36500 }
      ],
      vipConfigList: [],
      vipRights,
      payWayType: 1,
      payWayName: 'DOIT',
      userInfo: this.$store.getters.userInfo,
      assetsData: {},
      selectImage,
      amount: 0,
      payMoney: 0.0
    }
  },
  computed: {
  },
  created () {
    this.getAssets()
    this.getVipInterests()
    this.getPaymentList()
    this.getExchangerRate()
    this.getVipLevelList()
    this.getVipDeadlineTypeList()
    this.getVipConfigList()
    this.getRenewAmount()
    if (this.deadline && this.deadline >= 0) {
      this.changeDeadLine(this.deadline)
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    getAssets () {
      get('/asset/v1.assets/getAssets').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.assetsData = res.data
        this.$store.dispatch('setAssetsData', res.data)
      })
    },
    changeDeadLine (deadline) {
      this.deadlineIndex = deadline
      this.getRenewAmount()
    },
    choosePayment (amount) {
      const newPayment = []
      if (this.allPayment.length > 0) {
        this.allPayment.forEach((item, index) => {
          // eslint-disable-next-line eqeqeq
          if (item.range == -1) {
            newPayment.push(item)
          // eslint-disable-next-line eqeqeq
          } else if (item.range.indexOf('-') != -1) {
            const rangeArr = item.range.split('-')
            if (
              Number(amount) >= Number(rangeArr[0]) &&
              Number(amount) <= Number(rangeArr[1])
            ) {
              newPayment.push(item)
            }
          // eslint-disable-next-line eqeqeq
          } else if (item.range.indexOf(',') != -1) {
            const rangeArr = item.range.split(',')
            rangeArr.forEach((v, i) => {
              // eslint-disable-next-line eqeqeq
              if (Number(v) == Number(amount)) {
                newPayment.push(item)
                return null
              }
            })
          }
        })
        this.paymentList = newPayment
        this.payWayType = 0
        this.payWayName = ''
      }
    },
    chooseWay (type, name) {
      this.payWayType = type
      this.payWayName = name
    },
    getVipInterests () {
      get('/common/v1.system/getVipInterests').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.vipInterests = res.data
        this.vipInterest = this.vipInterests[this.vipIndex]
      })
    },
    getExchangerRate () {
      get('/common/v1.system/getExchangerRate').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data && res.data.doit_cny) {
          this.canSubmit = true
          this.exchangerRate = parseFloat(res.data.doit_cny.rate)
        }
      })
    },
    getPaymentList () {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const device = (isiOS) ? 'ios' : 'android'
      get('/asset/v1.assets/paymentList?way=vip&device=' + device).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.paymentList = res.data
        this.allPayment = res.data
      })
    },
    getVipLevelList () {
      get('/common/v1.system/getVipLevelList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data) {
          const vipLevelName = []
          const vipLevel = []
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index]
            // eslint-disable-next-line eqeqeq
            if (element.id != 0) {
              vipLevel.push(element)
              vipLevelName.push(element.name)
            }
          }
          this.vipLevelList = vipLevel
          this.vipLevelNames = vipLevelName
        }
      })
    },
    getVipDeadlineTypeList () {
      get('/common/v1.system/getVipDeadlineTypeList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.vipDeadlineTypeList = res.data
      })
    },
    getVipConfigList () {
      get('/common/v1.system/getVipConfigList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data) {
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index]
            element.enable = true
            if (
              this.userInfo.vip_level &&
              (element.level < this.userInfo.vip_level ||
                (element.level > this.userInfo.vip_level &&
                  this.userInfo.vip_deadline_type > element.deadline_type) ||
                // eslint-disable-next-line eqeqeq
                (element.level == this.userInfo.vip_level &&
                  // eslint-disable-next-line eqeqeq
                  this.userInfo.vip_deadline_type == 3))
            ) {
              element.enable = false
            }
            // eslint-disable-next-line eqeqeq
            if (this.deadline && this.deadline == 3 && element.deadline_type != this.deadline) {
              element.enable = false
            }
          }
          const vipConfig = {}
          for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index]
            // eslint-disable-next-line eqeqeq
            vipConfig[element.level] = vipConfig[element.level] || []
            vipConfig[element.level].push(element)
          }
          this.vipConfigList = vipConfig
        }
      })
    },
    getRenewAmount () {
      const toast = Toast.loading('加载中', 20)
      post('/common/v1.user/getRenewAmount',
        {
          vip_level: this.vipIndex,
          vip_deadline_type: this.deadlineIndex,
          vip_deadline: 1
        }
      ).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          this.amount = -1
          this.payMoney = -1
          return
        }
        this.amount = parseFloat(res.data.amount).toFixed(2)
        this.payMoney = (
          parseFloat(res.data.amount) * this.exchangerRate
        ).toFixed(2)
        this.choosePayment(this.amount)
      })
    },
    openingVIP () {
      if (this.amount <= 0) {
        Toast.fail('请选择开通级别')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.payWayName.length == 0) {
        Toast.fail('请选择充值方式')
        return
      }
      const toast = Toast.loading('加载中', 20)
      post('/common/v1.user/openingVIP', {
        vip_level: this.vipIndex,
        vip_deadline_type: this.deadlineIndex,
        vip_deadline: 1,
        pay_name: this.payWayName,
        pay_type: this.payWayType
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        // eslint-disable-next-line eqeqeq
        if (this.payWayName == 'DOIT') {
          Toast.success('开通成功')
          this.$router.back()
        } else {
          let query = ''
          let blank = 0
          for (const i in res.data) {
            // eslint-disable-next-line eqeqeq
            if (i != 'web_view_source' && i != 'url') {
              query += i + '=' + encodeURIComponent(res.data[i]) + '&'
            }
            // eslint-disable-next-line eqeqeq
            if (i == 'xapp-target') {
              blank = 1
            }
          }
          // eslint-disable-next-line eqeqeq
          if (query != '') {
            query = query.substring(0, query.length - 1)
          }
          let resurl = res.data.url
          // eslint-disable-next-line eqeqeq
          if (resurl.indexOf('?') == -1) {
            // eslint-disable-next-line eqeqeq
            if (query != '') {
              resurl = res.data.url + '?' + query
            }
          } else {
            const last = resurl.substr(resurl.length - 1, 1)
            // eslint-disable-next-line eqeqeq
            if (last == '&') {
              resurl = res.data.url + query
            } else {
              resurl = res.data.url + '&' + query
            }
          }
          this.$router.push({ name: 'Pay2', query: { url: encodeURIComponent(resurl), blank } })
        }
      })
    }
  },
  setup () {
  }
}
</script>

<style lang="scss" scoped>
.open-vip{
  .warpper {
    padding:10px;
  }
  .vip-item {
    padding:25px 5px;
    border-radius: var(--van-card-border-radius);
    text-align: center;
    position: relative;
  }
  .vip-amount{
    font-size:20px;
  }
  .currency{
    font-size:10px;
    color:#ddd;
  }
  .dead-line{
    font-size:16px;
    color: #fff;
    margin-top: 10px;
  }
  .actual-pay{
    text-align: right;
  }
  .background{
    background: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .nopayment{
    padding: 10px;
    color:#D91A6E;
  }
  .icon-group{
    margin:10px 0;
    padding:10px;
    background-color: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .icon-item{
    margin:5px 0 5px 0;
    width: 25%;
  }
  .col-item{
    text-align: center;
  }
  .icon-name{
    font-size:10px;
    color:#fff;
    margin-top:4px;
  }
  .icon{
    width:20px;
    height:20px;
  }
  .icon-box {
    width: 45px;
    height: 45px;
    border-radius: var(--van-card-border-radius);
  }
  .submit-btn{
    margin-top:20px;
  }
  .selectImage{
    position: absolute;
    top:0;
    right:0;
  }
}
</style>
