<template>
  <div class="ofo-my">
    <van-sticky>
      <van-nav-bar title="我的体验报告" :border=false>
        <template #right>
          <div v-if="userInfo.vip_level==3 && userInfo.vip_deadline_type==3" @click="$router.push({name:'AddExp'})" style="color:#D91A6E">
            新增
          </div>
        </template>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-sticky>
        <van-tabs v-model:active="selectedIndex" @click-tab="changeTab">
          <van-tab v-for="(item, index) in types" :title="item.name" :key="item.id" :name="index">
          </van-tab>
        </van-tabs>
      </van-sticky>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-top:10px;">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <template v-if="list && list.length>0">
            <div class="ofo-item">
              <OfoExpItem
                v-for="item in list"
                :key="item.id"
                :id="item.id"
                :girl_name="item.girl_name"
                :exp_time="item.exp_time"
                :location="item.location"
                :service_desc="item.service_desc"
                :image_around="item.image_around"
                :image_girl="item.image_girl"
                :image_inner="item.image_inner"
                :image_pay="item.image_pay"
                :look_ofo_exp="item.look_ofo_exp"
                :service_score="item.service_score"
                :created_at="item.created_at"
                :status="item.status"
                :reason="item.reason"
                :editable="true"
                :onEdit="onEdit"
                :onPullOff="onPullOff"
                />
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :code="code"
    :onClose="closeDialog"
    :onPay="onPay"
    :onDeposit="onDeposit"
    :onOpenVip="onOpenVip"
  />
</template>

<script>
import { get, post } from '@/utils/request'
import OfoExpItem from '@/components/Ofo/ExpItem.vue'
import { Toast } from 'vant'
import Dialog from '@/components/Common/Dialog.vue'
export default {
  name: 'MyExp',
  props: {

  },
  components: {
    OfoExpItem,
    Dialog
  },
  data () {
    return {
      page: 1,
      total: 0,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      code: 0,
      id: 0,
      userInfo: this.$store.getters.userInfo,
      types: [
        { name: '已上架', type: '1' },
        { name: '待审核', type: '2' },
        { name: '已下架', type: '3' },
        { name: '不通过', type: '4' },
        { name: '全部', type: '5' }
      ],
      selectedIndex: 0
    }
  },
  created () {
    this.getList()
  },
  methods: {
    changeTab (data) {
      this.selectedIndex = data.name
      this.onRefresh()
    },
    getList () {
      get('/common/v1.ofo/expListOfMine', {
        page: this.page,
        type: this.types[this.selectedIndex].type
      }).then(res => {
        this.loading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          // eslint-disable-next-line eqeqeq
          if (this.page == 1) {
            this.list = data
          }
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    onEdit (id) {
      this.$router.push({ name: 'EditExp', params: { id } })
    },
    closeDialog () {
      this.dialog = false
    },
    onPullOff (id) {
      this.id = id
      this.code = 5003
      this.dialog = true
      this.title = '您确定下架该体验报告吗？'
      this.subtitle = ''
      this.yesBtnText = '立即下架'
    },
    onOpenVip () { // 下架楼凤
      this.dialog = false
      const toast = Toast.loading('提交中')
      post('/common/v1.ofo/deleteExp', {
        exp_id: this.id
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
        } else {
          this.onRefresh()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ofo-my {
  .wrapper{
    padding: 10px;
  }
}
</style>
