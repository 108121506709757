import axios from 'axios'
import qs from 'qs'
import router from '../router/index'
import { createSign } from '../utils/createSign'

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : '/common'
axios.defaults.timeout = 30000
axios.defaults.headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }
/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  config => {
    let tokenData = localStorage.getItem('tokenData')
    tokenData = tokenData ? JSON.parse(tokenData) : {}
    const userToken = tokenData.token ? tokenData.token.access_token : ''
    config.headers['client-type'] = 'web'
    config.headers.lang = 'zh'
    config.headers.appversion = '1.0.0'
    config.headers.sign = createSign()
    if (userToken) {
      config.headers.Authorization = 'Bearer' + ' ' + userToken
    }
    // console.log(config)
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    const toLoginArr = [1, 1001, 1005, 3006, 888888, 99999]
    if (toLoginArr.indexOf(response.data.code) > -1) {
      // eslint-disable-next-line eqeqeq
      if (response.data.code == 99999) {
        router.push({ name: 'Maintenance' })
        return null
      }
      router.replace({ name: 'Welcome' })
    }
    // console.log(response)
    return response.data
  },
  error => {
    console.log(error)
    return Promise.reject(error.response)
  }
)

/**
* get方法
* @param {String} url [请求的url地址]
* @param {Object} params [参数]
*/
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
* post方法
* @param {String} url [请求的url地址]
* @param {Object} params [参数]
*/
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(params, { indices: false })).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
