<template>
  <div class="login">
    <van-sticky>
      <van-nav-bar
        left-arrow
        :border=false
        @click-left="onClickLeft"
      />
    </van-sticky>
    <div class="section">
      <p class="lbl_title">欢迎回来</p>
      <p class="lbl_subtitle">登录您的色影账户</p>
      <div>
        <van-field v-model="username" type="tel" class="input-item" placeholder="手机号码">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'mobile-alt']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password" type="password" class="input-item"  placeholder="登录密码">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-button type="primary" block round class="btn-login mt20" @click="login()">登录</van-button>
        <van-row justify="space-between" class="mt20">
          <van-col span="10"><van-button type="primary" size="small" class="btn" block @click="jumpPage('GuestLogin')">游客凭证登陆</van-button></van-col>
          <van-col span="10"><van-button type="primary" size="small" class="btn" block @click="jumpPage('ForgotPassword')">忘记密码</van-button></van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '../utils/request'
import { Toast } from 'vant'
import validator from 'validator'

export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  setup () {
    const onClickLeft = () => history.back()
    return {
      onClickLeft
    }
  },
  methods: {
    jumpPage (name) {
      this.$router.push({ name })
      return null
    },
    login  () {
      if (!validator.isMobilePhone(this.username, ['zh-CN'])) {
        Toast.fail('手机号码错误')
        return
      }
      if (this.password.length < 6) {
        Toast.fail('密码长度不能小于6个字符')
        return
      }
      if (this.password.length > 32) {
        Toast.fail('密码长度不能大于32个字符')
        return
      }
      this.$store.dispatch('removeTokenData')
      const toast = Toast.loading('加载中')
      post('/common/v1.login/login',
        {
          username: this.username,
          password: this.password,
          client_secret: 'jNv8YgpOeFRas1Pa4Fn6FmWkIefNGlylwkTPTVQr',
          client_id: 1,
          device_id: 'test',
          agreed: 1,
          code: '2'
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.$store.dispatch('setTokenData', res.data)
        post('/common/v1.user/getUserInfo').then(res => {
        // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            Toast.fail(res.msg)
            return
          }
          toast.clear()
          this.$store.dispatch('setUserInfo', res.data)
          this.$router.replace({ name: 'Home' })
        })
      })
    }
  },
  created () {
  },
  name: 'Login',
  components: {}
}
</script>

<style lang="scss">
.login{
  .section{
    padding:20px;
  }
  .lbl_title{
    font-size: 20px;
    color:white;
    margin-bottom: 10px;
  }
  .lbl_subtitle{
    font-size: 12px;
    color:white;
    margin-bottom: 30px;
  }
  .input-item{
    margin:10px 0;
    border-radius: 20px;
  }
  .btn-login{
    margin-top: 30px;
  }
  .forgot{
    margin: 20px auto;
    text-align: center;
    color:white;
    font-size: 12px;
  }
  .icon-box{
    min-width: 40px;;
  }
  .input-icon{
    color:#D91A6E;
    width: 20px !important;
    height: 20px;
  }
  .mt20{
    margin-top:20px;
  }
  .btn{
    background: #161A22;
  }
}
</style>
