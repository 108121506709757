<template>
  <div class="edit-profile">
    <van-sticky>
      <van-nav-bar title="编辑个人信息" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="submit" style="color:#D91A6E">保存</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <div v-if="keyword=='nickname'">
        <div class="name">昵称</div>
        <van-cell-group inset>
          <van-field
            v-model="userInfo.nickname"
            maxlength="20"
            placeholder="请输入昵称"
            show-word-limit
            clearable
          />
        </van-cell-group>
        <div class="tip">最多支持20个字符，支持emoji表情和特殊字符</div>
      </div>
      <div v-if="keyword=='sex'">
        <div class="name">性别</div>
        <van-radio-group v-model="userInfo.sex" direction="horizontal">
          <van-radio name="1">{{sexArr[0]}}</van-radio>
          <van-radio name="2">{{sexArr[1]}}</van-radio>
        </van-radio-group>
      </div>
      <div v-if="keyword=='height'">
        <div class="name">身高（cm）</div>
        <van-cell-group inset>
          <van-field
            v-model="userInfo.height"
            placeholder="请输入身高"
            clearable
          />
        </van-cell-group>
        <div class="tip">比如1米8的话，那就填180</div>
      </div>
      <div v-if="keyword=='weight'">
        <div class="name">体重（Kg）</div>
        <van-cell-group inset>
          <van-field
            v-model="userInfo.weight"
            placeholder="请输入体重"
            clearable
          />
        </van-cell-group>
        <div class="tip">比如50公斤的话，那就填50</div>
      </div>
      <div v-if="keyword=='birthday'">
        <div class="name">生日</div>
        <van-cell-group inset>
          <van-field
            v-model="userInfo.birthday"
            placeholder="请输入生日"
            clearable
          />
        </van-cell-group>
        <div class="tip">例如：1990-01-20，也可以输入“12月1号”</div>
      </div>
      <div v-if="keyword=='city'">
        <div class="name">所在城市</div>
        <van-cell-group inset>
          <van-field
            v-model="userInfo.city"
            is-link
            readonly
            placeholder="点击选择城市"
            @click="$router.push({name:'CityPicker'})"
          />
        </van-cell-group>
      </div>
      <div v-if="keyword=='contact'">
        <div class="name">联系方式</div>
        <van-radio-group v-model.number="contactType" direction="horizontal" @change="changeContactType">
          <van-radio name="0">{{contactArr[0]}}</van-radio>
          <van-radio name="1">{{contactArr[1]}}</van-radio>
          <van-radio name="2">{{contactArr[2]}}</van-radio>
        </van-radio-group>
        <van-cell-group inset style="margin-top:10px;">
          <van-field
            v-model="contact"
            :placeholder="contact_placeholder"
            clearable
          />
        </van-cell-group>
        <div class="tip">目前只支持一种联系方式，请选择您常用联系方式，以便别人联系您</div>
      </div>
      <div v-if="keyword=='introduction'">
        <div class="name">自我介绍</div>
        <van-cell-group inset>
          <van-field
            v-model="userInfo.introduction"
            rows="3"
            autosize
            type="textarea"
            maxlength="120"
            placeholder="请输入自我介绍，让大家快速了解您"
            show-word-limit
            clearable
          />
        </van-cell-group>
        <div class="tip">最多支持120个字符，支持emoji表情和特殊字符</div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { post } from '@/utils/request'
import { Toast } from 'vant'
export default {
  name: 'EditProfile',
  components: {
  },
  props: {
    keyword: { type: String }
  },
  data () {
    return {
      sexArr: ['男', '女'],
      contactArr: ['微信', '手机', 'QQ'],
      showCalendar: false,
      userInfo: this.$store.getters.userInfo,
      contactType: this.$store.getters.userInfo.contactType.toString(),
      contact: this.$store.getters.userInfo.contact
    }
  },
  computed: {
    contact_placeholder: (data) => {
      return '请输入您的' + data.contactArr[data.contactType]
    }
  },
  methods: {
    changeContactType (data) {
      this.contactType = data.toString()
      this.contact = ''
    },
    submit () {
      let data = {}
      if (this.keyword === 'nickname') {
        data = { nickname: this.userInfo.nickname }
      }
      if (this.keyword === 'sex') {
        data = { sex: this.userInfo.sex }
      }
      if (this.keyword === 'height') {
        data = { height: this.userInfo.height }
      }
      if (this.keyword === 'weight') {
        data = { weight: this.userInfo.weight }
      }
      if (this.keyword === 'birthday') {
        data = { birthday: this.userInfo.birthday }
      }
      if (this.keyword === 'city') {
        data = {
          area: this.userInfo.area,
          city: this.userInfo.city
        }
      }
      if (this.keyword === 'contact') {
        if (this.contact === '') {
          Toast.fail('请输入你的联系方式')
          return
        }
        data = {
          contact_type: this.contactType,
          contact: this.contact
        }
        this.userInfo.contactType = this.contactType
        this.userInfo.contact = this.contact
      }
      if (this.keyword === 'introduction') {
        data = { introduction: this.userInfo.introduction }
      }
      const loading = Toast.loading('设置中')
      post('/common/v1.user/updateUserInfo2', data).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success({
          message: res.msg,
          onClose: () => {
            this.$router.back()
          }
        })
        this.$store.dispatch('setUserInfo', this.userInfo)
      })
    }
  },
  created () {
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.userInfo.area = data.id
      this.userInfo.city = data.city
    })
  }
}
</script>

<style lang="scss">
.edit-profile{
  .wrapper{
    padding:10px;
  }
  .name{
    font-size:14px;
    color:#868A93;
    line-height: 40px;
  }
  .tip{
    font-size:12px;
    color:#868A93;
    line-height: 40px;
  }
  .van-radio--horizontal{
    margin-right:30px;
  }
  .van-radio__label{
    color: #fff;
  }
  .van-calendar__month, .van-calendar__header{
    color: #000;
  }
}
</style>
