<template>
  <div class="withdraw">
    <van-sticky>
      <van-nav-bar title="提币"  :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.push({ name: 'My' })" style="width:18px;height:18px;"  />
        </template>
        <template #right>
          <div @click="$router.push({ name: 'WithdrawRecord', params:{type:'USDT'} })">提币记录</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-cell-group inset>
        <van-field v-model="address" type="tel" readonly class="input-item" placeholder="请选择到账地址" @click="$router.push({name: 'AddressManage'})">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'credit-card']" class="input-icon" />
            </div>
          </template>
          <template #right-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="right-input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="amount" type="tel" class="input-item" placeholder="提币数量">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'coins']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password" type="password" class="input-item" placeholder="支付密码">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="code" center clearable type="digit"  placeholder="手机验证码" class="input-item" >
          <template #button>
            <van-button size="mini" type="primary" round class="input-right-item" v-if="setCode" @click="getCode" :disabled="disabled" >{{setCode}}</van-button>
          </template>
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'sms']" class="input-icon" />
            </div>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin:15px 0;text-align:right;font-size:14px;">到账金额：{{amount || 0.00}}</div>
      <van-button type="primary" block round class="withdraw-btn" @click="withdraw">提交</van-button>
      <Tips :data="lang.withdrawusdt_tips" v-if="lang && lang.withdrawusdt_tips.length>0" style="margin-top:20px;" />
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { Toast } from 'vant'
import { post, get } from '@/utils/request'
import validator from 'validator'
import Tips from '@/components/Common/Tips.vue'

export default {
  name: 'WithdrawUSDT',
  props: {
  },
  components: {
    Tips
  },
  data () {
    return {
      setCode: '发送验证码',
      disabled: false,
      addressId: 0,
      address: null,
      amount: null,
      password: null,
      code: null,
      lang: null
    }
  },
  created () {
    this.getUserDefaultAddress()
    this.getLang()
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    getLang () {
      get('/common/v1.system/getLang').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.lang = res.data
      })
    },
    getCode () {
      post('/common/v1.user/getPhoneCaptcha').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.validateBtn()
        return true
      })
    },
    validateBtn () {
      let time = 60
      const timer = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        if (time == 0) {
          clearInterval(timer)
          this.disabled = false
          this.setCode = '发送验证码'
        } else {
          this.setCode = time + '秒后重试'
          this.disabled = true
          // eslint-disable-next-line no-const-assign
          time--
        }
      }, 1000)
    },
    getUserDefaultAddress () {
      get('/asset/v1.assets/getWalletDefaultaddress').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.address = res.data.address
      })
    },
    withdraw () {
      if (this.addressId <= 0) {
        Toast.fail('请选择到账钱包地址')
        return
      }
      if (this.password.length <= 0) {
        Toast.fail('请输入支付密码')
        return
      }
      if (this.amount <= 0) {
        Toast.fail('请输入提现金额')
        return
      }
      if (this.amount <= 0) {
        Toast.fail('请输入提现金额')
        return
      }
      if (!validator.isInt(this.code, { min: 1000, max: 9999 })) {
        Toast.fail('验证码错误')
        return
      }
      const loading = Toast.loading('请求中')
      post('/asset/v1.assets/withdraw', {
        withdrawaltype: 1,
        payPassword: this.password,
        phonecode: this.code,
        withdrawalamount: this.amount,
        withdrawalRequireId: this.addressId
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Bus.emit('updateAssets')
        Toast.success('提现成功')
        setTimeout(() => {
          this.$router.back()
        }, 1000)
      })
    }
  },
  mounted () {
    // let self = this
    Bus.on('address', (data) => {
      this.address = data.address
      this.addressId = data.id
    })
  }
}
</script>

<style lang="scss">
.withdraw{
  .wrapper{
    padding: 10px;
  }
  .right-input-icon{
    width: 15px;
    height: 15px;
    text-align: right;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .van-field__left-icon{
    margin-right:20px;
  }
  .input-icon{
    width: 15px;
    height:15px;
    color: #D91A6E;
  }
}
</style>
