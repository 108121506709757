<template>
  <van-row class="tips">
    <van-col span="2" class="left-icon">
      <font-awesome-icon :icon="['fas', 'info-circle']" class="icon" />
    </van-col>
    <van-col span="22" class="tips-name">
      <div v-for="(item,index) in data" :key="index" class="tip-name">{{item}}</div>
    </van-col>
  </van-row>
</template>
<script>
export default {
  name: 'Tips',
  props: {
    data: { type: Array }
  },
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.tips{
  .icon{
    color:#F0B90B;
    width:18px;
    height:18px;
  }
  .tips-name{
    font-size:10px;
    color:#868A93;
  }
}
</style>
