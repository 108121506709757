<template>
  <div class="tasks">
    <van-sticky>
      <van-nav-bar title="任务列表" :border=false>
        <template #right>
          <div @click="$router.push({name:'TasksAward'})" >
            奖励记录
          </div>
        </template>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-sticky>
        <van-tabs v-model:active="selectedIndex" @click-tab="changeTab">
          <van-tab v-for="(item, index) in types" :title="item.name" :key="item.id" :name="index">
          </van-tab>
        </van-tabs>
      </van-sticky>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-top:10px;">
        <template v-if="list && list.length>0">
          <div class="" v-if="list && list.length>0">
            <TaskItem
              v-for="item,index in list"
              :index="index"
              :key="item.id"
              :icon="item.icon"
              :icon_bg_color="item.icon_bg_color"
              :remark="item.remark"
              :amount="item.amount"
              :vip_days_str="(item.vip_days_str)?item.vip_days_str:''"
              :finish_status="item.finish_status"
              :scene_type="item.scene_type"
              :scene_key="item.scene_key"
              :scene_params="item.scene_params"
              :name="item.name"
              />
          </div>
        </template>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
// import { Toast } from 'vant'
import TaskItem from '@/components/Common/TaskItem.vue'

export default {
  name: 'Tasks',
  props: {

  },
  components: {
    TaskItem
  },
  data () {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      types: [
        { name: '每日任务', type: '1' },
        { name: '破处任务', type: '2' }
      ],
      selectedIndex: 0
    }
  },
  created () {
    this.getList()
  },
  methods: {
    changeTab (data) {
      this.selectedIndex = data.name
      this.onRefresh()
    },
    getList () {
      let url = '/common/v1.user/getDailyTaskList'
      if (this.selectedIndex === 0) {
        url = '/common/v1.user/getDailyTaskList'
      } else if (this.selectedIndex === 1) {
        url = '/common/v1.user/getFirstTaskList'
      }
      get(url).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.refreshing = false
        this.list = res.data
      })
    },
    onRefresh () {
      this.refreshing = true
      this.getList()
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.tasks {
  .wrapper{
    padding: 10px;
  }
}
</style>
