<template>
  <div class="ofo-index">
    <van-nav-bar title="楼凤信息" :border=false>
      <template #left>
        <div @click="$router.push({name:'CityPicker'})">
          <van-icon name="location" size="18" />
          {{city}}
        </div>
      </template>
      <template #right>
        <font-awesome-icon :icon="['fas', 'search']" class="icon" @click="$router.push({name:'OfoSearch'})" />
      </template>
    </van-nav-bar>
    <div class="top">
      <free-style-shimmer :is-loading="isloading" height="120px" width="100%" border-radius="6px"  @click="getConfig" />
      <van-row class="top-box" v-if="!isloading">
        <van-col span="12" style="margin: auto" @click="$router.push({name:'OfoExp'})">
          <van-row style="padding:5px">
            <van-col span="12">
              <div class="ofo-top-title">{{ofoConfig && ofoConfig.cardLeftTitle}}</div>
              <div class="ofo-top-subtitle">{{ofoConfig && ofoConfig.cardLeftSubTitle1}}</div>
              <div class="ofo-top-subtitle">{{ofoConfig && ofoConfig.cardLeftSubTitle2}}</div>
              <div><van-button type="primary" round size="mini" class="ofo-top-btn">&nbsp;&nbsp;立即查看&nbsp;&nbsp;</van-button></div>
            </van-col>
            <van-col span="12">
              <van-image
                v-if="ofoConfig && ofoConfig.cardLeftImage"
                width="60px"
                height="90px"
                round
                radius="15px"
                :src="imageUrl+ofoConfig.cardLeftImage"
              />
            </van-col>
          </van-row>
        </van-col>
        <van-col span="12" style="margin: auto">
          <div class="right-box"  @click="$router.push({name:'MyOfo'})">
            <van-row style="padding:5px">
              <van-col span="12">
                <div class="ofo-top-title">{{ofoConfig && ofoConfig.cardRightTopTitle}}</div>
                <div class="ofo-top-subtitle">{{ofoConfig && ofoConfig.cardRightTopSubTitle1}}</div>
                <div class="ofo-top-subtitle">{{ofoConfig && ofoConfig.cardRightTopSubTitle2}}</div>
              </van-col>
              <van-col span="12">
                <van-image
                  v-if="ofoConfig && ofoConfig.cardRightTopImage"
                  width="80px"
                  height="46px"
                  round
                  radius="15px"
                  :src="imageUrl+ofoConfig.cardRightTopImage"
                />
              </van-col>
            </van-row>
          </div>
          <div class="right-box"  @click="$router.push({name:'MyExp'})">
            <van-row  style="padding:5px">
              <van-col span="12">
                <div class="ofo-top-title">{{ofoConfig && ofoConfig.cardRightBottomTitle}}</div>
                <div class="ofo-top-subtitle">{{ofoConfig && ofoConfig.cardRightBottomSubTitle1}}</div>
                <div class="ofo-top-subtitle">{{ofoConfig && ofoConfig.cardRightBottomSubTitle2}}</div>
              </van-col>
              <van-col span="12">
                <van-image
                  v-if="ofoConfig && ofoConfig.cardRightBottomImage"
                  width="80px"
                  height="46px"
                  round
                  radius="15px"
                  :src="imageUrl+ofoConfig.cardRightBottomImage"
                />
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <free-style-shimmer :is-loading="isloading" height="35px" style="margin-top:10px" width="100%" border-radius="6px"  @click="getConfig" />
      <van-row class="top-box2" v-if="!isloading" justify="space-between">
        <van-col span="11" @click="openAddOfo">
          <van-button type="primary" block size="small" style="border-radius:15px;">发布楼凤信息</van-button>
        </van-col>
        <van-col span="11" @click="openAddExp">
          <van-button type="primary" block plain size="small" style="border-radius:15px;">上传体验报告</van-button>
        </van-col>
      </van-row>
      <free-style-shimmer :is-loading="isloading2" height="50px " width="100%" border-radius="6px" class="ofo-loading" @click="getTypes" />
      <free-style-shimmer :is-loading="isloading2" height="165px" width="100%" border-radius="6px" class="ofo-loading" v-for="item in 5" :key="item"  @click="getList" />
    </div>
    <van-sticky>
      <van-tabs v-model:active="selectedIndex" @click-tab="changeTab" v-if="!isloading2">
        <van-tab v-for="(item, index) in types" :title="item.name" :key="item.key" :name="index">
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
        <template v-if="list && list.length>0">
          <div class="ofo-item">
            <OfoItem
              v-for="item in list"
              :key="item.id"
              :ofo_id="item.id"
              :avatar="item.avatar"
              :name="item.name"
              :type="item.type"
              :city="item.city"
              :services="item.services"
              :quality="item.quality"
              :monetary="item.monetary"
              :created_at_str="item.created_at_str"
              :look_ofo="item.look_ofo"
              :ofo_up="item.ofo_up"
              :ofo_store="item.ofo_store"
              :status="item.status"
              :margin="item.margin"
              />
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :code="code"
    :onClose="closeDialog"
    :onOpenVip="onOpenVip"
  />
</template>

<script>
import Bus from 'vue3-bus'
import { imageUrl } from '@/config/index.js'
import { get } from '@/utils/request'
import OfoItem from '@/components/Ofo/Item.vue'
import Dialog from '@/components/Common/Dialog.vue'

export default {
  name: 'OfoIndex',
  components: {
    OfoItem,
    Dialog
  },
  data () {
    return {
      ofoConfig: '',
      page: 1,
      total: 0,
      keyword: '',
      adcode: '110100',
      city: '北京',
      loading: false,
      finished: false,
      refreshing: false,
      isloading: true,
      isloading2: true,
      list: [],
      selectedIndex: 0,
      types: [{
        key: 'is_good',
        name: '精选',
        sort: 'desc',
        type: '1'
      }],
      userInfo: this.$store.getters.userInfo,
      code: 0,
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      imageUrl
    }
  },
  created () {
    this.location()
    this.getTypes()
    this.getList()
    this.getConfig()
  },
  methods: {
    openAddOfo () {
      // eslint-disable-next-line eqeqeq
      if (this.userInfo.vip_level != 3 || this.userInfo.vip_deadline_type != 3) {
        this.code = 5003
        this.dialog = true
        this.title = '您不是终身会员，不能上传楼凤信息'
        this.subtitle = '开通终身会员，将体验更多特权'
        this.yesBtnText = '立即开通'
        return
      }
      this.$router.push({ name: 'AddOfo' })
    },
    openAddExp () {
      // eslint-disable-next-line eqeqeq
      if (this.userInfo.vip_level != 3 || this.userInfo.vip_deadline_type != 3) {
        this.code = 5003
        this.dialog = true
        this.title = '您不是终身会员，不能上传楼凤信息'
        this.subtitle = '开通终身会员，将体验更多特权'
        this.yesBtnText = '立即开通'
        return
      }
      this.$router.push({ name: 'AddExp' })
    },
    closeDialog () {
      this.dialog = false
    },
    onOpenVip () {
      this.dialog = false
      setTimeout(() => {
        this.$router.push({ name: 'OpenVip', params: { deadline: 3 } })
      }, 300)
    },
    changeTab (data) {
      this.selectedIndex = data.name
      this.onRefresh()
    },
    location () {
      const location = this.$store.getters.location || {}
      if (location && location.id) {
        this.adcode = location.id
        this.city = location.city
      }
    },
    getTypes () {
      get('/common/v1.system/getOfoType').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.types = res.data
      })
    },
    getConfig () {
      get('/common/v1.system/getConfig?key=ofo_config').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.isloading = false
        this.ofoConfig = res.data
      })
    },
    getList () {
      get('/common/v1.ofo/index', {
        page: this.page,
        sort: this.types[this.selectedIndex].key,
        order: this.types[this.selectedIndex].sort,
        type: this.types[this.selectedIndex].type,
        keyword: '',
        adcode: this.adcode,
        is_vip: 0
      }).then(res => {
        this.isloading2 = false
        this.loading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
      this.onRefresh()
    })
  }
}
</script>
<style lang="scss" scoped>
.ofo-index{
  padding-bottom: 70px;
  .top{
    padding: 10px;
  }
  .top-box{
    background: #1F232B;
    height:120px;
    border-radius: var(--van-card-border-radius);
    text-align: center;
  }
  .top-box2{
    height:35px;
    border-radius: var(--van-card-border-radius);
    text-align: center;
    margin-top:10px;
  }
  .ofo-top-title{
    font-size: 14px;
    margin: 5px;
  }
  .ofo-top-subtitle{
    font-size: 12px;
    color:#868A93;
  }
  .ofo-top-btn{
    font-size: 12px;
    margin: 5px;
  }
  .ofo-item{
    padding: 10px;
  }
  .right-box{
    width:100%;
    height:50%;
  }
  .icon{
    width:16px;
    height:16px;
  }
  .ofo-loading{
    margin-top: 10px;
  }
}
</style>
