<template>
  <div class="profile">
    <van-sticky>
      <van-nav-bar title="个人资料" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <div class="headpic center">
        <van-uploader
          v-model="headpicArr"
          :max-count="1"
          :after-read="addHeadpic"
          :before-delete="delHeadpic"
        />
      </div>
      <div>
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'nickname' }})">
          <van-col span="12">昵称</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{userInfo && userInfo.nickname}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="right-icon"/>
          </van-col>
        </van-row>
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'sex' }})">
          <van-col span="12">性别</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{userInfo && sexArr[userInfo.sex-1]}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'height' }})">
          <van-col span="12">身高</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{(userInfo && userInfo.height=='')?'点击设置':userInfo.height+' cm'}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'weight' }})">
          <van-col span="12">体重</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{(userInfo && userInfo.weight=='')?'点击设置':userInfo.weight+' Kg'}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'birthday' }})">
          <van-col span="12">生日</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{(userInfo && userInfo.birthday=='')?'点击设置':userInfo.birthday}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'city' }})">
          <van-col span="12">所在城市</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{(userInfo && userInfo.city=='')?'点击设置':userInfo.city}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <CommonTitle title="联系方式" />
        <van-row justify="space-between" class="cell" @click="$router.push({name:'EditProfile', params: { keyword: 'contact' }})">
          <van-col span="12">{{userInfo && contactArr[userInfo.contactType]}}</van-col>
          <van-col span="12" class="right">
            <div class="right-text">{{(userInfo && userInfo.contact=='')?'点击设置':userInfo.contact}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <CommonTitle title="其他信息" />
        <van-row justify="space-between" class="cell" @click="$router.push({name:'UserLabels', params: { keyword: userInfo.sex }})">
          <van-col span="12">选择标签</van-col>
          <van-col span="12" class="right">
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
        <van-row justify="space-between" class="cell end" @click="$router.push({name:'EditProfile', params: { keyword: 'introduction' }})">
          <van-col span="12">自我介绍</van-col>
          <van-col span="12" class="right">
            <div class="right-text van-ellipsis">{{(userInfo && userInfo.introduction=='')?'点击设置':userInfo.introduction}}</div>
            <font-awesome-icon :icon="['fas', 'chevron-right']"  class="right-icon"/>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import { imageUrl } from '@/config/index.js'
export default {
  name: 'Profile',
  components: {
    CommonTitle
  },
  data () {
    return {
      imageUrl,
      sexArr: ['男', '女'],
      contactArr: ['微信', '手机', 'QQ'],
      headpicArr: [{ url: imageUrl + this.$store.getters.userInfo.headpic }],
      userInfo: this.$store.getters.userInfo
    }
  },
  methods: {
    addHeadpic (file) {
      this.uploadImg(file, 'headpic')
    },
    delHeadpic () {
      this.headpicArr = []
    },
    uploadImg (file, type) {
      file.status = 'uploading'
      file.message = '上传中...'
      post('/common/v1.user/uploadImge', {
        imageType: type,
        base64_image: file.content
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          file.status = 'failed'
          file.message = res.msg
          return
        }
        this.headpicArr = []
        this.headpicArr.push({ url: imageUrl + res.data.imgUrl })
        this.headpic = res.data.imgUrl
        this.userInfo.headpic = res.data.imgUrl
        setTimeout(() => {
          this.setHeadPic()
        }, 200)
      }).catch(err => {
        console.log(err)
        Toast.fail('上传失败')
        this.headpicArr = []
      })
    },
    setHeadPic () {
      const toastkey = Toast.loading('头像更新中')
      post('/common/v1.user/updateUserInfo2',
        {
          headpic: this.headpic
        }
      ).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success('更新成功')
        this.$store.dispatch('setUserInfo', this.userInfo)
      })
    }
  },
  created () {
  },
  watch: {
    $route (to, from) {
      // eslint-disable-next-line eqeqeq
      if (to.path != from.path && (from.name == 'EditProfile' || from.name == 'My')) {
        this.userInfo = this.$store.getters.userInfo
        this.headpicArr = [{ url: imageUrl + this.$store.getters.userInfo.headpic }]
      }
      return null
    }
  }
}
</script>

<style lang="scss">
.profile{
  .wrapper{
    padding:10px;
  }
  .headpic{
    padding:20px;
  }
  .center{
    text-align: center;
  }
  .right{
    color: #868A93;
    text-align: right;
    overflow: hidden;
  }
  .right-text{
    margin-right:20px;
  }
  .cell{
    font-size: 14px;
    line-height: 50px;
    position: relative;
  }
  .end{
    margin-bottom: 20px;
  }
  .right-icon{
    width:14px;
    height:14px;
    position: absolute;
    right:0;
    top: 18px;
  }
  .van-uploader__upload, .van-uploader__preview-image, .van-uploader__mask{
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
}
</style>
