import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Welcome from '../views/Welcome.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Maintenance from '../views/Maintenance.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import VideoIndex from '../views/Video/Index.vue'
import VideoDetail from '../views/Video/Detail.vue'
import VideoSearch from '../views/Video/Search.vue'
import VideoFilter from '../views/Video/Filter.vue'
import VideoMore from '../views/Video/More.vue'
import HotTopics from '../views/Video/HotTopics.vue'
import OfoIndex from '../views/Ofo/Index.vue'
import OfoExp from '../views/Ofo/Exp.vue'
import OfoSearch from '../views/Ofo/Search.vue'
import OfoDetail from '../views/Ofo/Detail.vue'
import MyOfo from '../views/Ofo/My.vue'
import AddOfo from '../views/Ofo/Add.vue'
import EditOfo from '../views/Ofo/Edit.vue'
import MyExp from '../views/Ofo/MyExp.vue'
import AddExp from '../views/Ofo/AddExp.vue'
import EditExp from '../views/Ofo/EditExp.vue'
import ExpDetail from '../views/Ofo/ExpDetail.vue'
import ClubIndex from '../views/Club/Index.vue'
import ClubDetail from '../views/Club/Detail.vue'
import ClubSearch from '../views/Club/Search.vue'
import My from '../views/My/Index.vue'
import MyTeam from '../views/My/Team.vue'
import MyTeamDetail from '../views/My/TeamDetail.vue'
import MyFavorite from '../views/My/Favorite.vue'
import Setting from '../views/My/Setting.vue'
import PayPassword from '../views/My/PayPassword.vue'
import Deposit from '../views/Assets/Deposit.vue'
import DepositUSDT from '../views/Assets/DepositUSDT.vue'
import DepositRecord from '../views/Assets/DepositRecord.vue'
import Withdraw from '../views/Assets/Withdraw.vue'
import WithdrawUSDT from '../views/Assets/WithdrawUSDT.vue'
import WithdrawRecord from '../views/Assets/WithdrawRecord.vue'
import BankManage from '../views/Assets/BankManage.vue'
import AddressManage from '../views/Assets/AddressManage.vue'
import AddBank from '../views/Assets/AddBank.vue'
import AddAddress from '../views/Assets/AddAddress.vue'
import Pay from '../views/Assets/Pay.vue'
import Pay2 from '../views/Assets/Pay2.vue'
import OpenVip from '../views/Assets/OpenVip.vue'
import OpenOfoVip from '../views/Assets/OpenOfoVip.vue'
import CityPicker from '../components/Common/CityPicker.vue'
import Invite from '../views/My/Invite.vue'
import CustomerService from '../views/My/CustomerService.vue'
import EarnTask from '../views/Other/EarnTask.vue'
import EarnTaskDetail from '../views/Other/EarnTaskDetail.vue'
import MessageCenter from '../views/Other/MessageCenter.vue'
import MessageSystem from '../views/Other/MessageSystem.vue'
import MessageMine from '../views/Other/MessageMine.vue'
import MessageActivity from '../views/Other/MessageActivity.vue'
import MessageDetail from '../views/Other/MessageDetail.vue'
import Faq from '../views/Other/Faq.vue'
import SignIn from '../views/Other/SignIn.vue'
import Profile from '../views/My/Profile.vue'
import EditProfile from '../views/My/EditProfile.vue'
import UserLabels from '../views/My/UserLabels.vue'
import Photos from '../views/My/Photos.vue'
import Exchange from '../views/Assets/Exchange.vue'
import Tasks from '../views/Other/Tasks.vue'
import TasksAward from '../views/Other/TasksAward.vue'
import NoticeDetail from '../views/Other/NoticeDetail.vue'
import Comments from '../views/Other/Comments.vue'
import CommentDetail from '../views/Other/CommentDetail.vue'
import BindPhone from '../views/My/BindPhone.vue'
import GuestLogin from '../views/GuestLogin.vue'
import GuestToken from '../views/My/GuestToken.vue'
import { storage } from '@/utils/storage'

const routes = [
  { path: '/maintenance', name: 'Maintenance', component: Maintenance, meta: { keepAlive: false, auth: false } },
  { path: '/welcome', name: 'Welcome', component: Welcome, meta: { keepAlive: false, auth: false } },
  { path: '/start', name: 'Start', component: Start, meta: { keepAlive: false, auth: false } },
  { path: '/register', name: 'Register', component: Register, meta: { keepAlive: false, auth: false } },
  { path: '/login', name: 'Login', component: Login, meta: { keepAlive: false, auth: false } },
  { path: '/guestlogin', name: 'GuestLogin', component: GuestLogin, meta: { keepAlive: false, auth: false } },
  { path: '/forgotPassword', name: 'ForgotPassword', component: ForgotPassword, meta: { keepAlive: false, auth: false } },
  { path: '/', name: 'Home', component: Home, meta: { keepAlive: true, auth: true } },
  { path: '/video', name: 'VideoIndex', component: VideoIndex, meta: { keepAlive: true, auth: true } },
  { path: '/video/:id', name: 'VideoDetail', component: VideoDetail, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/videosearch/:label?', name: 'VideoSearch', component: VideoSearch, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/videofilter', name: 'VideoFilter', component: VideoFilter, meta: { keepAlive: true, auth: true } },
  { path: '/videomore/:id/:name', name: 'VideoMore', component: VideoMore, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/hottopics', name: 'HotTopics', component: HotTopics, meta: { keepAlive: true, auth: true } },
  { path: '/ofo', name: 'Ofo', component: OfoIndex, meta: { keepAlive: true, auth: true } },
  { path: '/ofoexp', name: 'OfoExp', component: OfoExp, meta: { keepAlive: true, auth: true } },
  { path: '/ofosearch', name: 'OfoSearch', component: OfoSearch, meta: { keepAlive: true, auth: true } },
  { path: '/ofo/:id', name: 'OfoDetail', component: OfoDetail, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/myofo', name: 'MyOfo', component: MyOfo, meta: { keepAlive: true, auth: true } },
  { path: '/addofo', name: 'AddOfo', component: AddOfo, meta: { keepAlive: true, auth: true } },
  { path: '/editofo/:id', name: 'EditOfo', component: EditOfo, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/myexp', name: 'MyExp', component: MyExp, meta: { keepAlive: true, auth: true } },
  { path: '/addexp', name: 'AddExp', component: AddExp, meta: { keepAlive: true, auth: true } },
  { path: '/editexp/:id', name: 'EditExp', component: EditExp, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/exp/:id', name: 'ExpDetail', component: ExpDetail, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/club', name: 'Club', component: ClubIndex, meta: { keepAlive: true, auth: true } },
  { path: '/club/:id', name: 'ClubDetail', component: ClubDetail, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/clubsearch', name: 'ClubSearch', component: ClubSearch, meta: { keepAlive: true, auth: true } },
  { path: '/my', name: 'My', component: My, meta: { keepAlive: true, auth: true } },
  { path: '/profile', name: 'Profile', component: Profile, meta: { keepAlive: true, auth: true } },
  { path: '/editprofile/:keyword', name: 'EditProfile', component: EditProfile, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/myteam', name: 'MyTeam', component: MyTeam, meta: { keepAlive: true, auth: true } },
  { path: '/myteamdetail', name: 'MyTeamDetail', component: MyTeamDetail, meta: { keepAlive: true, auth: true } },
  { path: '/myfavorite', name: 'MyFavorite', component: MyFavorite, meta: { keepAlive: true, auth: true } },
  { path: '/openvip/:deadline?', name: 'OpenVip', component: OpenVip, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/openofovip', name: 'OpenOfoVip', component: OpenOfoVip, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/deposit', name: 'Deposit', component: Deposit, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/depositusdt', name: 'DepositUSDT', component: DepositUSDT, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/depositrecord/:type', name: 'DepositRecord', component: DepositRecord, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/pay', name: 'Pay', component: Pay, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/pay2', name: 'Pay2', component: Pay2, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/citypicker', name: 'CityPicker', component: CityPicker, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/setting', name: 'Setting', component: Setting, meta: { keepAlive: true, auth: true } },
  { path: '/paypassword', name: 'PayPassword', component: PayPassword, meta: { keepAlive: true, auth: true } },
  { path: '/withdraw', name: 'Withdraw', component: Withdraw, meta: { keepAlive: true, auth: true } },
  { path: '/withdrawusdt', name: 'WithdrawUSDT', component: WithdrawUSDT, meta: { keepAlive: true, auth: true } },
  { path: '/withdrawrecord/:type', name: 'WithdrawRecord', component: WithdrawRecord, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/addressmanage', name: 'AddressManage', component: AddressManage, meta: { keepAlive: true, auth: true } },
  { path: '/bankmanage', name: 'BankManage', component: BankManage, meta: { keepAlive: true, auth: true } },
  { path: '/addbank', name: 'AddBank', component: AddBank, meta: { keepAlive: true, auth: true } },
  { path: '/addaddress', name: 'AddAddress', component: AddAddress, meta: { keepAlive: true, auth: true } },
  { path: '/invite', name: 'Invite', component: Invite, meta: { keepAlive: true, auth: true } },
  { path: '/customerservice', name: 'CustomerService', component: CustomerService, meta: { keepAlive: false, auth: true } },
  { path: '/earntask', name: 'EarnTask', component: EarnTask, meta: { keepAlive: true, auth: true } },
  { path: '/earntaskdetail/:id', name: 'EarnTaskDetail', component: EarnTaskDetail, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/messagecenter', name: 'MessageCenter', component: MessageCenter, meta: { keepAlive: true, auth: true } },
  { path: '/messagesystem', name: 'MessageSystem', component: MessageSystem, meta: { keepAlive: true, auth: true } },
  { path: '/messagemine', name: 'MessageMine', component: MessageMine, meta: { keepAlive: true, auth: true } },
  { path: '/messageactivity', name: 'MessageActivity', component: MessageActivity, meta: { keepAlive: true, auth: true } },
  { path: '/messagedetail/:type/:id', name: 'MessageDetail', component: MessageDetail, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/faq', name: 'Faq', component: Faq, meta: { keepAlive: true, auth: true } },
  { path: '/signin', name: 'SignIn', component: SignIn, meta: { keepAlive: true, auth: true } },
  { path: '/userlabels/:keyword', name: 'UserLabels', component: UserLabels, props: true, meta: { keepAlive: false, auth: true } },
  { path: '/photos', name: 'Photos', component: Photos, meta: { keepAlive: true, auth: true } },
  { path: '/exchange', name: 'Exchange', component: Exchange, meta: { keepAlive: true, auth: true } },
  { path: '/tasks', name: 'Tasks', component: Tasks, meta: { keepAlive: true, auth: true } },
  { path: '/tasksaward', name: 'TasksAward', component: TasksAward, meta: { keepAlive: true, auth: true } },
  { path: '/noticedetail/:id', name: 'NoticeDetail', component: NoticeDetail, props: true, meta: { keepAlive: false, auth: false } },
  { path: '/comments/:type/:id', name: 'Comments', component: Comments, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/commentdetail/:type/:id', name: 'CommentDetail', component: CommentDetail, props: true, meta: { keepAlive: true, auth: true } },
  { path: '/bindphone', name: 'BindPhone', component: BindPhone, meta: { keepAlive: true, auth: true } },
  { path: '/guesttoken', name: 'GuestToken', component: GuestToken, meta: { keepAlive: true, auth: true } }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savePosition) {
    if (savePosition) {
      return savePosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// 路由守卫
router.beforeEach((to, from, next) => {
  let tokenData = storage.getItem('tokenData')
  tokenData = tokenData ? JSON.parse(tokenData) : {}
  const userToken = tokenData.token ? tokenData.token.access_token : ''
  if (to.meta.auth) {
    if (userToken) {
      next()
    } else {
      next({
        // path: '/start' //更新检查
        path: '/welcome'
      })
    }
  } else {
    next()
  }
})

export default router
