<template>
  <div class="bank-manage">
    <van-sticky>
      <van-nav-bar title="银行卡管理"  :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="$router.push({name:'AddBank'})" style="color:#D91A6E">添加</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper" v-if="cards && cards.length>0">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <div class="bank-card" v-for="item in cards" :key="item.id">
          <div class="content">
            <div class="bank-name">
              <span @click="onCallback(item)">{{item.bank_name}} <span style="color:#D91A6E;font-style:italic;">#{{item.label}}</span></span>
              <span class="right-icon"><font-awesome-icon :icon="['fas', 'times-circle']" class="close-icon" @click="delUserBank(item.id)" /></span>
            </div>
            <div class="card-number" @click="onCallback(item)">
              <van-row justify="space-between">
                <van-col span="6">{{item.card_number.slice(0,4)}}</van-col>
                <van-col span="6" style="text-align:center">****</van-col>
                <van-col span="6" style="text-align:center">****</van-col>
                <van-col span="6" style="text-align:right">{{item.card_number.slice(-4)}}</van-col>
              </van-row>
            </div>
          </div>
          <van-row justify="space-between" class="footer">
            <van-col span="10">
              <van-button type="primary" size="small" @click="setDefaultBank(item.id)" v-if="item.default==0">设置默认</van-button>
              <van-button type="default" size="small" plain v-if="item.default==1">当前默认</van-button>
            </van-col>
          </van-row>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import Bus from 'vue3-bus'
import { post } from '@/utils/request.js'
export default {
  name: 'BankManage',
  props: {
  },
  components: {

  },
  data () {
    return {
      cards: [],
      loading: false,
      refreshing: false
    }
  },
  created () {
    this.getUserBankList()
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    onCallback (item) {
      Bus.emit('bank', item)
      this.$router.push({ name: 'Withdraw' })
    },
    getUserBankList () {
      post('/asset/v1.assets/getUserBankList').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        this.cards = res.data
      })
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.getUserBankList()
    },
    setDefaultBank (bankId) {
      const toast = Toast.loading('提交中', 20)
      post('/asset/v1.assets/setDefaultBank',
        {
          id: bankId
        }
      ).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.getUserBankList()
      })
    },
    delUserBank (bankId) {
      const toast = Toast.loading('提交中', 20)
      post('/asset/v1.assets/delUserBank',
        {
          id: bankId
        }
      ).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.getUserBankList()
      })
    }
  },
  mounted () {
  },
  watch: {
    $route (to, from) {
      // eslint-disable-next-line eqeqeq
      if ((from.name == 'AddBank')) {
        this.onRefresh()
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.bank-manage{
  .wrapper{
    padding: 10px;
  }
  .bank-card{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
    padding:10px 10px;
    margin-bottom: 10px;
    position: relative;
  }
  .content{
    width: 100%;;
  }
  .right-icon{
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .content div{
    padding:5px 0;
  }
  .bank-name{
    font-size:16px;
  }
  .card-number{
    font-size: 24px;
  }
  .close-icon{
    width: 25px;
    height: 25px;
    color: rgba(0,0,0,0.5);
  }
}
</style>
