<template>
  <div class="register">
    <van-sticky>
      <van-nav-bar left-arrow :border=false @click-left="onClickLeft" />
    </van-sticky>
    <div class="section">
      <p class="lbl_title">注册账号</p>
      <p class="lbl_subtitle">注册专属于您的色影账号</p>
      <div>
        <van-field v-model="username" required type="tel" placeholder="手机号码" class="input-item">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'mobile-alt']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="code" required center clearable type="digit" placeholder="手机验证码" class="input-item">
          <template #button>
            <van-button size="mini" type="primary" class="input-right-item" v-if="setCode" @click="getCode" :disabled="disabled">{{setCode}}</van-button>
          </template>
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'sms']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="invite_code" :readonly="invite_disabled" :disabled="invite_disabled" class="input-item"
          :placeholder="textData.inviteCode_placeholder_extra">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'envelope-open-text']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password" required type="password" placeholder="登录密码" class="input-item">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-field v-model="password2" required type="password" placeholder="确认登录密码" class="input-item">
          <template #left-icon>
            <div class="icon-box">
              <font-awesome-icon :icon="['fas', 'key']" class="input-icon" />
            </div>
          </template>
        </van-field>
        <van-button type="primary" block round class="btn-register mt20" @click="register()">注册</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '../utils/request'
import { Toast } from 'vant'
import validator from 'validator'

export default {
  data () {
    return {
      textData: { inviteCode_placeholder_extra: '邀请码' },
      username: '',
      password: '',
      password2: '',
      code: '',
      invite_code: '',
      setCode: '获取验证码',
      disabled: false,
      invite_disabled: false
    }
  },
  setup () {
    const onClickLeft = () => history.back()
    return {
      onClickLeft
    }
  },
  methods: {
    getText () {
      get('/common/v1.system/getText').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data) {
          this.textData = res.data
        }
      })
    },
    getCode () {
      if (!validator.isMobilePhone(this.username, ['zh-CN'])) {
        Toast.fail('手机号码错误')
        return
      }
      post('/common/v1.system/getPhoneCaptcha',
        {
          type: 'reg',
          phone: this.username,
          area: '86'
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        // if (res.code != 0) {
        //   Toast.fail(res.msg)
        //   return
        // }
        this.validateBtn()
        return true
      })
    },
    validateBtn () {
      let time = 60
      const timer = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        if (time == 0) {
          clearInterval(timer)
          this.disabled = false
          this.setCode = '发送验证码'
        } else {
          this.setCode = time + '秒后重试'
          this.disabled = true
          // eslint-disable-next-line no-const-assign
          time--
        }
      }, 1000)
    },
    register () {
      if (!validator.isMobilePhone(this.username, ['zh-CN'])) {
        Toast.fail('手机号码错误')
        return
      }
      if (!validator.isInt(this.code, { min: 1000, max: 9999 })) {
        Toast.fail('验证码错误')
        return
      }
      if (this.password.length < 6) {
        Toast.fail('密码长度不能小于6个字符')
        return
      }
      if (this.password.length > 32) {
        Toast.fail('密码长度不能大于32个字符')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.password != this.password2) {
        Toast.fail('两次密码必须一致')
        return
      }
      const toast = Toast.loading('加载中')
      post('/common/v1.register/user2',
        {
          username: this.username,
          password: this.password,
          repassword: this.password2,
          type: 'phone',
          agreed: '1',
          client_secret: 'jNv8YgpOeFRas1Pa4Fn6FmWkIefNGlylwkTPTVQr',
          client_id: '1',
          inviteCode: this.invite_code,
          code: this.code
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.$store.dispatch('setTokenData', res.data)
        post('/common/v1.user/getUserInfo').then(res => {
          // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            Toast.fail(res.msg)
            return
          }
          toast.clear()
          this.$store.dispatch('setUserInfo', res.data)
          this.$router.replace({ name: 'Home' })
        })
      })
    }
  },
  created () {
    this.getText()
    window.superInstallSdk.getSuperInstall((channel, data) => {
      if (channel !== '') {
        this.invite_code = channel
        this.invite_disabled = true
      }
    })
  },
  mounted () {
  },
  name: 'Register',
  components: {}
}
</script>

<style scoped lang="scss">
.register {
  .section {
    padding: 20px;
  }
  .lbl_title {
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
  }
  .lbl_subtitle {
    font-size: 12px;
    color: white;
    margin-bottom: 30px;
  }
  .input-item {
    margin: 10px 0;
    border-radius: 20px;
  }
  .input-right {
    margin: 0;
    padding: 0;
  }
  .input-right-item {
    margin: 0;
    border-radius: 20px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .btn-register {
    margin-top: 30px;
  }
  .icon-box {
    min-width: 40px;
  }
  .input-icon {
    color: #D91A6E;
    width: 20px !important;
    height: 20px;
  }
  .van-field {
    color: #000 !important;
  }
  .mt20 {
    margin-top: 20px;
  }
}
</style>
