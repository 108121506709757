<template>
  <van-nav-bar title="城市选择" :border=false>
    <template #left>
        <font-awesome-icon :icon="['fas', 'times']" @click="$router.back()" style="width:18px;height:18px;" />
    </template>
  </van-nav-bar>
  <van-index-bar v-if="CityData && CityData.length>0"  :index-list="indexList">
    <div v-for="(citys,index) in CityData" :key="index">
      <van-index-anchor :index="citys.key"><div class="index-item">{{citys.key}}</div></van-index-anchor>
      <div class="city-wrapper">
        <van-cell
          :title="item.city"
          v-for="item in citys.data[0].citys"
          :key="item.id"
          class="city-item"
          @click="selectCity(item)"
        />
      </div>
    </div>
  </van-index-bar>
</template>

<script>
import Bus from 'vue3-bus'

const CityData = require('@/config/CityData.json')
const indexList = []
for (let i = 0; i < CityData.length; i++) {
  indexList.push(CityData[i].key.substr(0, 2))
}
export default {
  created () {
  },
  name: 'CityPicker',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    }
  },
  components: {

  },
  data () {
    return {
      CityData,
      indexList
    }
  },
  methods: {
    selectCity (item) {
      this.$store.dispatch('setLocation', item)
      Bus.emit('location', item)
      setTimeout(() => { this.$router.back() }, 200)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.index-item{
  color: #fff !important;
  font-weight: 700;
}
.city-wrapper{
  width:88%;
}
.city-item{
  flex: 0 0 25%;
  width: 25%;
  display: inline-flex;
  text-align: center;
  background: var(--van-index-anchor-background-color);
  color: #fff;
  padding: 10px 5px;
}
.van-cell:after{
  border:0;
}
</style>
