<template>
  <div class="section">
    <div class="wrapper">
      <van-row @click="push">
        <van-col span="24" class="exp-item">
          <div><div class="ofo_lbl">妹子花名：</div>{{girl_name}}</div>
          <div><div class="ofo_lbl">体验时间：</div>{{exp_time}}</div>
          <div class="text-line"><div class="ofo_lbl">所在位置：</div>{{location?location:'未透露'}}</div>
          <div><div class="ofo_lbl">服务评分：</div><van-rate v-model="serviceScoreData" readonly allow-half /></div>
          <div class="text-line" v-if="!editable"><div class="ofo_lbl">服务项目：</div>{{service_desc}}</div>
          <div v-if="editable">
            <div class="ofo_lbl">状态：</div>
            <span v-if="status==1">已通过</span>
            <span v-if="status==0">审核中</span>
            <span v-if="status==2">已下架</span>
            <span v-if="status==3">审核不通过</span>
          </div>
          <div v-if="editable && status==3">
            <div class="ofo_lbl">原因：</div>
            <span>{{reason}}</span>
          </div>
        </van-col>
      </van-row>
      <van-row style="padding:0 10px 10px 10px" v-if="!editable" @click="push">
        <van-col span="5" v-if="image_around && image_around.length>0">
          <van-image width="60px" height="90px" :src="imageUrl+image_around" class="img" lazy-load >
            <template v-slot:loading>
              <div>色影</div>
            </template>
          </van-image>
        </van-col>
        <van-col span="5" v-if="image_girl && image_girl.length>0">
          <van-image width="60px" height="90px" :src="imageUrl+image_girl" class="img" lazy-load >
            <template v-slot:loading>
              <div>色影</div>
            </template>
          </van-image>
        </van-col>
        <van-col span="5" v-if="image_inner && image_inner.length>0">
          <van-image width="60px" height="90px" :src="imageUrl+image_inner" class="img" lazy-load >
            <template v-slot:loading>
              <div>色影</div>
            </template>
          </van-image>
        </van-col><van-col span="5" v-if="image_pay && image_pay.length>0">
          <van-image width="60px" height="90px" :src="imageUrl+image_pay" class="img" lazy-load >
            <template v-slot:loading>
              <div>色影</div>
            </template>
          </van-image>
        </van-col>
      </van-row>
      <van-row v-if="editable">
        <van-col span="12" class="footer-left-item" @click="push">{{created_at}}发布</van-col>
        <van-col span="12" class="footer-right-item">
          <van-row v-if="editable && status!=2">
            <van-col span="8">&nbsp;</van-col>
            <van-col span="8"><van-button type="primary" :disabled="!(status==1 || status==3)" size="mini" @click="onEdit(id)">编辑</van-button></van-col>
            <van-col span="8"><van-button type="default" :disabled="status!=1" size="mini" plain @click="onPullOff(id)">下架</van-button></van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { imageUrl } from '@/config/index.js'
export default {
  name: 'OfoExpItem',
  props: {
    id: { type: Number },
    girl_name: { type: String },
    exp_time: { type: String },
    location: { type: String },
    service_desc: { type: String },
    image_around: { type: String },
    image_girl: { type: String },
    image_inner: { type: String },
    image_pay: { type: String },
    look_ofo_exp: { type: Number },
    service_score: { type: String },
    created_at: { type: String, required: true, default: null },
    status: { type: Number },
    reason: { type: String },
    editable: { type: Boolean, default: false },
    onEdit: { type: Function },
    onPullOff: { type: Function }
  },
  components: {

  },
  computed: {
  },
  data () {
    return {
      serviceScoreData: parseFloat(this.service_score),
      imageUrl
    }
  },
  methods: {
    push () {
      if (this.status !== 1) return
      this.$router.push({ name: 'ExpDetail', params: { id: this.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.section{
  width: 100%;
  margin-bottom: 10px;
  padding:0;
}
.wrapper{
  background:#1F232B;
  border-radius: var(--van-card-border-radius);
}
.ofo_lbl{
  color:#868A93 ;
  display: inline-block;
}
.exp-item{
  text-align: left;
  padding:10px;
}

.avatar{
  border-radius: 50px;
  overflow: hidden;
}
.text-line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.footer-left-item{
  line-height: 25px;
}
.footer-left-item, .footer-right-item{
  padding: 0 10px 10px 10px;
  color: #dfe0e1 ;
}
.footer-right-item{
  margin-top: 2px;
  text-align: right;
}
</style>
