const vipRights = [
  [
    {
      img: require('../assets/icon/vip/icon_gycs.png'),
      text: 'watch_times_text'
    },
    {
      img: require('../assets/icon/vip/icon_jylxfs.png'),
      text: 'look_friends_text'
    },
    {
      img: require('../assets/icon/vip/icon_hslxfs.png'),
      text: 'look_club_text'
    },
    {
      img: require('../assets/icon/vip/icon_vip_1.png'),
      text: 'logo_text'
    },
    {
      img: require('../assets/icon/vip/icon_vipsp.png'),
      text: 'is_vip_video_text'
    },
    {
      img: require('../assets/icon/vip/icon_vipjy.png'),
      text: 'is_vip_friends_text'
    },
    {
      img: require('../assets/icon/vip/icon_gdhs.png'),
      text: 'is_vip_club_text'
    }
  ],
  [
    {
      img: require('../assets/icon/vip/icon_gycs.png'),
      text: 'watch_times_text'
    },
    {
      img: require('../assets/icon/vip/icon_jylxfs.png'),
      text: 'look_friends_text'
    },
    {
      img: require('../assets/icon/vip/icon_hslxfs.png'),
      text: 'look_club_text'
    },
    {
      img: require('../assets/icon/vip/icon_vip_2.png'),
      text: 'logo_text'
    },
    {
      img: require('../assets/icon/vip/icon_vipsp.png'),
      text: 'is_vip_video_text'
    },
    {
      img: require('../assets/icon/vip/icon_vipjy.png'),
      text: 'is_vip_friends_text'
    },
    {
      img: require('../assets/icon/vip/icon_gdhs.png'),
      text: 'is_vip_club_text'
    }
  ],
  [
    {
      img: require('../assets/icon/vip/icon_gycs.png'),
      text: 'watch_times_text'
    },
    {
      img: require('../assets/icon/vip/icon_vipsp.png'),
      text: 'look_friends_text'
    },
    {
      img: require('../assets/icon/vip/icon_hslxfs.png'),
      text: 'look_club_text'
    },
    // {
    //   img: require('../assets/icon/vip/icon_vip_3.png'),
    //   text: 'logo_text'
    // },
    // {
    //   img: require('../assets/icon/vip/icon_vipsp.png'),
    //   text: 'is_vip_video_text'
    // },
    // {
    //   img: require('../assets/icon/vip/icon_vipjy.png'),
    //   text: 'is_vip_friends_text'
    // },
    // {
    //   img: require('../assets/icon/vip/icon_gdhs.png'),
    //   text: 'is_vip_club_text'
    // },
    {
      img: require('../assets/icon/vip/icon_jylxfs.png'),
      text: 'look_ofo_text'
    }
  ]
]
export { vipRights }
