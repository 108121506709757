<template>
  <div class="welcome">
    <div class='bg'>
      <div class="slide">
        <div class="section">
          <p class="lbl_title">色影</p>
          <p class="lbl_subtitle">发现身边的美，分享身边的美</p>
          <p class="lbl_intro">全球首创看视频赚钱的APP，让你身心愉悦的同时悄无声息的赚钱。</p>
          <div>
            <van-button type="primary" block round class="btn-login" to='/login'>用户登录</van-button>
            <van-button type="primary" plain block round class="btn-guest" to='/register'>手机注册</van-button>
            <van-divider
                :style="{ color: '#868A93', borderColor: '#868A93' }"
              >
              或者
            </van-divider>
            <van-button type="warning" block round class="btn-guest" @click="guestLogin">游客入口</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :inviteBtnText="inviteBtnText"
    :code="code"
    :onInvite="onGuestLogin"
    :onOpenVip="onLogin"
    :onClose="closeDialog"
  />
</template>

<script>
import Dialog from '@/components/Common/Dialog.vue'
import { post } from '../utils/request'
import { Toast } from 'vant'
import { isAndroid, getAndroidUUID } from '@/utils/common'
import { getUUID } from '@/utils/uuid'

export default {
  name: 'Welcome',
  components: {
    Dialog
  },
  data () {
    return {
      dialog: false,
      code: 1001,
      title: '游客登陆也赠送会员，是否立即更换为送免费观看的注册登录？',
      subtitle: '',
      inviteBtnText: '仍游客登陆',
      yesBtnText: '手机注册送VIP',
      invite_code: ''
    }
  },
  setup () {

  },
  methods: {
    jumpPage (name) {
      this.$router.push({ name })
      return null
    },
    closeDialog () {
      this.dialog = false
    },
    openDialog () {
      this.dialog = true
    },
    onGuestLogin () {
      this.dialog = false
      setTimeout(() => {
        this.guestLogin()
      }, 20)
    },
    onLogin () {
      this.dialog = false
      setTimeout(() => {
        this.$router.push({ name: 'Register' })
      }, 20)
    },
    guestLogin () {
      let username = ''
      if (isAndroid()) {
        username = getAndroidUUID()
        console.log('android--getAndroidUUID', username)
      } else {
        username = getUUID()
        console.log('ios--getUUID', username)
      }
      if (!username) {
        Toast.fail('游客登录失败，请联系在线客服[uuid]')
        return
      }
      this.login(username)
    },
    login (username) {
      const toast = Toast.loading('加载中')
      post('/common/v1.register/guestRegOrLogin',
        {
          username,
          type: 'guest',
          agreed: '1',
          client_secret: 'jNv8YgpOeFRas1Pa4Fn6FmWkIefNGlylwkTPTVQr',
          client_id: '1',
          inviteCode: this.invite_code
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.$store.dispatch('setTokenData', res.data)
        post('/common/v1.user/getUserInfo').then(res => {
          // eslint-disable-next-line eqeqeq
          if (res.code != 0) {
            Toast.fail(res.msg)
            return
          }
          this.$store.dispatch('setUserInfo', res.data)
          this.$router.replace({ name: 'Home' })
          toast.clear()
        })
      })
    }
  },
  created () {
    window.superInstallSdk.getSuperInstall((channel, data) => {
      if (channel !== '') {
        this.invite_code = channel
        this.invite_disabled = true
      }
    })
  }
}
</script>

<style scoped lang="scss">
.welcome{
  .bg{
    background: url("../assets/welcome.jpg") no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position:fixed;
    max-width:414px;
  }
  .slide{
    height: 100%;
    width: 100%;
    background-color: #161A22dd;
  }
  .section{
    padding: 20px;
    position: absolute;
    bottom:100px;
  }
  .lbl_title{
    font-size: 45px;
    color:white;
  }
  .lbl_subtitle{
    font-size: 20px;
    color:white;
  }
  .lbl_intro{
    font-size: 12px;
    color:white;
  }
  .btn-login{
    margin-top:70px;
  }
  .btn-guest{
    margin-top:20px;
  }
  .register{
    margin: 20px auto;
    text-align: center;
    color:white;
    font-size: 12px;
  }
  .btn{
    border-radius: var(--van-card-border-radius);
  }
}
</style>
