<template>
  <div class="club" @click="push">
    <div class="section">
      <div class="wrapper">
        <van-row>
          <van-col span="7" class="left-item">
            <div class="left-image">
              <van-image width="88px" round height="88px" :src="imageUrl+headpic" lazy-load>
                <template v-slot:loading>
                  <div>色影</div>
                </template>
              </van-image>
            </div>
          </van-col>
          <van-col span="17" class="right-item">
            <div class="title">{{username}}</div>
            <div><span class="label">总推广人数：{{team_num}} 人</span></div>
            <div><span class="label">消费总额：{{team_consume_amount}} 色币</span></div>
            <div><span class="label">存币生息总额：{{team_financial_amount}} 色币</span></div>
            <div><span class="label">注册时间：{{add_time}}</span></div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>
<script>
import { imageUrl } from '@/config/index.js'

export default {
  name: 'TeamItem',
  props: {
    headpic: { type: String },
    nickname: { type: String },
    team_consume_amount: { type: Number },
    team_financial_amount: { type: Number },
    team_num: { type: Number },
    username: { type: String },
    add_time: { type: String }
  },
  components: {

  },
  data () {
    return {
      imageUrl
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.club{
  width:100%;
  .section{
    width: 100%;
    margin-bottom: 10px;
  }
  .wrapper{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .right-item{
    text-align: left;
    padding:10px;
  }
  .left-item{
    display: flex;
  }
  .left-image{
    align-self: center;
    margin: 0 auto;
  }
  .text-2line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .title{
    font-size:16px;
  }
  .label{
    color:#868A93 ;
  }
}
</style>
