<template>
  <div class="ofo-detail">
    <van-sticky>
      <van-nav-bar title="报告详情" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div v-if='false'>投诉</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <free-style-shimmer :is-loading="loading" height="240px" width="100%" border-radius="6px"  class="wrapper" />
    <van-swipe class="my-swipe" :autoplay="3000" lazy-render v-if="!loading && imageArr && imageArr.length>0" @click="showImage">
      <van-swipe-item v-for="image in imageArr" :key="image">
        <img :src="image" class="swipe-img" />
      </van-swipe-item>
    </van-swipe>
    <free-style-shimmer :is-loading="loading" height="60px" width="100%" border-radius="6px"  class="wrapper" />
    <div class="wrapper" v-if="!loading && data" style="padding-bottom:0">
      <div><span class="label">上传者：</span><span class="title">{{data.nickname}}</span></div>
      <div class="subtitle label">
        {{data.created_at}}
      </div>
    </div>
    <div class="wrapper" style="padding-bottom:0">
      <van-row class="xinkebikan">
        <van-col span="4" class="xinkebikan-left">
          <van-image width="50" height="50" :src="require('@/assets/image/xinkebikan.png')" class="xinkebikan-image" />
        </van-col>
        <van-col span="20" class="xinkebikan-right">{{ofoConfig && ofoConfig.expXinkebikan}}</van-col>
      </van-row>
    </div>
    <div class="wrapper" style="margin-bottom: 20px;">
      <div v-if="userInfo.vip_level == 3 && userInfo.vip_deadline_type == 3 && userInfo.ofo_vip == 1">
        <div class="ofo-card  basic-card">
          <div class="top-vip-text1">{{ofoConfig && ofoConfig.ofoVip}}</div>
          <div class="top-vip-text2">剩余次数：无限</div>
          <div class="top-vip-text3 top-vip-desc">您<span>可享受以下特权</span></div>
          <div class="top-vip-text2" v-html="ofoConfig && ofoConfig.ofoVipDesc"></div>
        </div>
      </div>
      <div v-if="userInfo.vip_level == 3 && userInfo.vip_deadline_type == 3 && userInfo.ofo_vip != 1">
        <free-style-shimmer :is-loading="loading" height="115px" width="100%" border-radius="6px" class="top-vip-card" />
        <div class="top-vip-card  basic-card" v-if="!loading">
          <div class="top-vip-text1">尊贵的{{ofoConfig && ofoConfig.vip3}}</div>
          <div class="top-vip-text2">剩余次数：{{remain}}</div>
          <div class="top-vip-text3 top-vip-desc">您可享受{{ofoConfig && ofoConfig.unlockCount}}次以下特权</div>
          <div class="top-vip-text2" v-html="ofoConfig && ofoConfig.vip3Desc"></div>
        </div>
      </div>
      <free-style-shimmer :is-loading="loading" height="70px" width="100%" border-radius="6px"  />
      <div class="contact-card basic-card" @click="getContact" v-if="!loading">
        <van-row>
          <van-col span="6">
            <span class="label"><font-awesome-icon :icon="['fas', 'map-marker-alt']" class="icon2" />&nbsp;详细地址：</span>
          </van-col>
          <van-col span="18">
            <div v-if="!showContact" class="lock-text">
              {{(userInfo.vip_level == 3 &&
                userInfo.vip_deadline_type == 3 &&
                userInfo.ofo_vip == 1) ? '点击解锁' : userInfo.vip_level == 3 &&
                    userInfo.vip_deadline_type == 3 &&
                    userInfo.ofo_vip == 0
                  ? '点击解锁，开通' +
                    (ofoConfig && ofoConfig.ofoVip) +
                    '享无限次'
                  : '点击解锁，开通' +
                    (ofoConfig && ofoConfig.vip3) +
                    '享' +
                    (ofoConfig && ofoConfig.unlockCount) +
                    '次'
              }}
            </div>
            <div v-if="showContact">{{address}}</div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="6">
            <span class="label"><font-awesome-icon :icon="['fas', 'mobile-alt']" class="icon2" />&nbsp;联系方式：</span>
          </van-col>
          <van-col span="18">
            <div v-if="!showContact" class="lock-text">
              {{(userInfo.vip_level == 3 &&
                userInfo.vip_deadline_type == 3 &&
                userInfo.ofo_vip == 1) ? '点击解锁' : userInfo.vip_level == 3 &&
                    userInfo.vip_deadline_type == 3 &&
                    userInfo.ofo_vip == 0
                  ? '点击解锁，开通' +
                    (ofoConfig && ofoConfig.ofoVip) +
                    '享无限次'
                  : '点击解锁，开通' +
                    (ofoConfig && ofoConfig.vip3) +
                    '享' +
                    (ofoConfig && ofoConfig.unlockCount) +
                    '次'
              }}
            </div>
            <div v-if="showContact">{{contacts}}</div>
          </van-col>
        </van-row>
      </div>

      <CommonTitle title="基本信息" />
      <free-style-shimmer :is-loading="loading" height="120px" width="100%" border-radius="6px" />
      <div class="info-card basic-card" v-if="!loading">
        <div class="info-item"><span class="label">妹子花名：</span>{{data && data.girl_name}}</div>
        <div class="info-item"><span class="label">体验报告：</span>{{data && data.exp_time}}</div>
        <div class="info-item"><span class="label">身高身材：</span>{{data && data.height}}</div>
        <div class="info-item"><span class="label">颜值水平：</span>{{data && data.quality}}</div>
        <div class="info-item"><span class="label">胸器罩杯：</span>{{data && data.bar}}</div>
        <div class="info-item"><span class="label">服务详情：</span>{{data && data.service_desc}}</div>
      </div>
      <CommonTitle title="体验评分" />
      <free-style-shimmer :is-loading="loading" height="60px" width="100%" border-radius="6px" />
      <div class="info-card basic-card" v-if="!loading">
        <div class="info-item"><span class="label">妹子颜值：</span><van-rate v-model="data.quality_score" readonly allow-half /></div>
        <div class="info-item"><span class="label">服务质量：</span><van-rate v-model="data.service_score" readonly allow-half /></div>
        <div class="info-item"><span class="label">环境设备：</span><van-rate v-model="data.ambient_score" readonly allow-half /></div>
      </div>
    </div>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :code="code"
    :onClose="closeDialog"
    :onOpenVip="onOpenVip"
    :onPay="onPay"
  />
</template>

<script>
import { post, get } from '@/utils/request'
import { ImagePreview, Toast } from 'vant'
import CommonTitle from '@/components/Common/Title.vue'
import Dialog from '@/components/Common/Dialog.vue'
import { imageUrl } from '@/config/index.js'

export default {
  name: 'ExpDetail',
  props: {
    id: { type: String, required: true }
  },
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    CommonTitle,
    Dialog
  },
  data () {
    return {
      data: {},
      imageArr: [],
      show: false,
      index: 0,
      ofoConfig: null,
      showContact: false,
      contacts: null,
      address: null,
      remain: null,
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      code: 0,
      loading: true,
      userInfo: this.$store.getters.userInfo,
      imageUrl
    }
  },
  created () {
    this.getDetails()
    this.getConfig()
    this.getRemain()
  },
  methods: {
    showImage () {
      ImagePreview({
        images: this.imageArr,
        closeable: true
      })
    },
    closeDialog () {
      this.dialog = false
    },
    onChange (newIndex) {
      this.index = newIndex
    },
    onClickLeft () {
      this.$router.back()
    },
    onOpenVip () {
      this.$router.push({ name: 'OpenVip', params: { deadline: 3 } })
    },
    onPay () {
      this.$router.push({ name: 'OpenOfoVip' })
    },
    onOpenOfoVip () {
      this.$router.push({ name: 'OpenOfoVip' })
    },
    getContact () {
      const toast = Toast.loading('加载中', 20)
      post('/common/v1.ofo/expContact2', {
        exp_id: this.id
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code == 0) {
          this.showContact = true
          this.contacts = res.data.contacts
          this.address = res.data.location
          this.remain = res.data.remain
        // eslint-disable-next-line eqeqeq
        } else if (res.code == 5001) {
          this.code = 0
          this.dialog = true
          this.title = res.msg
          this.yesBtnText = '开通终身会员'
        // eslint-disable-next-line eqeqeq
        } else if (res.code == 5002) {
          this.code = 5004
          this.dialog = true
          this.title = res.msg
          this.yesBtnText = '开通楼凤会员'
        } else {
          Toast.fail('系统繁忙，请稍后重试', 1)
        }
      })
    },
    getDetails () {
      post('/common/v1.ofo/expDetails', {
        exp_id: this.id
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.data = res.data
        const images = []
        if (res.data.image_around) {
          images.push(imageUrl + res.data.image_around)
        }
        if (res.data.image_inner) {
          images.push(imageUrl + res.data.image_inner)
        }
        if (res.data.image_girl) {
          images.push(imageUrl + res.data.image_girl)
        }
        if (res.data.image_pay) {
          images.push(imageUrl + res.data.image_pay)
        }
        this.imageArr = images
      })
    },
    getConfig () {
      get('/common/v1.system/getConfig?key=ofo_config').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.ofoConfig = res.data
      })
    },
    getRemain  () {
      post('/common/v1.ofo/getRemainExp').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.remain = res.data.remain
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ofo-detail{
  .my-swipe .van-swipe-item {
    text-align: center;
    background-color: #000;
    height:240px;
    padding:10px 0;
  }
  .swipe-img{
    height:240px;
    border-radius: var(--van-card-border-radius);
    overflow: hidden;
    align-self: center;
  }
  .wrapper{
    padding:10px;
  }
  .xinkebikan {
    padding: 10px 5px;
    background: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .xinkebikan-left {
    display: flex;
  }
  .xinkebikan-image{
    align-self: center;
    margin: 0 auto;
  }
  .xinkebikan-right{
    font-size:10px;
  }
  .basic-card{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
    padding:5px 10px;
  }
  .top-vip-card, .ofo-vip-card{
    margin-bottom: 10px;
  }
  .contact-card .van-col{
    padding:5px 0;
    display: flex;
    align-items: center;
  }
  .title{
    font-size: 16px;
    line-height: 30px;
  }
  .subtitle{
    font-size: 12px;
    line-height: 30px;
  }
  .subtitle-data{
    margin-left:20px;
  }
  .info-item{
    line-height: 20px;
  }
  .top-vip-text1{
    font-size:14px;
    line-height: 30px;
  }
  .top-vip-desc{
    color:#D91A6E;
  }
  .top-vip-text2{
    color:#868A93;
    font-size:10px;
    line-height: 20px;
  }
  .top-vip-text3{
    line-height: 20px;
    margin-top:10px;
  }
  .lock-text{
    color:#D91A6E
  }
  .ofo-card{
    border-radius: var(--van-card-border-radius);
    padding:10px 10px;
    background: #1F232B url('~@/assets/image/ofo_vip_bg.png') right center no-repeat;
    background-size: contain;
    margin-bottom: 10px;
  }
  .label{
    color:#868A93;
  }
}
</style>
