<template>
  <div class="open-ofo-vip">
    <van-sticky>
      <van-nav-bar title="楼凤至尊无限卡" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="warpper">
      <div class="ofo-card">
        <div class="top-vip-text1">{{ofo_config && ofo_config.ofoVip2}}</div>
        <div class="top-vip-text2">到期时间：永久有效</div>
        <div class="top-vip-text3">无限卡开通后<span class="top-vip-desc">可享受以下特权</span></div>
        <div class="top-vip-text2" v-html="ofo_config && ofo_config.ofoVipDesc"></div>
      </div>
      <div class="payments">
        <CommonTitle title="支付方式" subtitle="" />
        <div class="background">
            <PaymentItem
              key=1
              :payWayType="this.payWayType"
              :payWayName="this.payWayName"
              icon_name="DOIT"
              color='#fff'
              sub_color='#868A93'
              payment_name="色币"
              :sub_name="'余额：' +
                  parseFloat(
                    assetsData && assetsData.DOIT ? assetsData.DOIT.totalAssets : 0,
                  ).toFixed(2)"
              type="1"
              name="DOIT"
              :chooseWay="this.chooseWay"
            />
            <PaymentItem
              v-for="item in paymentList"
              :key="item.id"
              :payWayType="this.payWayType"
              :payWayName="this.payWayName"
              :icon_name="item.icon_name"
              :color="item.color"
              :sub_color="item.sub_color"
              :payment_name="item.payment_name"
              :sub_name="item.sub_name"
              :type="item.type"
              :name="item.name"
              :chooseWay="this.chooseWay"
            />
        </div>
      </div>
      <van-button type="primary" size="large" block round class="submit-btn" @click="this.openingVIP">确定开通</van-button>
    </div>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :code="code"
    :onClose="closeDialog"
    :onDeposit="onDeposit"
  />
</template>

<script>
import { Toast } from 'vant'
import { get, post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import PaymentItem from '@/components/Common/PaymentItem.vue'
import Dialog from '@/components/Common/Dialog.vue'

export default {
  name: 'OpenOfoVip',
  components: {
    CommonTitle,
    PaymentItem,
    Dialog
  },
  props: {
    // vip_level: { type: Number },
    // vip_deadline_type: { type: Number },
    // assetsDOIT: { type: Number }
  },
  data () {
    return {
      canSubmit: false,
      paymentList: [],
      payWayType: 1,
      payWayName: 'DOIT',
      userInfo: this.$store.getters.userInfo,
      assetsData: {},
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      code: 0,
      ofo_config: null
    }
  },
  computed: {
  },
  created () {
    this.getAssets()
    this.getPaymentList()
    this.getConfig()
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    closeDialog () {
      this.dialog = false
    },
    onDeposit () {
      this.dialog = false
      setTimeout(() => {
        this.$router.push({ name: 'Deposit' })
      }, 300)
    },
    getAssets () {
      get('/asset/v1.assets/getAssets').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.assetsData = res.data
        this.$store.dispatch('setAssetsData', res.data)
      })
    },
    chooseWay (type, name) {
      this.payWayType = type
      this.payWayName = name
    },
    getPaymentList () {
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      const device = (isiOS) ? 'ios' : 'android'
      get('/asset/v1.assets/paymentList?way=vip&type=ofo&device=' + device).then(res => {
        const amount = Number(res.code)
        const newPayment = []
        if (res.data.length > 0) {
          res.data.forEach((item, index) => {
            // eslint-disable-next-line eqeqeq
            if (item.range == -1) {
              newPayment.push(item)
            // eslint-disable-next-line eqeqeq
            } else if (item.range.indexOf('-') != -1) {
              const rangeArr = item.range.split('-')
              if (
                Number(amount) >= Number(rangeArr[0]) &&
                Number(amount) <= Number(rangeArr[1])
              ) {
                newPayment.push(item)
              }
            // eslint-disable-next-line eqeqeq
            } else if (item.range.indexOf(',') != -1) {
              const rangeArr = item.range.split(',')
              rangeArr.forEach((v, i) => {
                // eslint-disable-next-line eqeqeq
                if (Number(v) == Number(amount)) {
                  newPayment.push(item)
                  return null
                }
              })
            }
          })
          this.paymentList = newPayment
        }
      })
    },
    openingVIP () {
      // eslint-disable-next-line eqeqeq
      if (this.payWayName.length == 0) {
        Toast.fail('请选择充值方式')
        return
      }
      const toast = Toast.loading('加载中', 20)
      post('/common/v1.user/openingOfoVIP', {
        pay_name: this.payWayName,
        pay_type: this.payWayType
      }).then(res => {
        toast.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          // eslint-disable-next-line eqeqeq
          if (res.code == 10056) {
            this.code = 5005
            this.dialog = true
            this.title = res.msg
            this.subtitle = ''
            this.yesBtnText = '立即充值'
          } else {
            Toast.fail(res.msg)
          }
          return
        }
        // eslint-disable-next-line eqeqeq
        if (this.payWayName == 'DOIT') {
          Toast.success('开通成功')
          this.$router.back()
        } else {
          let query = ''
          let blank = 0
          for (const i in res.data) {
            // eslint-disable-next-line eqeqeq
            if (i != 'web_view_source' && i != 'url') {
              query += i + '=' + encodeURIComponent(res.data[i]) + '&'
            }
            // eslint-disable-next-line eqeqeq
            if (i == 'xapp-target') {
              blank = 1
            }
          }
          // eslint-disable-next-line eqeqeq
          if (query != '') {
            query = query.substring(0, query.length - 1)
          }
          let resurl = res.data.url
          // eslint-disable-next-line eqeqeq
          if (resurl.indexOf('?') == -1) {
            // eslint-disable-next-line eqeqeq
            if (query != '') {
              resurl = res.data.url + '?' + query
            }
          } else {
            const last = resurl.substr(resurl.length - 1, 1)
            // eslint-disable-next-line eqeqeq
            if (last == '&') {
              resurl = res.data.url + query
            } else {
              resurl = res.data.url + '&' + query
            }
          }
          this.$router.push({ name: 'Pay2', query: { url: encodeURIComponent(resurl), blank } })
        }
      })
    },
    getConfig () {
      get('/common/v1.system/getConfig?key=ofo_config').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.ofo_config = res.data
      })
    }
  },
  setup () {
  }
}
</script>

<style lang="scss" scoped>
.open-ofo-vip{
  .warpper {
    padding:10px;
  }
  .ofo-card{
    border-radius: var(--van-card-border-radius);
    padding:10px 10px;
    background: #1F232B url('~@/assets/image/ofo_vip_bg.png') right center no-repeat;
    background-size: contain;
  }
  .top-vip-text1{
    font-size:14px;
    line-height: 30px;
  }
  .top-vip-desc{
    color:#D91A6E;
  }
  .top-vip-text2{
    color:#868A93;
    font-size:10px;
    line-height: 20px;
  }
  .top-vip-text3{
    line-height: 20px;
    margin-top:10px;
  }
  .submit-btn{
    margin-top:20px;
  }
}
</style>
