<template>
  <div class="faq">
    <van-sticky>
      <van-nav-bar title="常见问题" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-collapse v-model="activeNames" style="border-radius:15px;overflow:hidden;">
        <van-collapse-item :title="item.title" v-for="item in list" :key="item.id">
          {{item.content}}
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
export default {
  name: 'EarnTask',
  props: {
  },
  components: {

  },
  data () {
    return {
      list: [],
      activeNames: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.system/faq').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.list = res.data
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.faq {
  .wrapper {
    padding: 10px;
  }
}
</style>
