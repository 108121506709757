<template>
  <div class="team-detail">
    <van-sticky>
      <van-nav-bar title="直推用户" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
        <template v-if="list && list.length>0">
          <div class="club-item">
            <TeamItem
              v-for="item in list"
              :key="item.user_id"
              :add_time="item.add_time"
              :headpic="item.headpic"
              :nickname="item.nickname"
              :team_consume_amount="item.team_consume_amount"
              :team_financial_amount="item.team_financial_amount"
              :team_num="item.team_num"
              :username="item.username"
              />
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
    <div class="wrapper" v-if="isloading">
      <free-style-shimmer :is-loading="isloading" height="105px" width="100%" border-radius="6px" class="ofo-loading" v-for="item in 10" :key="item"  @click="getList" />
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
import TeamItem from '@/components/Common/TeamItem.vue'

export default {
  name: 'MyTeamDetail',
  components: {
    TeamItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      loading: false,
      finished: false,
      refreshing: false,
      isloading: true,
      list: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      get('/common/v1.record/getInviteLog', { page: this.page }).then(res => {
        this.loading = false
        this.isloading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    }
  },
  mounted () {
  },
  setup () {
  }
}
</script>
<style lang="scss" scoped>
.team-detail{
  .club-item{
    padding: 10px;
  }
  .wrapper{
    padding: 10px;
  }
  .ofo-loading{
    margin-bottom: 10px;
  }
  .icon{
    width:16px;
    height:16px;
  }
}
</style>
