<template>
  <div class="recommand-wrap">
    <CommonTitle title="精选楼凤" subtitle="全部" :onSubtitle="onSubtitle" />
    <div ref="wrapper">
      <ul class="cont" ref="cont">
        <li class="cont-item" v-for="item of ofoList" :key="item.id" @click="ofoPush(item.id)">
          <div class="cont-img">
            <van-image class="img" :src="imageUrl+item.avatar" lazy-load >
              <template v-slot:loading>
                <div>色影</div>
              </template>
            </van-image>
          </div>
          <div class="cont-dest">{{item.name}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BScroll from 'better-scroll'
import CommonTitle from '@/components/Common/Title.vue'
import { imageUrl } from '@/config/index.js'

export default {
  name: 'HomeOfo',
  props: {
    ofoList: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    }
  },
  components: {
    CommonTitle
  },
  data () {
    return {
      imageUrl
    }
  },
  methods: {
    ofoPush (id) {
      this.$router.push({ name: 'OfoDetail', params: { id } })
    },
    onSubtitle () {
      this.$router.push({ name: 'Ofo' })
    },
    verScroll () {
      const width = this.ofoList.length * 100// 动态计算出滚动区域的大小，前面已经说过了，产生滚动的原因是滚动区域宽度大于父盒子宽度
      this.$refs.cont.style.width = width + 'px' // 修改滚动区域的宽度
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.wrapper, {
            startX: 0, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
            click: true,
            scrollX: true,
            scrollY: false,
            eventPassthrough: 'vertical'
          })
        } else {
          this.scroll.refresh()// 如果dom结构发生改变调用该方法
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      const timer = setTimeout(() => { // 其实我也不想写这个定时器的，这相当于又嵌套了一层$nextTick，但是不这么写会失败
        if (timer) {
          clearTimeout(timer)
          this.verScroll()
        }
      }, 0)
    })
  }
}
</script>

<style lang="scss" scoped>
  .recommand-wrap {
    height: 156px;
    margin: 10px 0;
    // background: #fff;
    width: 100%;
    border-radius: var(--van-card-border-radius);
    overflow: hidden;
  }
  .cont {
    list-style: none;
    // overflow-x: scroll;
    white-space: nowrap;
    font-size: 12px;
    text-align: center;
    padding-right: 40px;
    .cont-item {
      position: relative;
      display: inline-block;
      width: 100px;
      overflow: hidden;
      .cont-img {
        overflow: hidden;
        width: 80px;
        height: 80px;
        margin:0 auto;
        .img {
          width: 100%;
          height: 100%;
          border-radius: 40px;
          overflow: hidden;
        }
      }
      .cont-dest {
        color:#fff;
        font-size: 12px;
        margin-top: 5px;
      }

    }
  }
</style>
