<template>
  <van-tag
    round
    color="#37b167"
    :class="!radius?'tag':''"
    v-if="vip==0"
  >
    免费
  </van-tag>
  <van-tag
    round
    color="#D91A6E"
    :class="!radius?'tag':''"
    v-if="vip==1 && level!=4"
  >
    VIP
  </van-tag>
  <van-tag
    round
    color="#FFB600"
    :class="!radius?'tag':''"
    v-if="vip==1 && level==4"
  >
    ￥{{price}}
  </van-tag>
</template>

<script>
export default {
  watch: {
  },
  name: 'VideoTag',
  props: {
    vip: {
      type: [Number, String]
    },
    level: {
      type: [Number, String]
    },
    price: {
      type: [Number, String]
    },
    radius: {
      type: Boolean,
      default: true
    }
  },
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.tag{
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
}
</style>
