<template>
  <van-popup :show="show" class="dialog-box" style="border-radius:10px">
    <div class="dialog-content">
      <img class="title-img" :src="require('@/assets/image/logo.png')">
      <div class="title">{{title}}</div>
      <div class="subtitle">{{subtitle}}</div>
      <div class="subtitle" v-if="code==5001">{{subtitle2}}</div>
      <div class="btn-group">
        <van-row justify="space-around">
          <van-col span="8" v-if="code==5001 || code==1000 || code==1001"><van-button type="warning" size="small" class="btn" block @click="onInvite">{{inviteBtnText}}</van-button></van-col>
          <van-col span="8" v-if="code==5005"><van-button type="primary" size="small" class="btn" block @click="onDeposit">{{yesBtnText}}</van-button></van-col>
          <van-col span="8" v-if="code!=5004 && code!=5005"><van-button type="primary" size="small" class="btn" block @click="onOpenVip">{{yesBtnText}}</van-button></van-col>
          <van-col span="8" v-if="code==5004"><van-button type="primary" size="small" class="btn" block @click="onPay">{{yesBtnText}}</van-button></van-col>
        </van-row>
        <van-row justify="space-around" v-if="code!=1000">
          <van-col span="20"><van-button type="primary" plain size="small" class="btn btn-close" @click="onClose" block>{{noBtnText}}</van-button></van-col>
        </van-row>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  watch: {
  },
  name: 'Dialog',
  props: {
    show: { type: Boolean, required: true, default: false },
    title: { type: String, default: '开通会员' },
    subtitle: { type: String, default: '享受更多特权请开通色影VIP' },
    subtitle2: { type: String, default: '邀请好友将获得3天VIP会员和享受好友消费提成' },
    logo: { type: String },
    code: { type: Number },
    yesBtnText: { type: String, default: '开通会员' },
    noBtnText: { type: String, default: '残忍拒绝' },
    inviteBtnText: { type: String, default: '立即邀请' },
    isInvite: { type: Boolean, default: false },
    onClose: { type: Function },
    onOpenVip: { type: Function },
    onPay: { type: Function },
    onDeposit: { type: Function },
    onInvite: { type: Function }
  },
  computed: {
  },
  components: {

  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.title-img{
  width:60px;
  height:60px;
}
.dialog-box{
  border-radius: var(--van-card-border-radius);
}
.dialog-content{
  margin:0 auto;
  color: #000;
  padding: 25px 10px;
  text-align: center;
  min-width: 270px;
}
.title{
  font-size:16px;
  margin-top:10px;
  font-style: bold;
}
.subtitle{
  font-size:12px;
  margin-top:10px;
  color: #666;
}
.btn-group{
  margin-top:30px;
}
.btn{
  border-radius: var(--van-card-border-radius);
}
.btn-close{
  margin-top:10px;
}
</style>
