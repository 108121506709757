<template>
  <div class="bank-add">
    <van-sticky>
      <van-nav-bar title="添加地址" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="submit" style="color:#D91A6E">提交</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="label"
            label="标签"
            name="标签"
            placeholder="标签"
            clearable
          />
          <van-field
            v-model="address"
            label="提币地址"
            name="提币地址"
            placeholder="提币地址"
            clearable
          />
        </van-cell-group>
        <div style="margin: 16px 0;">
          <van-button round block type="primary" @click="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { post } from '@/utils/request'
import { Toast } from 'vant'
export default {
  name: 'AddAddress',
  props: {
  },
  components: {
  },
  data () {
    return {
      label: '',
      address: ''
    }
  },
  created () {
  },
  methods: {
    submit () {
      // eslint-disable-next-line eqeqeq
      if (this.label == '') {
        Toast.fail('请输入标签')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.address == '') {
        Toast.fail('请输入钱包地址')
        return
      }
      const toastkey = Toast.loading('提交中', 20)
      post('/asset/v1.assets/bindWithdrawaddress', {
        currency: 'USDT',
        withdrawalAddress: this.address,
        label: this.label,
        default: 0
      }).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success('提交成功')
        setTimeout(() => {
          this.$router.push({ name: 'AddressManage' })
        }, 500)
      })
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
    })
  }
}
</script>

<style lang="scss">
.bank-add {
  .wrapper {
    padding: 10px;
  }
  .van-uploader{
    width: 130px;
    height:170px;
  }
  .van-uploader__upload{
    width: 130px;
    height:170px;
  }
  .van-uploader__preview-image{
    width: 125px;
    height:165px;
  }
  .van-uploader__preview-delete{
    width: 25px;
    height:25px;
  }
  .van-uploader__preview-delete-icon{
    font-size: 25px;
  }
}
</style>
