import { isAndroid, parseJson } from '@/utils/common'
export const storage = {}

storage.getItem = (key) => {
  let tokenData = localStorage.getItem(key)
  if (isAndroid() && window.AndroidInterface) {
    let res = window.AndroidInterface.loadDataFromFile(key)
    res = parseJson(res)
    if (res && res.data) {
      tokenData = res.data
      localStorage.setItem(key, tokenData)
    } else if (tokenData) {
      window.AndroidInterface.writeDataToFile(tokenData, key)
    }
  }
  return tokenData
}

storage.setItem = (key, data) => {
  try {
    localStorage.setItem(key, data)
    if (isAndroid() && window.AndroidInterface) {
      window.AndroidInterface.writeDataToFile(data, key)
    }
  } catch (err) {
    console.log('[error]setTokenData', err)
  }
}

storage.removeItem = (key) => {
  if (isAndroid() && window.AndroidInterface) {
    window.AndroidInterface.removeFile(key)
  }
  localStorage.removeItem(key)
}
