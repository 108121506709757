<template>
  <div class="invite">
    <van-sticky>
      <van-nav-bar title="游客凭证" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="warpper">
      <div class="qrcode">
        <qrcode-vue :value="userInfo.token" :size="150" level="H" />
      </div>
      <div class="content">
        <div class="text1">*游客凭证请妥善保管，如果遗忘，将无法找回</div>
        <div class="text2">游客凭证</div>
        <div class="text3">{{userInfo.token}}</div>
        <input ref="token" :value="userInfo.token" style="opacity:0;position:absolute;" />
        <div class="btn1"><van-button round plain type="primary" @click="copyCode">复制凭证</van-button></div>
      </div>
      <CommonTitle title="为什么要保存游客凭证？"  style="margin-top:20px;" />
      <Tips :data="lang.guesttoken_tips" v-if="lang && lang.guesttoken_tips.length>0" />
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/request'
import QrcodeVue from 'qrcode.vue'
import { Toast } from 'vant'
import Tips from '@/components/Common/Tips.vue'
import CommonTitle from '@/components/Common/Title.vue'

export default {
  name: 'Invite',
  components: {
    QrcodeVue,
    Tips,
    CommonTitle
  },
  data () {
    return {
      lang: null,
      userInfo: this.$store.getters.userInfo
    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    copyCode () {
      const text = this.userInfo.token
      const input = this.$refs.token
      input.value = text
      input.select()
      document.execCommand('copy')
      Toast.success('复制成功')
    },
    getLang () {
      get('/common/v1.system/getLang').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.lang = res.data
      })
    }
  },
  created () {
    this.getLang()
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.invite {
  .warpper {
    padding: 10px;
  }
  .qrcode{
    margin:30px auto;
    text-align: center;
    background: #fff;
    border-radius: var(--van-card-border-radius);
    padding: 10px;
    width:150px;
  }
  .content{
    text-align: center;
  }
  .btn1, .btn2{
    margin-top: 20px;
  }
  .marginBottom{
    margin-bottom: 20px;
  }
  .text1{
    color: #D91A6E
  }
  .text2{
    margin-top:10px;
    font-size:14px;
  }
  .text3{
    margin-top:10px;
    font-size:16px;
  }
  .footer{
    position: absolute;
    bottom: 20px;
    padding: 10px;
  }
}
</style>
