<template>
  <div class="ofo-add">
    <van-sticky>
      <van-nav-bar title="发布楼凤信息" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="submit" style="color:#D91A6E">提交</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-form @submit="onSubmit">
        <CommonTitle title="基本信息" />
        <van-cell-group inset>
          <van-field
            v-model="name"
            label="标题"
            name="标题"
            placeholder="标题"
            clearable
          />
          <van-field
            v-model="typeName"
            is-link
            readonly
            name="type"
            label="类型"
            placeholder="点击选择类型"
            @click="showType = true"
          />
          <van-popup v-model:show="showType" position="bottom">
            <van-picker
              :columns="typeArr"
              @confirm="onTypeConfirm"
              @cancel="showType = false"
            />
          </van-popup>
          <van-field
            v-model="city"
            is-link
            readonly
            name="city"
            label="所在城市"
            placeholder="点击选择城市"
            @click="$router.push({name:'CityPicker'})"
          />
        </van-cell-group>
        <CommonTitle title="妹子信息" />
        <van-cell-group inset>
          <van-field
            v-model="num"
            label="妹子数量"
            name="妹子数量"
            placeholder="3~20人"
            clearable
          />
          <van-field
            v-model="age"
            label="妹子年龄"
            name="妹子年龄"
            placeholder="20~24岁"
            clearable
          />
          <van-field name="quality" label="妹子颜值">
            <template #input>
              <van-rate v-model="quality" allow-half />
            </template>
          </van-field>
        </van-cell-group>
        <CommonTitle title="相关服务" />
        <van-cell-group inset>
          <van-field name="service" label="服务质量">
            <template #input>
              <van-rate v-model="service" allow-half />
            </template>
          </van-field>
          <van-field
            v-model="services"
            label="服务项目"
            name="服务项目"
            placeholder="舌吻、毒龙、胸推、莞式一条龙"
            clearable
          />
        </van-cell-group>
        <CommonTitle title="场所信息" />
        <van-cell-group inset>
          <van-field
            v-model="work_time"
            label="营业时间"
            name="营业时间"
            placeholder="早9点～晚5点"
            clearable
          />
          <van-field
            v-model="monetary"
            label="消费情况"
            name="消费情况"
            placeholder="1p500 2p800 包夜1600"
            clearable
          />
          <van-field name="ambient" label="环境设备">
            <template #input>
              <van-rate v-model="ambient" allow-half />
            </template>
          </van-field>
          <van-field
            v-model="introduce"
            rows="5"
            autosize
            label="详细描述"
            type="textarea"
            maxlength="120"
            placeholder="请阐述真实过程，字数不得少于30字。字数越多，图片质量越好，管理员审核后所得到的奖励越丰厚。"
            clearable
            show-word-limit
          />
        </van-cell-group>
        <CommonTitle title="联系方式" />
        <van-cell-group inset>
          <van-field name="contact_type" label="联系类型">
            <template #input>
              <van-radio-group v-model="contact_type" direction="horizontal" @change="changeContactType">
                <van-radio name="0">{{contactArr[0]}}</van-radio>
                <van-radio name="1">{{contactArr[1]}}</van-radio>
                <van-radio name="2">{{contactArr[2]}}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="contact"
            label="联系方式"
            name="联系方式"
            clearable
            :placeholder="contact_placeholder"
          />
          <van-field
            v-model="address"
            label="详细地址"
            name="详细地址"
            clearable
            placeholder="什么区，什么地铁附近，几号房等等"
          />
        </van-cell-group>
        <CommonTitle title="真实照片信息" />
        <div><span style="color:#F0B90B">务必按要求上传图片（可以只传一张封面）</span>，审核通过后图片信息将公开，请自行打码，如果上传图片一直加载中，请在网络顺畅的时候操作。</div>
        <div style="margin:10px 0;">
          <van-row>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="aroundImage" :after-read="addAround" :before-delete="delAround" />
              <div>封面图片</div>
              <div>(展示最美一面)</div>
            </van-col>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1"  v-model="innerImage" :after-read="addInner" :before-delete="delInner" />
              <div>全身图片</div>
              <div>(由头到脚的全身，正面)</div>
            </van-col>
          </van-row>
        </div>
        <div style="margin:10px 0;">
          <van-row>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="girlImage" :after-read="addGirl" :before-delete="delGirl" />
              <div>焦点图片</div>
              <div>(突出个人特点，如大胸)</div>
            </van-col>
            <van-col span="10" offset="2">
              <van-uploader class="uploader" :max-count="1" v-model="selfImage" :after-read="addSelf" :before-delete="delSelf" />
              <div>其他图片</div>
              <div>(来，秀起来吧)</div>
            </van-col>
          </van-row>
        </div>
        <div style="margin: 16px 0;">
          <van-button round block type="primary" @click="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import { post } from '@/utils/request'
import { Toast } from 'vant'
import CommonTitle from '@/components/Common/Title.vue'
import { imageUrl } from '@/config/index.js'
export default {
  name: 'AddOfo',
  props: {
  },
  components: {
    CommonTitle
  },
  data () {
    return {
      name: '',
      typeName: '楼凤兼职',
      type: 0,
      showType: false,
      typeArr: ['楼凤兼职', '洗浴桑拿', '路边小店'],
      city: '',
      adcode: '',
      num: '',
      age: '',
      quality: 0,
      service: 0,
      services: '',
      work_time: '',
      monetary: '',
      ambient: 0,
      introduce: '',
      contactArr: ['微信', '手机', 'QQ'],
      contact_type: '0',
      contact: '',
      address: '',
      around: '',
      aroundImage: [],
      inner: '',
      innerImage: [],
      girl: '',
      girlImage: [],
      self: '',
      selfImage: [],
      imageUrl
    }
  },
  computed: {
    contact_placeholder: (data) => {
      return '请输入您的' + data.contactArr[data.contact_type]
    }
  },
  methods: {
    changeContactType () {
      this.contact = ''
    },
    onTypeConfirm (value, index) {
      this.typeName = value
      this.type = index
      this.showType = false
    },
    addAround (file) {
      this.uploadImg(file, 'around')
    },
    delAround () {
      this.aroundImage = []
    },
    addInner (file) {
      this.uploadImg(file, 'inner')
    },
    delInner () {
      this.innerImage = []
    },
    addGirl (file) {
      this.uploadImg(file, 'girl')
    },
    delGirl () {
      this.girlImage = []
    },
    addSelf (file) {
      this.uploadImg(file, 'self')
    },
    delSelf () {
      this.selfImage = []
    },
    uploadImg (file, type) {
      file.status = 'uploading'
      file.message = '上传中...'
      post('/common/v1.user/uploadImge', {
        imageType: type,
        base64_image: file.content
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          file.status = 'failed'
          file.message = res.msg
          return
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'around') {
          this.aroundImage = []
          this.aroundImage.push({ url: imageUrl + res.data.imgUrl })
          this.around = res.data.imgUrl
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'inner') {
          this.innerImage = []
          this.innerImage.push({ url: imageUrl + res.data.imgUrl })
          this.inner = res.data.imgUrl
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'girl') {
          this.girlImage = []
          this.girlImage.push({ url: imageUrl + res.data.imgUrl })
          this.girl = res.data.imgUrl
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'self') {
          this.selfImage = []
          this.selfImage.push({ url: imageUrl + res.data.imgUrl })
          this.self = res.data.imgUrl
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('上传失败')
        // eslint-disable-next-line eqeqeq
        if (type == 'around') {
          this.aroundImage = []
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'inner') {
          this.innerImage = []
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'girl') {
          this.girlImage = []
        }
        // eslint-disable-next-line eqeqeq
        if (type == 'self') {
          this.selfImage = []
        }
      })
    },
    submit () {
      // eslint-disable-next-line eqeqeq
      if (this.name == '') {
        Toast.fail('请输入标题')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.type === '') {
        Toast.fail('请选择类型')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.adcode == '') {
        Toast.fail('请选择所在城市')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.num == '') {
        Toast.fail('请输入妹子数量')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.age == '') {
        Toast.fail('请输入妹子年龄')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.quality == '') {
        Toast.fail('请给妹子颜值打分')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.service == '') {
        Toast.fail('请给服务质量打分')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.services == '') {
        Toast.fail('请输入服务项目')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.work_time == '') {
        Toast.fail('请输入营业时间')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.monetary == '') {
        Toast.fail('请输入消费情况')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.ambient == '') {
        Toast.fail('请给环境设备打分')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.introduce == '') {
        Toast.fail('请输入详细描述')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.address == '') {
        Toast.fail('请输入详细地址')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.contact == '') {
        Toast.fail('请输入联系方式')
        return
      }
      // eslint-disable-next-line eqeqeq
      if (this.around == '') {
        Toast.fail('请上传封面图片')
        return
      }
      const toastkey = Toast.loading('提交中', 20)
      post('/common/v1.ofo/add', {
        name: this.name,
        type: this.typeName,
        adcode: this.adcode,
        city: this.city,
        num: this.num,
        age: this.age,
        quality: this.quality,
        service: this.service,
        services: this.services,
        work_time: this.work_time,
        monetary: this.monetary,
        ambient: this.ambient,
        introduce: this.introduce,
        address: this.address,
        contact_type: this.contact_type,
        contact: this.contact,
        image_front: this.around,
        image_body: this.inner,
        image_focus: this.girl,
        video_selfie: '',
        video_selfie_img: this.self
      }).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success('提交成功')
        setTimeout(() => {
          this.$router.push({ name: 'MyOfo' })
        }, 500)
      })
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
    })
  }
}
</script>

<style lang="scss">
.ofo-add {
  .wrapper {
    padding: 10px;
  }
  .van-uploader{
    width: 130px;
    height:170px;
  }
  .van-uploader__upload{
    width: 130px;
    height:170px;
    border-radius: 15px;
    overflow: hidden;
  }
  .van-uploader__preview-image{
    width: 125px;
    height:165px;
    border-radius: 15px;
    overflow: hidden;
  }
  .van-uploader__preview-delete{
    width: 25px;
    height:25px;
    border-top-right-radius: 15px;
  }
  .van-uploader__preview-delete-icon{
    font-size: 25px;
  }
}
</style>
