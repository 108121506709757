<template>
  <div class="video-item" :style="{width:itemWidth+'%'}" v-if="display">
    <van-image :src="vod_pic" width="100%" :height="height+'px'" lazy-load  @click="push" fit="contain" class="video-image">
      <template v-slot:error>加载失败</template>
      <template v-slot:loading>
        <div>色影</div>
      </template>
      <div class="vip-tag"><VideoTag :vip="vip" :level="level" :price="price" :radius="false" /></div>
      <van-tag
        round
        class="watch-tag"
      >
        <font-awesome-icon :icon="['fas', 'eye']" class="icon" />&nbsp;{{vod_watch}}
      </van-tag>
    </van-image>
    <div class="vod-name"  @click="push">{{vod_name}}</div>
    <font-awesome-icon :icon="['fas', 'times-circle']" class="close-icon" @click="onDelete(vod_id, $event)" v-if="isDelete" />
  </div>
</template>

<script>
import VideoTag from '@/components/Video/Tag.vue'
export default {
  name: 'VideoItem',
  props: {
    height: { type: Number, default: 85 },
    vod_id: { type: Number, required: true, default: 0 },
    vod_pic: { type: String, required: true, default: null },
    vod_name: { type: String, required: true, default: '' },
    vip: { type: Number, default: 0 },
    level: { type: Number, default: 0 },
    vod_watch: { type: Number, default: 0 },
    vod_up: { type: Number, default: 0 },
    vod_store: { type: Number, default: 0 },
    layout: { type: String, default: 'column' },
    numColumns: { type: Number, default: 2 },
    price: { type: Number, default: 30 },
    display: { type: Boolean, default: true },
    isDelete: { type: Boolean, default: false },
    onDelete: { type: Function }
  },
  components: {
    VideoTag
  },
  computed: {
    itemWidth: (props) => {
      // eslint-disable-next-line eqeqeq
      if (props.numColumns == 1) {
        return 100
      } else {
        return Number(98 / Number(props.numColumns))
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    push () {
      this.$router.push({ name: 'VideoDetail', params: { id: this.vod_id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.video-item {
  overflow: hidden;
  border-radius: var(--van-card-border-radius);
  background: #1F232B;
  margin-bottom:8px;
  padding:0;
  position: relative;
  .video-image{
    background: #000;
  }
  .icon{
    width: 14px;
    height:14px;
  }
  .close-icon{
    position:absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    color: rgba(0,0,0,0.5);
  }
  .vod_pic{
    width:100%;
    height:100px;
  }
  .vod-name{
    font-size:13px;
    height: 32px;
    line-height: 18px;
    overflow: hidden;
    color: #fff;
    padding: 0px 5px 2px;
  }
  .watch-tag{
    background: rgba(0,0,0,.5);
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
  .vip-tag{
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
  }
}
</style>
