const black = {
  dangerColor: '#D91A6E',
  buttonPrimaryColor: '#FFFFFF',
  buttonPrimaryBackgroundColor: '#D91A6E',
  buttonDangerBackgroundColor: '#D91A6E',
  buttonWarningColor: '#161A22',
  buttonWarningBackgroundColor: '#F0B90B',
  buttonBorderWidth: '0',
  navBarBackgroundColor: '#161A22',
  navBarArrowSize: '24px',
  navBarIconColor: '#FFFFFF',
  navBarTitleTextColor: '#FFFFFF',
  navBarTitleFontSize: '1rem',
  tabbarBackgroundColor: '#FFFFFF',
  tabbarItemActiveBackgroundColor: '#FFFFFF',
  tabbarItemFontSize: '12px',
  tabbarItemTextColor: '#868A93',
  tabsNavBackgroundColor: '#161A22', // #161A22
  tabActiveTextColor: '#FFFFFF',
  tabTextColor: '#868A93',
  tabFontSize: '1rem',
  searchBackgroundColor: '#161A22', // #161A22
  searchContentBackgroundColor: '#1F232B',
  searchActionColor: '#FFFFFF',
  searchLeftIconColor: '#FFFFFF',
  fieldInputTextColor: '#000000',
  rateIconSize: '12px',
  dividerBorderColor: '#202531',
  activeColor: '#fff0',
  cellGroupInsetPadding: '0px',
  indexAnchorStickyBackgroundColor: '#1F232B',
  cardBorderRadius: '6px',
  rateIconFullColor: '#D91A6E',
  tabsBottomBarColor: '#F0B90B',
  radioCheckedIconColor: '#D91A6E',
  tagPrimaryColor: '#D91A6E',
  cellGroupInsetBorderRadius: '6px',
  actionBarButtonDangerColor: '#D91A6E'
}
export { black }
