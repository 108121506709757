<template>
  <div class="maintenance">
    <div class='bg'>
      <div class="section">
        <h1>系统维护</h1>
        <h6>系统维护，请耐心等待，维护时间将持续1个小时，对您带来的不便，我们深感抱歉！谢谢！ </h6>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped lang="scss">
.bg{
  background: url("../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position:fixed;
}
.section{
  padding: 20px;
  position: absolute;
  bottom:100px;
}
.login-btn{
  margin-top:70px;
}
.reg-btn{
  margin-top:10px;
}
</style>
