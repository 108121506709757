<template>
  <div class="video-detail">
    <div class="back" @click="onClickLeft"><font-awesome-icon :icon="['fas', 'chevron-left']" class="back-icon" /></div>
    <div class="poster" v-if="!inited">
      <van-image class="video-image" :src="vodData.vod_pic"  fit="contain" />
      <font-awesome-icon :icon="['fas', 'play-circle']" @click="watch" class="watch-btn" />
      <div class="tip">
        <div>“长按屏幕左右滑动”来操作快进快退</div>
        <van-image width="50px" height="50px" :src="require('../../assets/icon/finger-slide.png')" />
      </div>
    </div>
    <div class="video-image" v-if="inited">
      <Artplayer
        @get-instance="getInstance"
        :option="option"
        class="video-image"
      />
    </div>
    <div class="content">
      <div class='wrapper'>
        <div class="title">
          <VideoTag :vip="vodData.is_vip" :level="vodData.level" :price="vodData.price" class="tag"/>
          <span class="title-name">{{vodData.vod_name}}</span>
        </div>
        <div class="subtitle">
          <van-row justify="space-between">
            <van-col span="16">
              <div>{{vodData.vod_watch}}次播放 • {{vodData.vod_last_time}}</div>
            </van-col>
            <van-col span="8">
              <div class="subtitle-comment">
                <van-button type="primary" round size="mini" @click="$router.push({ name: 'Comments', params: { type:'video', id: vodData.vod_id } })">我要评论</van-button>
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="type-list" v-if="vodData.vod_type_list && vodData.vod_type_list.length>0">
          <ul class="type-list-ul" v-for="item in vodData.vod_type_list" :key="item.type_id">
            <li class="type-list-li" @click="$router.push({ name: 'VideoSearch', params: { label:item.type_name } })">
              <van-tag color="#01dc92" round size="medium">#{{item.type_name}}</van-tag>
            </li>
          </ul>
        </div>
        <div class="label-list" v-if="vodData.vod_label_list && vodData.vod_label_list.length>0">
          <ul class="label-list-ul" v-for="item in vodData.vod_label_list" :key="item.label_id">
            <li class="label-list-li" style="margin-bottom:5px;" @click="$router.push({ name: 'VideoSearch', params: { label:item.label_name } })">
              <van-tag color="#F0B90B" round size="medium">{{item.label_name}}</van-tag>
            </li>
          </ul>
        </div>
        <div class="relate">
          <CommonTitle title="相关视频" />
          <div class="relate-list">
            <template v-if="vodData.relate && vodData.relate.length>0">
              <div class="videos">
                <VideoItem
                  v-for="item in vodData.relate" :key="item.vod_id"
                  :vod_id="item.vod_id"
                  :vod_pic="item.vod_pic"
                  :vod_name="item.vod_name"
                  :vod_watch="item.vod_watch"
                  :vip="item.is_vip"
                  :level="item.level"
                  :price="item.price"
                  :jwidth="4"
                  :imageRate="16 / 9"
                  :numColumns="2"
                  class="item"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <van-action-bar>
      <van-action-bar-icon :style="{'min-width':minWidth}" icon="chat-o" :text="String(vodData.vod_up)" @click="videohandleDo('vod_up')">
        <template #icon>
          <font-awesome-icon :icon="['fas', 'thumbs-up']" class="footer-nav-icon" :style="{'color':vodData.is_vod_up ? '#D91A6E':'#868A93'}" />
        </template>
      </van-action-bar-icon>
      <van-action-bar-icon :style="{'min-width':minWidth}" icon="cart-o" :text="String(vodData.vod_store)" @click="videohandleDo('vod_store')" >
        <template #icon>
          <font-awesome-icon :icon="['fas', 'heart']" class="footer-nav-icon" :style="{'color':vodData.is_vod_store ? '#D91A6E':'#868A93'}" />
        </template>
      </van-action-bar-icon>
      <van-action-bar-icon :style="{'min-width':minWidth}" icon="shop-o" :text="String(vodData.vod_share)" @click="videohandleDo('vod_share')" >
        <template #icon>
          <font-awesome-icon :icon="['fas', 'share-alt']" class="footer-nav-icon" :style="{'color':vodData.is_vod_share? '#D91A6E':'#868A93'}"/>
        </template>
      </van-action-bar-icon>
      <van-action-bar-button type="danger" v-if="btnShow" :text="vipBtn" @click="onOpenVip"/>
    </van-action-bar>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :code="code"
    :onClose="closeDialog"
    :onOpenVip="onOpenVip"
    :onPay="onPay"
    :onDeposit="onDeposit"
    :onInvite="onInvite"
  />
</template>

<script>
import { post } from '@/utils/request'
import CommonTitle from '@/components/Common/Title.vue'
import Dialog from '@/components/Common/Dialog.vue'
import VideoItem from '@/components/Video/Item.vue'
import VideoTag from '@/components/Video/Tag.vue'
import { Toast } from 'vant'
import Artplayer from '@/components/Video/Artplayer.vue'
// import Hls from 'hls.js'

export default {
  name: 'VideoDetail',
  components: {
    CommonTitle,
    VideoItem,
    Dialog,
    VideoTag,
    Artplayer
  },
  props: {
    id: { type: String, required: true }
  },
  data () {
    return {
      vodData: {},
      inited: false,
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      code: 0,
      playerOptions: {},
      watch5MinTimer: null,
      userInfo: this.$store.getters.userInfo,
      option: {
        url: '',
        poster: '',
        autoplay: true,
        muted: true,
        flip: true,
        playbackRate: true,
        aspectRatio: true,
        screenshot: true,
        setting: true,
        fullscreen: true,
        fullscreenWeb: true,
        miniProgressBar: true,
        whitelist: ['*'],
        lang: 'zh-cn',
        moreVideoAttr: {
          'webkit-playsinline': true,
          playsInline: true
          // crossOrigin: 'anonymous'
        }
        // customType: {
        //   m3u8: function (video, url) {
        //     var hls = new Hls()
        //     hls.loadSource(url)
        //     hls.attachMedia(video)
        //   }
        // }
      },
      style: {
        height: '240px'
      }
    }
  },
  computed: {
    vipBtn: (data) => {
      let btnStr = ''
      switch (parseInt(data.userInfo.vip_level)) {
        case 1:
        case 2:
        case 3:
          btnStr = '续费会员'
          break
        default:
          btnStr = '开通会员'
          break
      }
      return btnStr
    },
    minWidth: (data) => {
      let width = '80px'
      if (parseInt(data.userInfo.vip_level) === 3 && parseInt(data.userInfo.vip_deadline_type) === 3) {
        width = '33.33%'
      }
      return width
    },
    btnShow: (data) => {
      if (parseInt(data.userInfo.vip_level) === 3 && parseInt(data.userInfo.vip_deadline_type) === 3) {
        return false
      }
      return true
    }
  },
  created () {
    this.getVideoinfo()
  },
  methods: {
    getInstance (art) {
      art.on('ready', () => {
        // 5分钟观影奖励
        this.watch5MinTimer = setTimeout(() => {
          this.taskFinish()
        }, 1000 * 60 * 5)
      })
    },
    onClickLeft () {
      this.$router.back()
    },
    jumpPage (id) {
      // eslint-disable-next-line vue/no-mutating-props
      this.$router.replace({ name: 'VideoDetail', params: { id } })
    },
    closeDialog () {
      this.dialog = false
    },
    getVideoinfo () {
      post('/common/v1.video/info', { vod_id: this.id }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.option.poster = res.data.vod_pic
        this.vodData = res.data
      })
    },
    videohandleDo (type) {
      const loading = Toast.loading('请求中', 20)
      post('/common/v1.videohandle/do', {
        vod_id: this.id,
        type
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.type == 'vod_up') {
          this.vodData.is_vod_up = res.data.status
          this.vodData.vod_up = res.data.num
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.type == 'vod_store') {
          this.vodData.is_vod_store = res.data.status
          this.vodData.vod_store = res.data.num
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.type == 'vod_share') {
          this.vodData.is_vod_share = res.data.status
          this.vodData.vod_share = res.data.num
        }
      })
    },
    watch () {
      const loading = Toast.loading('加载中', 20)
      post('/common/v1.video/watch3', { vod_id: this.id }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code == 0) {
          // eslint-disable-next-line eqeqeq
          if (res.data.surplus_watch_times != '-1') {
            Toast.success('今日已观影' + res.data.watch_times + '次，剩余' + res.data.surplus_watch_times + '次')
          }
          this.option.url = res.data.vod_play_url
          this.option.title = this.vodData.vod_name
          this.inited = true
        // eslint-disable-next-line eqeqeq
        } else if (res.code == 5001 || res.code == 5002 || res.code == 5003) {
          this.dialog = true
          this.code = res.code
          // eslint-disable-next-line eqeqeq
          if (res.code == 5001) {
            this.title = '开通VIP会员'
            this.yesBtnText = '开通VIP会员'
          // eslint-disable-next-line eqeqeq
          } else if (res.code == 5002) {
            this.title = '开通VIP会员'
            this.yesBtnText = '开通VIP会员'
          // eslint-disable-next-line eqeqeq
          } else if (res.code == 5003) {
            this.title = '开通VIP会员'
            this.yesBtnText = '开通VIP会员'
          }
        // eslint-disable-next-line eqeqeq
        } else if (res.code == 5004) {
          this.code = res.code
          this.dialog = true
          this.title = res.msg
          this.subtitle = res.data.subtitle
          this.yesBtnText = '付费观看'
        } else {
          Toast.fail('系统繁忙，请稍后重试', 2)
        }
      })
    },
    taskFinish () {
      post('/common/v1.user/taskFinish', {
        task_name: 'watch_video_5minute'
      })
    },
    onOpenVip () {
      this.$router.push({ name: 'OpenVip' })
    },
    onDeposit () {
      this.$router.push({ name: 'Deposit' })
    },
    onInvite () {
      this.$router.push({ name: 'Invite' })
    },
    onPay () {
      post('/common/v1.video/pay', {
        vod_id: this.id
      }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code == 0) {
          this.dialog = false
          this.watch()
        } else {
          this.code = 5005
          this.title = res.msg
          this.subtitle = '请及时充值，付费继续观看'
          this.yesBtnText = '立即充值'
        }
      })
    }
  },
  setup () {
  },
  beforeRouteLeave () {
    clearTimeout(this.watch5MinTimer)
  }
}
</script>
<style lang="scss">
.video-detail{
  position: relative;
  .back {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
    z-index: 999;
  }
  .back-icon{
    width: 25px;
    height: 25px;
    color: #fff;
  }
  .poster{
    width: 100%;
    height: 240px;
    position: relative;
  }
  .tip{
    position: absolute;
    top: 150px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 20;
    width: 200px;
    height: 60px;
    text-align: center;
  }
  .watch-btn{
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    margin:auto;
    z-index: 20;
    width:60px;
    height:60px;
  }
  .title{
    color: #fff;
    font-size:14px;
    .title-name{
      line-height: 24px;
      margin-left: 5px;
    }
  }
  .content{
    width: 100%;
    margin-bottom: 80px;
  }
  .wrapper{
    padding: 10px;
  }
  .subtitle{
    margin-top:10px;
    .subtitle-comment{
      text-align: right;
    }
  }

  .type-list{
    width:100%;
    display:inline-block;
    margin-top:10px;
    .type-list-ul{
      width:100%;
      .type-list-li{
        float: left;
        margin-right:5px;
      }
    }
  }
  .label-list{
    width:100%;
    display:inline-block;
    margin-top:10px;
    .label-list-ul{
      width:100%;
      .label-list-li{
        float: left;
        margin-right:5px;
      }
    }
  }
  .videos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  .item{
    width: 100%;
    border-radius: var(--van-card-border-radius);
    overflow: hidden;
  }
  .footer{
    position: fixed;
    bottom: 0;
  }
  .footer-icon {
    min-width: 80px;
  }
  .vjs-big-play-button{
    display: none !important;
  }
  .video-image{
    width: 100%;
    height: 240px;
    background: #000;
  }
}
</style>
