<template>
  <div class="deposit-usdt">
    <van-sticky>
      <van-nav-bar title="充币" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
        <template #right>
          <div @click="$router.push({ name: 'DepositRecord', params:{type:'USDT'} })">充币记录</div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="warpper">
      <div class="content">
        <div class="text1">扫一扫向我充币</div>
        <div class="qrcode">
          <qrcode-vue :value="address" :size="150" level="H" />
        </div>
        <div class="text2">收款地址</div>
        <div class="text3">{{address}}</div>
        <input ref="inputAddress" :value="address" style="opacity:0;position:absolute;" />
        <div class="btn2 marginBottom"><van-button round  type="primary" @click="copyAddress">复制地址</van-button></div>
      </div>
      <Tips :data="lang.rechargeusdt_tips" v-if="lang && lang.rechargeusdt_tips.length>0" style="margin-top:20px;" />
    </div>
  </div>
</template>

<script>
import { get, post } from '@/utils/request'
import QrcodeVue from 'qrcode.vue'
import { Toast } from 'vant'
import Tips from '@/components/Common/Tips.vue'

export default {
  name: 'DepositUSDT',
  components: {
    QrcodeVue,
    Tips
  },
  data () {
    return {
      lang: null,
      address: ''
    }
  },
  methods: {
    copyAddress () {
      const text = this.address
      const input = this.$refs.inputAddress
      input.value = text
      input.select()
      document.execCommand('copy')
      Toast.success('复制成功')
    },
    getLang () {
      get('/common/v1.system/getLang').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.lang = res.data
      })
    },
    getDepositaddress () {
      post('/asset/v1.assets/getDepositaddress',
        {
          currency: 'USDT'
        }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data && res.data.address) {
          this.address = res.data.address
        }
      })
    }
  },
  created () {
    this.getLang()
    this.getDepositaddress()
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.deposit-usdt {
  .warpper {
    padding: 10px;
  }
  .qrcode{
    margin:30px auto;
    text-align: center;
    background: #fff;
    border-radius: var(--van-card-border-radius);
    padding: 10px;
    width:150px;
  }
  .content{
    text-align: center;
  }
  .btn1, .btn2{
    margin-top: 20px;
  }
  .marginBottom{
    margin-bottom: 20px;
  }
  .text2{
    margin-top:10px;
    font-size:14px;
  }
  .text3{
    margin-top:10px;
    font-size:16px;
  }
  .footer{
    position: absolute;
    bottom: 20px;
    padding: 10px;
  }
}
</style>
