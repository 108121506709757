<template>
  <div class="club-index">
    <van-sticky>
      <van-nav-bar title="红灯区" :border="false">
        <template #left>
          <div @click="$router.push({name:'CityPicker'})">
            <van-icon name="location" size="18" />
            {{this.city}}
          </div>
        </template>
        <template #right>
          <font-awesome-icon :icon="['fas', 'search']" class="icon" @click="$router.push({name:'ClubSearch'})" />
        </template>
      </van-nav-bar>
      <van-tabs v-model:active="active" @click-tab="changeTab">
        <van-tab v-for="(item, index) in types" :title="item.name" :key="item.key" :name="index">
        </van-tab>
      </van-tabs>
    </van-sticky>
    <div class="overlay-wrapper"  v-if="false">
      <div class="block">
        <div class="overlay-title">{{textData && textData.blur_club_title}}</div>
        <div class="overlay-content">{{textData && textData.blur_club_subtitle}}</div>
        <div><van-button round type="primary" block style="margin-top:20px" @click="$router.push({name: 'OpenVip'})">开通VIP</van-button></div>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
        <template v-if="list && list.length>0">
          <div class="club-item">
            <ClubItem
              v-for="item in list"
              :key="item.id"
              :id="item.id"
              :img="item.img"
              :name="item.name"
              :ambient="item.ambient"
              :quality="item.quality"
              :address="item.address"
              :follow="item.follow"
              />
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
    <div class="wrapper" v-if="isloading">
      <free-style-shimmer :is-loading="isloading" height="50px " width="100%" border-radius="6px" class="ofo-loading" @click="getTypes" />
      <free-style-shimmer :is-loading="isloading" height="105px" width="100%" border-radius="6px" class="ofo-loading" v-for="item in 5" :key="item"  @click="getList" />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Bus from 'vue3-bus'
import { get, post } from '@/utils/request'
import ClubItem from '@/components/Club/Item.vue'

export default {
  name: 'ClubIndex',
  components: {
    ClubItem
  },
  data () {
    return {
      show: true,
      page: 1,
      total: 0,
      keyword: '',
      adcode: '110100',
      city: '北京',
      loading: false,
      finished: false,
      refreshing: false,
      isloading: true,
      list: [],
      selectedIndex: 0,
      types: [{
        key: 'follow',
        name: '热门',
        sort: 'desc',
        type: '1'
      }],
      userInfo: this.$store.getters.userInfo,
      textData: null
    }
  },
  created () {
    this.location()
    this.getTypes()
    this.getList()
    this.getText()
  },
  methods: {
    changeTab (data) {
      this.selectedIndex = data.name
      this.onRefresh()
    },
    reload () {
      this.$router.go(0)
    },
    location () {
      const location = this.$store.getters.location || {}
      if (location && location.id) {
        this.adcode = location.id
        this.city = location.city
      }
    },
    getText () {
      get('/common/v1.system/getText').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data) {
          this.textData = res.data
        }
      })
    },
    getTypes () {
      get('/common/v1.system/getClubType').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.types = res.data
      })
    },
    getList () {
      get('/common/v1.club/index', {
        page: this.page,
        sort: this.types[this.selectedIndex].key,
        order: this.types[this.selectedIndex].sort,
        type: this.types[this.selectedIndex].type,
        keyword: '',
        adcode: this.adcode,
        // eslint-disable-next-line eqeqeq
        is_vip: this.selectedIndex == 3 ? 1 : 0
      }).then(res => {
        this.loading = false
        this.isloading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    getUserInfo () {
      post('/common/v1.user/getUserInfo').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.userInfo = res.data
        this.$store.dispatch('setUserInfo', res.data)
      })
    }
  },
  mounted () {
    // let self = this
    Bus.on('location', (data) => {
      this.adcode = data.id
      this.city = data.city
      this.onRefresh()
    })
  },
  setup () {
    const active = ref(0)
    return { active }
  },
  watch: {
    $route (to, from) {
      // eslint-disable-next-line eqeqeq
      if (from.name == 'OpenVip') {
        this.getUserInfo()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.club-index{
  .club-item{
    padding: 10px;
  }
  .wrapper{
    padding: 10px;
  }
  .ofo-loading{
    margin-bottom: 10px;
  }
  .icon{
    width:16px;
    height:16px;
  }
}
.overlay-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.block {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.overlay-title{
  display: inline-block;
  font-size:18px;
}
.overlay-content{
  display: inline-block;
  font-size:14px;
  margin-top:10px;
}

</style>
