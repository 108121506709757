<template>
  <div class="club" @click="push">
    <div class="section">
      <div class="wrapper">
        <van-row>
          <van-col span="7" class="left-item">
            <div class="left-image">
              <van-image width="88px" height="88px" :src="imageUrl+img" lazy-load>
                <template v-slot:loading>
                  <div>色影</div>
                </template>
              </van-image>
            </div>
          </van-col>
          <van-col span="17" class="right-item">
            <div class="title">{{name}}</div>
            <div><span class="label">装修环境：</span><van-rate v-model="ambientData" readonly allow-half /></div>
            <div><span class="label">技师素质：</span><van-rate v-model="qualityData" readonly allow-half /></div>
            <div class="text-2line"><span class="label">地址：</span>{{address}}</div>
            <div><span class="label">累计关注数：</span>{{follow}}</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <font-awesome-icon :icon="['fas', 'times-circle']" class="close-icon" @click="onDelete(id, $event)" v-if="isDelete" />
  </div>
</template>
<script>
import { imageUrl } from '@/config/index.js'

export default {
  name: 'ClubItem',
  props: {
    id: { type: Number },
    img: { type: String },
    name: { type: String },
    ambient: { type: Number },
    quality: { type: Number },
    address: { type: String },
    follow: { type: Number },
    isDelete: { type: Boolean, default: false },
    onDelete: { type: Function }
  },
  components: {

  },
  data () {
    return {
      ambientData: parseFloat(this.ambient),
      qualityData: parseFloat(this.quality),
      imageUrl
    }
  },
  methods: {
    push () {
      this.$router.push({ name: 'ClubDetail', params: { id: this.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.club{
  width:100%;
  position: relative;
  .section{
    width: 100%;
    margin-bottom: 10px;
  }
  .close-icon{
    position:absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    color: rgba(0,0,0,0.5);
  }
  .wrapper{
    background:#1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .right-item{
    text-align: left;
    padding:10px;
  }
  .left-item{
    display: flex;
  }
  .left-image{
    align-self: center;
    margin: 0 auto;
  }
  .text-2line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .title{
    font-size:16px;
  }
  .label{
    color:#868A93;
  }
}
</style>
