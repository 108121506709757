<template>
  <div class="home">
    <van-row>
      <van-col span="3" class="logo-col">
        <van-image width="50" height="15" :src="require('@/assets/image/logo_text.png')" class="logo" />
      </van-col>
      <van-col span="21" @click="$router.push({name:'VideoSearch'})">
        <van-search
          shape="round"
          placeholder="点击搜索视频"
        >
        </van-search>
      </van-col>
    </van-row>
    <van-tabs v-model:active="active" swipeable sticky v-if="tabs.length>0">
      <van-tab v-for="(tab) in tabs" :title="tab.name" :key="tab.id">
        <Recommend v-if="tab.id===0" />
        <TabTopic :tabid="tab.id" :name="tab.name" v-if="tab.id!==0" />
      </van-tab>
    </van-tabs>
  </div>
  <Dialog
    :show="dialog"
    :title="title"
    :subtitle="subtitle"
    :yesBtnText="yesBtnText"
    :noBtnText="cancelBtnText"
    :code="code"
    :onOpenVip="onDeposit"
    :onClose="closeDialog"
  ></Dialog>
</template>

<script>
import { post } from '@/utils/request'
import Recommend from '@/components/Home/Recommend.vue'
import Dialog from '@/components/Common/Dialog.vue'
import TabTopic from '@/components/Home/TabTopic.vue'

export default {
  name: 'Home',
  components: {
    Recommend,
    TabTopic,
    Dialog
  },
  data () {
    return {
      tabs: [],
      dialog: false,
      title: '',
      subtitle: '',
      yesBtnText: '',
      cancelBtnText: '',
      dialogKey: ''
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    onDeposit () {
      setTimeout(() => {
        if (this.dialogKey === 'BINDPHONE') {
          this.$router.push({ name: 'BindPhone' })
        }
        if (this.dialogKey === 'SETTINGS') {
          this.$router.push({ name: 'Setting' })
        }
      }, 20)
    },
    getTabs () {
      post('/common/v1.index/tabs').then(res => {
        if (res.code !== 0) {
          return
        }
        this.tabs = [{ id: 0, name: '推荐' }, ...res.data]
      })
    },
    getPopup () {
      post('/common/v1.user/getPopup',
        { type: 'home_popup' }
      ).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        // eslint-disable-next-line eqeqeq
        if (res.data.status && res.data.status == 1) {
          this.title = res.data.title
          this.subtitle = res.data.content
          this.yesBtnText = res.data.btnText
          this.cancelBtnText = res.data.cancelBtnText
          this.dialogKey = res.data.key
          this.dialog = true
        }
      })
    }
  },
  created () {
    this.getTabs()
    this.getPopup()
  },
  mounted () {
  },
  setup () {
  },
  watch: {
    $route (to, from) {
      // eslint-disable-next-line eqeqeq
      if (to.path != from.path && (from.name == 'Login' || from.name == 'Register' || from.name == 'Welcome') && to.name == 'Home') {
        this.getTabs()
      }
      return null
    }
  }
}
</script>
<style lang="scss">
.home{
  .wrapper{
    padding:10px;
    margin-bottom:80px;
  }
  .logo-col{
    display: flex;
  }
  .logo{
    align-self:center;
    margin-left:10px
  }
  .notice{
    border-radius: var(--van-card-border-radius);
    height: 30px;
  }
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
  .notice-swipe-title {
    font-size: 12px;
  }
  .icon-group-loading{
    margin:10px 0;
  }
  .icon-group{
    margin:10px 0;
    padding:10px;
    background-color: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .col-item{
    flex: 1;
    text-align: center;
  }
  .icon-name{
    font-size:12px;
    color:#fff;
    margin-top:4px;
  }
  .icon{
    width:18px;
    height:18px;
  }
  .icon2{
    width:13px;
    height:15px;
  }
  .icon-box {
    width: 45px;
    height: 45px;
    border-radius: var(--van-card-border-radius);
  }
  .banner-image{
    border-radius: var(--van-card-border-radius);
  }
  .swipe{
    padding:0 0 10px 0;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    height: 120px;
    text-align: center;
    background-color: #39a9ed;
    overflow: hidden;
    border-radius: var(--van-card-border-radius);
  }
  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    color:#fff;
  }
  .topics-loading{
    margin-top:10px;
  }
}
</style>
