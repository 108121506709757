<template>
  <div class="comments">
    <van-sticky>
      <van-nav-bar title="评论列表" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.comment_id">
            <CommentItem
              :type="type"
              :comment_id="item.comment_id"
              :headpic="item.headpic"
              :nickname="item.nickname"
              :vod_id="item.vod_id"
              :comment_content="item.comment_content"
              :comment_up="item.comment_up"
              :is_comment_up="item.is_comment_up"
              :comment_time="item.comment_time"
              :comment_reply_num="item.comment_reply_num"
              :comment_reply="item.comment_reply"
              :onComment="onComment"
              :onUp="onUp"
            />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
  <van-action-bar style="background:#1F232B;padding:5px;">
    <van-cell-group inset style="flex:4">
      <van-field
        v-model="content"
        placeholder="发表评论..."
        maxlength="100"
      />
    </van-cell-group>
    <van-action-bar-button type="primary" text="立即评论" @click="onComment()" />
  </van-action-bar>
</template>

<script>
import { get, post } from '@/utils/request'
import { Toast } from 'vant'
import CommentItem from '@/components/Common/CommentItem'
export default {
  name: 'Comments',
  props: {
    type: { type: String, default: 'video' },
    id: { type: [Number, String] }
  },
  components: {
    CommentItem
  },
  data () {
    return {
      page: 1,
      total: 0,
      list: [],
      loading: false,
      refreshing: false,
      content: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      let url = ''
      let data = {}
      if (this.type === 'video') {
        url = '/common/v1.video/comment'
        data = { page: this.page, vod_id: this.id }
      } else if (this.type === 'club') {
        url = '/common/v1.club/get_comment'
        data = { page: this.page, club_id: this.id }
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/get_comment'
        data = { page: this.page, ofo_id: this.id }
      } else if (this.type === 'user') {
        url = '/common/v1.user/getComment'
        data = { page: this.page, user_id: this.id }
      }
      get(url, data).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.loading = false
        this.refreshing = false
        if (res.data == null || res.data.length === 0) {
          this.finished = true
          // eslint-disable-next-line eqeqeq
          if (this.page == 1) {
            this.list = []
          }
          return
        }
        this.total = res.data.total
        const data = res.data.data
        if (data == null || data.length === 0) {
          this.finished = true
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.page = 1
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    onComment () {
      if (this.content === '') {
        Toast.fail('请输入评论内容')
        return
      }
      const toastKey = Toast.loading('提交中')
      let url = ''
      let data = {}
      if (this.type === 'video') {
        url = '/common/v1.videohandle/comment'
        data = {
          vod_id: this.id,
          comment_content: this.content
        }
      } else if (this.type === 'club') {
        url = '/common/v1.club/comment'
        data = {
          club_id: this.id,
          comment_content: this.content
        }
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/comment'
        data = {
          ofo_id: this.id,
          comment_content: this.content
        }
      } else if (this.type === 'user') {
        url = '/common/v1.user/comment'
        data = {
          argued_user_id: this.id,
          comment_content: this.content
        }
      }
      post(url, data).then(res => {
        toastKey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success(res.msg)
        this.content = ''
        setTimeout(() => {
          this.onRefresh()
        }, 200)
      })
    },
    onUp (commentid, e) {
      const toastKey = Toast.loading('提交中')
      let url = ''
      let data = {}
      if (this.type === 'video') {
        url = '/common/v1.videohandle/comment_up'
        data = {
          comment_id: commentid
        }
      } else if (this.type === 'club') {
        url = '/common/v1.club/comment_up'
        data = {
          comment_id: commentid
        }
      } else if (this.type === 'ofo') {
        url = '/common/v1.ofo/comment_up'
        data = {
          comment_id: commentid
        }
      }
      post(url, data).then(res => {
        toastKey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        setTimeout(() => {
          this.onRefresh()
        }, 200)
      })
      e = window.event || e
      if (e.stopPropagation) {
        e.stopPropagation()
      } else {
        e.cancelBubble = true
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.comments {
  .wrapper {
    padding: 10px;
  }
}
</style>
