<template>
  <van-row class="task">
    <van-col span="4" class="task-left-icon">
      <font-awesome-icon :icon="['fas', 'heart']" class="task-icon" :style="{background:icon_bg_color}" />
    </van-col>
    <van-col span="14" class="task-name">
      <div class="task-name-text">{{remark}}</div>
      <div class="gray">
        {{amount > 0 ? '奖励' + amount + '色币' : null}}
        {{amount > 0 && vip_days_str ? '，' : null}}
        {{vip_days_str ? '赠送VIP(' + vip_days_str + ')' : null}}
      </div>
    </van-col>
    <van-col span="6" class="task-right-btn">
      <van-button v-if="finish_status==0" round color="#D91A6E" @click="jumpPage()" size="mini">&nbsp;&nbsp;去完成&nbsp;&nbsp;</van-button>
      <van-button v-if="finish_status==1" round color="#34394d" size="mini">&nbsp;&nbsp;已完成&nbsp;&nbsp;</van-button>
    </van-col>
  </van-row>
</template>
<script>
export default {
  name: 'TaskItem',
  props: {
    index: { type: Number, required: true, default: 0 },
    icon: { type: String, required: true, default: null },
    icon_bg_color: { type: String, default: null },
    remark: { type: String, required: true, default: null },
    amount: { type: String, required: true, default: null },
    vip_days_str: { type: String, required: true, default: null },
    finish_status: { type: Number, required: true, default: null },
    scene_type: { type: Number, required: true, default: null },
    scene_key: { type: String, required: true, default: null },
    name: { type: String, required: true, default: null },
    scene_params: { type: String, required: true, default: null }
  },
  components: {

  },
  data () {
    return {

    }
  },
  methods: {
    jumpPage () {
      if (this.scene_type === 1) {
        if (this.scene_key === 'MYINFORMATION') {
          this.$router.push({ name: 'Profile' })
        }
        if (this.scene_key === 'MYPHOTOS') {
          this.$router.push({ name: 'Photos' })
        }
        if (this.scene_key === 'RECHARGE') {
          this.$router.push({ name: 'Deposit' })
        }
        if (this.scene_key === 'RECHARGEUSDT') {
          this.$router.push({ name: 'DepositUSDT' })
        }
        if (this.scene_key === 'CURRENCYEXCHANGE') {
          this.$router.push({ name: 'Exchange' })
        }
        if (this.scene_key === 'RECHARGEVIP') {
          this.$router.push({ name: 'OpenVip' })
        }
        if (this.scene_key === 'INVITE_FRIENDS') {
          this.$router.push({ name: 'Invite' })
        }
      } else if (this.scene_type === 2) {
        if (this.scene_params === '1') {
          this.$router.push({ name: 'VideoIndex' })
        }
        if (this.scene_params === '3') {
          this.$router.push({ name: 'Club' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task{
  height:60px;
  .task-icon{
    width:20px;
    height:20px;
    padding:10px;
    border-radius: var(--van-card-border-radius);
  }
  .task-left-icon{
    align-self: center;
  }
  .task-name{
    align-self: center;
  }
  .task-name-text{
    font-size:14px;
  }
  .task-right-btn{
    align-self: center;
    text-align: right;
  }
  .gray{
    color: #868A93;
  }
}
</style>
