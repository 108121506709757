<template>
  <div class="withdraw">
    <van-sticky>
      <van-nav-bar title="币币兑换"  :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;"  />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-row v-if="!loading" justify="center" class="assets center background margin">
        <van-col span="12">
          <div class="line label">色币钱包</div>
          <div class="line assets-amount">{{assetsData? parseFloat(assetsData.DOIT.totalAssets).toFixed(2) : 0}}</div>
        </van-col>
        <van-col span="12" class="">
          <div class="line label">USDT钱包</div>
          <div class="line assets-amount">{{assetsData? parseFloat(assetsData.USDT.totalAssets).toFixed(2) : 0}}</div>
        </van-col>
      </van-row>
      <van-row justify="space-between" style="margin-top:20px;">
        <van-col span="11">
          <van-button round block :plain="type==2" type="primary" @click="changeType(1)">USDT 换 色币</van-button>
        </van-col>
        <van-col span="11">
          <van-button round block :plain="type==1" type="primary" @click="changeType(2)">色币 换 USDT</van-button>
        </van-col>
      </van-row>
      <van-cell-group inset style="margin-top:20px;border-radius:15px;">
        <van-field
          v-model="amount"
          clearable
          label="兑换数量"
          placeholder="请输入数量"
        >
          <template #right-icon>
            <div>{{fromCurrency=='DOIT'?'色币':fromCurrency}}</div>
          </template>
        </van-field>
      </van-cell-group>
      <div class="actual">
        <div class="line label">实际可得</div>
        <div class="line assets-amount">{{actualAmount}} {{toCurrency=='DOIT'?'色币':toCurrency}}</div>
      </div>
      <van-button type="primary" size="large" block round  @click="exchange()"  style="margin-top:20px;">立即兑换</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { get, post } from '@/utils/request'

export default {
  name: 'Exchange',
  props: {
  },
  components: {

  },
  data () {
    return {
      assetsData: null,
      fromCurrency: 'USDT',
      toCurrency: 'DOIT',
      type: 1,
      amount: 0,
      exchangerRate: 1
    }
  },
  computed: {
    actualAmount: (data) => {
      // eslint-disable-next-line eqeqeq
      if (data.type == 1) {
        return (data.amount * data.exchangerRate).toFixed(2)
      } else {
        return (data.amount / data.exchangerRate).toFixed(2)
      }
    }
  },
  created () {
    this.getAssets()
    this.getExchangerRate()
  },
  methods: {
    changeType (type) {
      this.type = type
      if (type === 1) {
        this.fromCurrency = 'USDT'
        this.toCurrency = 'DOIT'
      } else {
        this.fromCurrency = 'DOIT'
        this.toCurrency = 'USDT'
      }
    },
    getAssets () {
      get('/asset/v1.assets/getAssets').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.assetsData = res.data
        this.$store.dispatch('setAssetsData', res.data)
      })
    },
    getExchangerRate () {
      get('/common/v1.system/getExchangerRate').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        if (res.data && res.data.doit_cny) {
          this.exchangerRate = res.data.usdt_doit.rate
        }
      })
    },
    exchange () {
      if (this.amount <= 0) {
        Toast.fail('请输入兑换数量')
        return
      }
      const toastkey = Toast.loading('兑换中')
      post('/asset/v1.assets/exchange', {
        exchangeAmount: this.amount,
        currency: this.fromCurrency,
        exchangeCurrency: this.toCurrency
      }).then(res => {
        toastkey.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success('兑换成功')
        this.getAssets()
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.withdraw{
  .wrapper{
    padding: 10px;
  }
  .center{
    text-align: center;
  }
  .background{
    background: #1F232B;
    border-radius: var(--van-card-border-radius);
  }
  .margin{
    margin-top:10px;
  }
  .assets{
    padding: 20px 5px 20px;
  }
  .assets-amount{
    font-size:18px;
  }
  .line{
    line-height: 30px;
  }
  .label{
    color:#868A93;
    font-size: 12px;
  }
  .actual{
    margin-top:20px;
    text-align: center;
  }
}
</style>
