<template>
  <div class="sign-in">
    <van-sticky>
      <van-nav-bar title="签到" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <div class="section">
        <div class="center text1">已连续签到</div>
        <div class="center text2">{{signinData ? signinData.count : ''}} 天</div>
        <div class="center text3">签到总计以获得 {{signinData ? signinData.total_points : ''}} 色币</div>
        <van-steps :active="signinData.today_signin==1?currentDay-1:currentDay-2" >
          <van-step v-for="item in signinData.detail" :key="item.days">
            <div>第{{item.days}}天</div>
            <div class="center">{{item.points}}</div>
          </van-step>
        </van-steps>
      </div>
      <van-button type="primary" @click="signin" :disabled="signinData.today_signin==1?true:false" block round class="btn" >{{signinData.today_signin==1?"已签到":"签到"}}</van-button>
      <Tips :data="lang.signin_tips" v-if="lang && lang.signin_tips.length>0" style="margin-top:20px;" />
    </div>
  </div>
</template>

<script>
import { get, post } from '@/utils/request'
import { Toast } from 'vant'
import Tips from '@/components/Common/Tips.vue'

export default {
  name: 'SignIn',
  props: {
  },
  components: {
    Tips
  },
  data () {
    return {
      signinData: [],
      currentDay: 0,
      lang: null
    }
  },
  created () {
    this.getSigninList()
    this.getLang()
  },
  methods: {
    getLang () {
      get('/common/v1.system/getLang').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.lang = res.data
      })
    },
    signin () {
      // eslint-disable-next-line eqeqeq
      if (this.signinData.today_signin == 1) {
        return
      }
      post('/common/v1.user/signin').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        Toast.success(res.msg)
        this.getSigninList()
      })
    },
    getSigninList () {
      get('/common/v1.user/getSigninList2').then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        let currentDay = 0
        for (let index = 0; index < res.data.detail.length; index++) {
          const element = res.data.detail[index]
          // eslint-disable-next-line eqeqeq
          if (element.is_today == 1) {
            currentDay = element.days
            break
          }
        }
        this.signinData = res.data
        this.currentDay = currentDay
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
.sign-in {
  .wrapper {
    padding: 10px;
  }
  .section {
    color: #000;
    background: #fff;
    border-radius: var(--van-card-border-radius);
    overflow: hidden;
    padding:20px;
  }
  .center{
    text-align: center;
  }
  .text1{
    font-size:18px;
    line-height: 50px;
  }
  .text2{
    font-size:18px;
    color:#F0B90B;
    line-height: 50px;
  }
  .text3{
    font-size:12px;
    color:#868A93;
    line-height: 50px;
  }
  .van-step__circle-container{
    top: 40px !important;
  }
  .van-step__line{
    top: 40px !important;
  }
  .btn{
    margin-top: 20px;
  }
}
</style>
