<template>
  <div class="hot-topics">
    <van-sticky>
      <van-nav-bar title="火热专题" :border=false>
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrapper">
      <van-cell-group inset v-if="topics && topics.length>0">
        <van-cell :title="item.name" is-link v-for="item in topics" :key="item.id" @click="moreVideo(item.id, item.name)"></van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request'
export default {
  name: 'HotTopics',
  props: {
  },
  components: {

  },
  data () {
    return {
      topics: []
    }
  },
  created () {
    this.getHot()
  },
  methods: {
    getHot () {
      post('/common/v1.topics/hot', { vod_id: this.id }).then(res => {
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          return
        }
        this.topics = res.data
      })
    },
    moreVideo (id, name) {
      this.$router.push({ name: 'VideoMore', params: { id, name } })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.hot-topics {
  .wrapper {
    padding: 10px;
  }
}
</style>
