import { createStore } from 'vuex'
import { storage } from '@/utils/storage'

export default createStore({
  state: {
    userInfo: {},
    tokenData: {},
    assetsData: {},
    location: {},
    videoSearchHistory: {},
    ofoSearchHistory: {},
    clubSearchHistory: {},
    fingerprintJS: {}
  },
  getters: {
    userInfo: () => JSON.parse(localStorage.getItem('userInfo')),
    tokenData: () => JSON.parse(localStorage.getItem('tokenData')),
    assetsData: () => JSON.parse(localStorage.getItem('assetsData')),
    location: () => JSON.parse(localStorage.getItem('location')),
    videoSearchHistory: () => JSON.parse(localStorage.getItem('videoSearchHistory')),
    ofoSearchHistory: () => JSON.parse(localStorage.getItem('ofoSearchHistory')),
    clubSearchHistory: () => JSON.parse(localStorage.getItem('clubSearchHistory')),
    fingerprintJS: () => JSON.parse(localStorage.getItem('fingerprintJS'))
  },
  mutations: {
    setUserInfo (state, data) {
      try {
        state.userInfo = data
        localStorage.setItem('userInfo', JSON.stringify(data))
      } catch (err) {
        console.log('[error]setUserInfo', err)
      }
    },
    remoteUserInfo (state) {
      try {
        state.userInfo = null
        localStorage.removeItem('userInfo')
      } catch (err) {
        console.log('[error]remoteUserInfo', err)
      }
    },
    setTokenData (state, data) {
      state.tokenData = data
      storage.setItem('tokenData', JSON.stringify(data))
    },
    removeTokenData (state) {
      try {
        state.tokenData = null
        storage.removeItem('tokenData')
      } catch (err) {
        console.log('[error]removeTokenData', err)
      }
    },
    setAssetsData (state, data) {
      try {
        state.assetsData = data
        localStorage.setItem('assetsData', JSON.stringify(data))
      } catch (err) {
        console.log('[error]setAssetsData', err)
      }
    },
    remoteAssetsData (state) {
      try {
        state.assetsData = null
        localStorage.removeItem('assetsData')
      } catch (err) {
        console.log('[error]remoteAssetsData', err)
      }
    },
    setLocation (state, data) {
      try {
        state.location = data
        localStorage.setItem('location', JSON.stringify(data))
      } catch (err) {
        console.log('[error]setLocation', err)
      }
    },
    remoteLocation (state) {
      try {
        state.location = null
        localStorage.removeItem('location')
      } catch (err) {
        console.log('[error]remoteLocation', err)
      }
    },
    setVideoSearchHistory (state, data) {
      try {
        state.videoSearchHistory = data
        localStorage.setItem('videoSearchHistory', JSON.stringify(data))
      } catch (err) {
        console.log('[error]videoSearchHistory', err)
      }
    },
    setOfoSearchHistory (state, data) {
      try {
        state.ofoSearchHistory = data
        localStorage.setItem('ofoSearchHistory', JSON.stringify(data))
      } catch (err) {
        console.log('[error]ofoSearchHistory', err)
      }
    },
    setClubSearchHistory (state, data) {
      try {
        state.clubSearchHistory = data
        localStorage.setItem('clubSearchHistory', JSON.stringify(data))
      } catch (err) {
        console.log('[error]clubSearchHistory', err)
      }
    },
    setFingerprintJS (state, data) {
      try {
        state.fingerprintJS = data
        localStorage.setItem('fingerprintJS', JSON.stringify(data))
      } catch (err) {
        console.log('[error]fingerprintJS', err)
      }
    }
  },
  actions: {
    setUserInfo ({ commit }, param) {
      commit('setUserInfo', param)
    },
    remoteUserInfo ({ commit }) {
      commit('remoteUserInfo')
    },
    setTokenData ({ commit }, param) {
      commit('setTokenData', param)
    },
    removeTokenData ({ commit }) {
      commit('removeTokenData')
    },
    setAssetsData ({ commit }, param) {
      commit('setAssetsData', param)
    },
    remoteAssetsData ({ commit }) {
      commit('remoteAssetsData')
    },
    setLocation ({ commit }, param) {
      commit('setLocation', param)
    },
    remoteLocation ({ commit }) {
      commit('remoteLocation')
    },
    setVideoSearchHistory ({ commit }, param) {
      commit('setVideoSearchHistory', param)
    },
    setOfoSearchHistory ({ commit }, param) {
      commit('setOfoSearchHistory', param)
    },
    setClubSearchHistory ({ commit }, param) {
      commit('setClubSearchHistory', param)
    },
    setFingerprintJS ({ commit }, param) {
      commit('setFingerprintJS', param)
    },
    logout ({ commit }) {
      commit('remoteUserInfo')
      commit('removeTokenData')
      commit('remoteAssetsData')
      commit('remoteLocation')
    }
  },
  modules: {
  }
})
