<template>
  <div class="my-favorite">
    <van-sticky>
      <van-nav-bar title="我的收藏" :border="false">
        <template #left>
          <font-awesome-icon :icon="['fas', 'chevron-left']" @click="$router.back()" style="width:18px;height:18px;" />
        </template>
      </van-nav-bar>
      <van-tabs v-model:active="active" @click-tab="changeTab"  v-if="!isloading">
        <van-tab v-for="(item, index) in types" :title="item" :key="index" :name="index">
        </van-tab>
      </van-tabs>
    </van-sticky>
    <div class="wrapper">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <template v-if="list && list.length>0">
            <div class="videos" v-if='selectedIndex===0'>
              <VideoItem
                v-for="item in list" :key="item.vod_id"
                :vod_id="item.vod_id"
                :vod_pic="item.vod_pic"
                :vod_name="item.vod_name"
                :vod_watch="item.vod_watch"
                :vip="item.is_vip"
                :level="item.level"
                :price="item.price"
                :jwidth="4"
                :imageRate="16 / 9"
                :numColumns="2"
                class="video-item"
                :isDelete="true"
                :onDelete="deleteVideo"
              />
            </div>
            <div class="ofos" v-if='selectedIndex===1'>
              <OfoItem
                v-for="item in list"
                :key="item.id"
                :ofo_id="item.id"
                :avatar="item.avatar"
                :name="item.name"
                :type="item.type"
                :city="item.city"
                :services="item.services"
                :quality="item.quality"
                :monetary="item.monetary"
                :created_at_str="item.created_at_str"
                :look_ofo="item.look_ofo"
                :ofo_up="item.ofo_up"
                :ofo_store="item.ofo_store"
                :status="item.status"
                :margin="item.margin"
                :isDelete="true"
                :onDelete="deleteOfo"
                />
            </div>
            <div class="clubs" v-if='selectedIndex===2'>
              <ClubItem
                v-for="item in list"
                :key="item.id"
                :id="item.id"
                :img="item.img"
                :name="item.name"
                :ambient="item.ambient"
                :quality="item.quality"
                :address="item.address"
                :follow="item.follow"
                :isDelete="true"
                :onDelete="deleteClub"
                />
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
      <div v-if="isloading">
        <free-style-shimmer :is-loading="isloading" height="50px " width="100%" border-radius="6px" class="loading" @click="getTypes" />
        <free-style-shimmer :is-loading="isloading" height="105px" width="100%" border-radius="6px" class="loading" v-for="item in 10" :key="item"  @click="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from '@/utils/request'
import VideoItem from '@/components/Video/Item.vue'
import OfoItem from '@/components/Ofo/Item.vue'
import ClubItem from '@/components/Club/Item.vue'
import { Toast } from 'vant'
export default {
  name: 'MyFavorite',
  props: {
  },
  components: {
    VideoItem,
    OfoItem,
    ClubItem
  },
  data () {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      isloading: true,
      types: [
        '视频',
        '楼凤',
        '会所'
      ],
      list: [],
      selectedIndex: 0
    }
  },
  created () {
    this.getList()
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    changeTab (data) {
      this.selectedIndex = data.name
      this.onRefresh()
    },
    getList () {
      let url = '/common/v1.record/getVodStore'
      if (this.selectedIndex === 0) {
        url = '/common/v1.record/getVodStore'
      } else if (this.selectedIndex === 1) {
        url = '/common/v1.record/getOfoStore'
      } else if (this.selectedIndex === 2) {
        url = '/common/v1.record/getClubStore'
      }
      get(url, {
        page: this.page
      }).then(res => {
        this.loading = false
        this.isloading = false
        this.refreshing = false
        const data = res.data.data
        this.total = res.data.total
        if (data == null || data.length === 0) {
          this.finished = true
          // eslint-disable-next-line eqeqeq
          if (this.page == 1) {
            this.list = []
          }
          return
        }
        if (res.data.current_page > 1) {
          this.list = this.list.concat(data)
        } else {
          this.list = data
        }
        if (this.list.length >= this.total) {
          this.finished = true
        }
      })
    },
    onRefresh () {
      this.list = []
      this.page = 1
      this.finished = false
      this.loading = true
      this.refreshing = true
      this.getList()
    },
    onLoad () {
      this.page++
      this.getList()
    },
    deleteVideo (id, e) {
      const loading = Toast.loading('加载中')
      post('/common/v1.videohandle/do', {
        vod_id: id,
        type: 'vod_store'
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.finished = false
        this.loading = true
        this.refreshing = true
        this.getList()
      })
      e = window.event || e
      if (e.stopPropagation) {
        e.stopPropagation()
      } else {
        e.cancelBubble = true
      }
    },
    deleteOfo (id, e) {
      const loading = Toast.loading('加载中')
      post('/common/v1.ofo/do', {
        ofo_id: id,
        do_type: 'ofo_store'
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.finished = false
        this.loading = true
        this.refreshing = true
        this.getList()
      })
      e = window.event || e
      if (e.stopPropagation) {
        e.stopPropagation()
      } else {
        e.cancelBubble = true
      }
    },
    deleteClub (id, e) {
      const loading = Toast.loading('加载中')
      post('/common/v1.club/do', {
        club_id: id,
        type: 'club_store'
      }).then(res => {
        loading.clear()
        // eslint-disable-next-line eqeqeq
        if (res.code != 0) {
          Toast.fail(res.msg)
          return
        }
        this.finished = false
        this.loading = true
        this.refreshing = true
        this.getList()
      })
      e = window.event || e
      if (e.stopPropagation) {
        e.stopPropagation()
      } else {
        e.cancelBubble = true
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.my-favorite {
  .wrapper {
    padding: 10px;
  }
  .videos{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
  }
  .video-item{
    width: 100%;
    border-radius: var(--van-card-border-radius);
    overflow: hidden;
  }
  .loading{
    margin-bottom: 10px;
  }
}
</style>
