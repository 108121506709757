<template>
  <div class='video-filter'>
    <van-nav-bar title="筛选条件" :border=false>
      <template #left>
          <font-awesome-icon :icon="['fas', 'times']" class="icon" @click="$router.back()" />
      </template>
    </van-nav-bar>
    <div class='wrapper'>
      <CommonTitle title="类别" />
      <div class="type-group">
        <van-button
          round
          :type="(selectedType==index)?'primary':'default'"
          class="btn-item"
          v-for="(item, index) in videoTypes"
          :key="index"
          @click="selectType(index)"
        >{{item.name}}</van-button>
      </div>
      <CommonTitle title="更新时间" />
      <div class="time-group">
        <van-button
          round
          :type="(selectedTime==index)?'primary':'default'"
          class="btn-item"
          v-for="(item, index) in videoUpdateTimes"
          :key="index"
          @click="selectTime(index)"
        >{{item.name}}</van-button>
      </div>
      <div class="footer-btn">
        <van-row justify="space-between">
          <van-col span="11">
            <van-button type="primary" round plain block @click="reset">重置</van-button>
          </van-col>
          <van-col span="11">
            <van-button type="primary" round block @click="submit">确认</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from 'vue3-bus'
import CommonTitle from '@/components/Common/Title.vue'

export default {
  name: 'VideoFilter',
  props: {
  },
  components: {
    CommonTitle
  },
  data () {
    return {
      videoTypes: [
        { name: '最多点赞', value: 'vod_up' },
        { name: '最多收藏', value: 'vod_store' },
        { name: '最多推荐', value: 'vod_recommend' },
        { name: '最多播放', value: 'vod_watch' }
      ],
      videoUpdateTimes: [
        { name: '一周内', value: 7 },
        { name: '半月内', value: 15 },
        { name: '一个月内', value: 30 },
        { name: '三个月内', value: 90 },
        { name: '六个月内', value: 180 },
        { name: '一年内', value: 365 }
      ],
      selectedType: -1,
      selectedTime: -1
    }
  },
  created () {

  },
  methods: {
    selectType (index) {
      this.selectedType = index
    },
    selectTime (index) {
      this.selectedTime = index
    },
    reset () {
      this.selectedType = -1
      this.selectedTime = -1
    },
    submit () {
      if (this.selectedType >= 0 || this.selectedTime >= 0) {
        const data = {
          type: this.videoTypes[this.selectedType],
          time: this.videoUpdateTimes[this.selectedTime]
        }
        Bus.emit('filter', data)
      } else {
        const data2 = {
          type: { value: 'vod_time' },
          time: { value: 0 }
        }
        Bus.emit('filter', data2)
      }
      setTimeout(() => { this.$router.back() }, 200)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.video-filter{
  .wrapper{
    padding: 10px;
  }
  .icon{
    width:22px;
    height:22px;
  }
  .type-group{
    display: flex;
    flex-wrap: wrap;
  }
  .time-group{
    display: flex;
    flex-wrap: wrap;
  }
  .btn-item{
    margin: 0 5px 10px 0;
  }
  .footer-btn{
    margin-top:30px;
  }
}
</style>
